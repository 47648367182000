
define('template!wellResultIconTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-1 p-l-0 p-r-16-per tooltip1">\n    ';
 if (color) { ;
__p += '\n    <div class="circleResult circleResult1" style="position: absolute; background-color: ' +
((__t = ( color )) == null ? '' : __t) +
' !important; ' +
((__t = ( ic?'width:9px;height:9px;margin-left:4px;margin-top:6px;':'' )) == null ? '' : __t) +
'">\n        ';
 if (repeatSt === 'P') { ;
__p += '\n            <div class="ico-repeat-pcr" style="' +
((__t = ( ic?'width:9px; height:4px; border-bottom-right-radius:9px; border-bottom-left-radius:9px; bottom:0.6px; left:-1px;':'')) == null ? '' : __t) +
'"></div>\n        ';
 } else if (repeatSt === 'D') { ;
__p += '\n            <div class="ico-repeat-dna-1" style="' +
((__t = ( ic?'height: 4px; width: 4px; border-radius: 4px 0 0 0;':'' )) == null ? '' : __t) +
'"></div>\n            <div class="ico-repeat-dna-2" style="' +
((__t = ( ic?'height: 4px; width: 4px; border-radius: 4px 0 0 0;':'' )) == null ? '' : __t) +
'"></div>\n        ';
 } ;
__p += '\n    </div>\n    ';
 } ;
__p += '\n    ';
 if (resCodeErr === 'E' || resCodeErr === 'W') { ;
__p += '\n        <div class="bigSquare" style="background-color: ' +
((__t = ( resCodeErr==='E'?'#000000;':'orange' )) == null ? '' : __t) +
' !important;"></div>\n    ';
 } ;
__p += '\n    ';
 if (resCodeErr === 'E' || resCodeErr === 'W' || resCommentErrAuto) { ;
__p += '\n        <span class="tooltiptext">' +
((__t = ( resCommentErrAuto?resCommentErrAuto:resComment )) == null ? '' : __t) +
' ' +
((__t = ( kitcqct )) == null ? '' : __t) +
'</span>\n    ';
 } ;
__p += '\n\n    ';
 if (valSt === 2.1) { ;
__p += '\n        <div class="smallCircleVal" style="background-color: darkblue; ' +
((__t = ( ic?'width:5px; height:5px; border-radius:5px; top:3px; left:2px;':'top:1px;' )) == null ? '' : __t) +
'"></div>\n    ';
 } else if (valSt === 2.2) { ;
__p += '\n        <div class="smallCircleVal" style="background-color: orange; ' +
((__t = ( ic?'width:5px; height:5px; border-radius:5px; top:3px; left:2px;':'top:1px;' )) == null ? '' : __t) +
'"></div>\n    ';
 } else if (valSt === 3.1) { ;
__p += '\n        <div class="smallCircleVal" style="background-color: darkblue; ' +
((__t = ( ic?'width:5px; height:5px; border-radius:5px; left:10px; top:3px;':'left:8px; top:1px;' )) == null ? '' : __t) +
'"></div>\n    ';
 } else if (valSt === 3.2) { ;
__p += '\n        <div class="smallCircleVal" style="background-color: orange; ' +
((__t = ( ic?'width:5px; height:5px; border-radius:5px; left:10px; top:3px;':'left:8px; top:1px;' )) == null ? '' : __t) +
'"></div>\n    ';
 } else if (valSt >= 4 && valSt < 5) { ;
__p += '\n        <div class="smallCircleVal" style="background-color: orange; ' +
((__t = ( ic?'width:5px; height:5px; border-radius:5px; left:6px; top:6px;':'left:4px; top:4px;' )) == null ? '' : __t) +
'"></div>\n    ';
 } else if (valSt === 5) { ;
__p += '\n        <div class="smallCircleVal" style="background-color: white; ' +
((__t = ( ic?'width:5px; height:5px; border-radius:5px; left:6px; top:6px;':'left:4px; top:4px;' )) == null ? '' : __t) +
'"></div>\n    ';
 } ;
__p += '\n\n    ';
 if(!resCodeErr && resCommentErrAuto) { ;
__p += '\n        ';
 if(resCommentErrAuto[0] === 'W') { ;
__p += '\n            <div class="smallSquareErr" style="background-color: orange; ' +
((__t = ( ic?'top:13px; left:6px; width:5px; height:5px;':'top:11px; left:4px;' )) == null ? '' : __t) +
'"></div>\n        ';
 } else { ;
__p += '\n            <div class="smallSquareErr" style="background-color: black; ' +
((__t = ( ic?'top:13px; left:6px; width:5px; height:5px;':'top:11px; left:4px;' )) == null ? '' : __t) +
'"></div>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

