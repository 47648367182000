/**
 * Created by RKL on 13/08/2015.
 */
define('runAssaysTargets2TabView',[
  'module',
  'app',
  'underscore',
  'marionette',
  'template!runAssaysTargets2TabTpl',
  'miniPlateView',
  'entities/wells',
  'entities/runats'
], function (
  module,
  App,
  _,
  Marionette,
  viewTpl,
  MiniPlateView
) {
  'use strict';

  var RunAssaysTargets2View = Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      lblAssay: '.lbl-assay',
      lblTarget: '.lbl-target',
        alert: '.alert'
    },
    events: {
      'click .edit-run-at': 'onEditRunAt',
      'click .btn-assay-target': 'onBtnAssayTargetClick',
      'click .btn-detail': 'onDetailClick',
      'click .btn-previous': 'onPreviousClick',
      'click .btn-next': 'onNextClick',
      'click .btn-validate': 'onValidateClick',
      'click .btn-validate2': 'onValidate2Click',
      'click .btn-invalidate': 'onInValidateClick',
      'click .btn-repeat-dna': 'onRepeatDnaClick',
      'click .btn-repeat-dna-dil12': 'onRepeatDnaDil12Click',
      'click .btn-repeat-dna-dil15': 'onRepeatDnaDil15Click',
      'click .btn-expert-required': 'onValidateExpertRequiredClick',
      'click .btn-confirm-result': 'onConfirmResultClick',
      'click .btn-change-to-neg': 'onChangeToNegClick'
    },

    regions: {
      wellTable: '#right-view',
      targetsView: '#targets-view',
      miniTargetView: '#mini-plate-view',
      loader: '#region-loader'
    },

    onShow: function() {
        this.miniPlateView = new MiniPlateView({});
        this.miniPlateView.listenTo(this.miniPlateView, 'well:show-well-edit', _.bind(this.onShowWellEditClick, this));
        this.miniPlateView.listenTo(this.miniPlateView, 'well:show-well-detail', _.bind(this.onShowWellDetailClick, this));
        this.miniPlateView.listenTo(this.miniPlateView, 'well:removeSelection', _.bind(this.removeSelection, this));
        this.miniPlateView.listenTo(this.miniPlateView, 'well:addSelection', _.bind(this.addSelection, this));
        this.miniPlateView.listenTo(this.miniPlateView, 'well:filteredDetailRow', _.bind(this.onFilteredDetailRow, this));
        this.miniPlateView.listenTo(this.miniPlateView, 'well:filteredDetailColumn', _.bind(this.onFilteredDetailColumn, this));
        this.getRegion('miniTargetView').show(this.miniPlateView);
    },

      onShowWellEditClick: function(well) {
          this.trigger('well:show-well-edit', well);
      },

      onFilteredDetailRow: function(row) {
          this.trigger('well:filteredDetailRow', row);
      },

      onFilteredDetailColumn: function(col) {
          this.trigger('well:filteredDetailColumn', col);
      },

    onShowWellDetailClick: function(well) {
        this.trigger('well:show-well-detail', well);
    },

    removeSelection: function(model) {
        this.trigger('well:removeSelection', model);
    },

    addSelection: function(model) {
        this.trigger('well:addSelection', model);
    },

    refreshMiniPlateView: function(run, models, channel) {
      this.channel = channel;
      this.miniPlateView.refresh(run, models, channel);
      if (models && models.length) {
        var assay = models[0].get('refAssay');
        var target = run.getTargetByChannel(assay, channel);
        this.ui.lblAssay.text(assay.code);
        this.ui.lblTarget.text(channel + ' ' + (target!==null?target.refMbAna.code:''));
      }
    },

    onBtnAssayTargetClick : function(event) {
      var btn = $(event.currentTarget);
      this.assaySecId = btn.data('assay-secid');
      this.mbAnaSecId = btn.data('mbana-secid');
      this.targetSecId = btn.data('target-secid');
      this.trigger('well:assay-target:click', this.assaySecId, this.mbAnaSecId, this.targetSecId);
      this.unSelectAll();
      btn.addClass('target-selected');
    },

    onInValidateClick: function() {
      this.trigger('well:assay-target-invalidate:click');
    },

    onValidateClick: function() {
      this.trigger('well:assay-target-validate:click');
    },

    onValidate2Click: function() {
      this.trigger('well:assay-target-validate2:click');
    },

    onRepeatDnaClick: function() {
      this.trigger('well:assay-target-invalidateallextract');
    },

    onRepeatDnaDil12Click: function() {
      this.trigger('well:assay-target-invalidateallextract-dil12');
    },

    onRepeatDnaDil15Click: function() {
      this.trigger('well:assay-target-invalidateallextract-dil15');
    },
    
    onValidateExpertRequiredClick: function() {
      this.trigger('well:assay-target-validate-expert-required:click');
    },

    onPreviousClick: function() {
      this.trigger('well:assay-target-previous:click');
    },

    onNextClick: function() {
      this.trigger('well:assay-target-next:click');
    },

    onConfirmResultClick: function() {
      this.trigger('well:assay-target-confirm-result:click');
    },

    onChangeToNegClick: function() {
      this.trigger('well:assay-target-change-to-neg:click');
    },

    onDetailClick: function() {
      if (this.assaySecId) {
        if (this.mbAnaSecId) {
            App.navigate('runs/'+this.model.get('secId')+'/details/filters/{"mbAnaSecId":"'+ this.mbAnaSecId +'", ' +
                '"assaySecId":"'+ this.assaySecId +'", "targetSecId": "'+ this.targetSecId +'"}', {trigger: true});
        } else {
            App.navigate('runs/'+this.model.get('secId')+'/details/filters/{"assaySecId":"'+ this.assaySecId +'"}',
                {trigger: true});
        }
      }
    },

    unSelectAll: function() {
      this.$el.find('.target-selected').removeClass('target-selected');
    },

    serializeData: function () {
      var templateData = {};
      this.data = templateData.data = this.model.getAssaysTargets();
      if (this.data && this.data.assays && this.data.assays.length &&
          this.data.assays[0].kitps &&
          this.data.assays[0].kitps.length) {
          this.trigger('well:assay-target:click', this.data.assays[0].secId);
      }
      templateData.run = this.model.toJSON();
      templateData.displayBT = this.model.get('refKitProt')?this.model.get('refKitProt').resGraph !== 'M':true;

      return templateData;
    }
  });

  module.exports = RunAssaysTargets2View;
});
