define('runTabsData',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = [{
      name: 'detail',
      title: _.i18n('runs.detail.tabTitle')
    },
    {
      name: 'table',
      title: _.i18n('runs.table.tabTitle')
    },
    {
      name: 'details',
      title: _.i18n('runs.details.tabTitle')
    },
    {
        name: 'assays-targets-2',
        title: _.i18n('runs.assays.targets.tabTitle')
    },
    {
      name: 'results',
      title: _.i18n('runs.results.tabTitle')
    }
  ];
});
