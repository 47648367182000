define('runDetailsTabView',[
    'module',
    'marionette',
    'template!runDetailsTabTpl',
    'underscore',
    'dialogFormView',
    'wellDetailView'
], function(
    module,
    Marionette,
    detailsTabTpl,
    _,
    DialogFormView,
    WellDetailView
) {
    'use strict';

    var DetailsTabView = DialogFormView.extend({
        template: detailsTabTpl,

        regions: {
            rightView: '.right-view',
            wellTable: '.well-table'
        },

        initialize: function() {

        },

        onShow: function() {
            $.material.init();
        },

        initViews: function() {
            if (this.wellDetailView) {
                this.wellDetailView.destroy();
            }

            this.wellDetailView = new WellDetailView();
            this.wellDetailView.model = this.model;
            this.wellDetailView.modelRun = this.modelRun;
            var region = this.getRegion('rightView');
            if (region){
                region.show(this.wellDetailView);
            }
        }
    });

    module.exports = DetailsTabView;
});
