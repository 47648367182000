define('savingBehavior',[
  'module',
  'marionette',
  'underscore'
], function(
  module,
  Marionette,
  _
) {
  'use strict';

  /**
   * Add to your template
   * <div class="saving-label"></div>
   * <div class="error-label"><%= _.i18n('common.serverError') %></div>
   *
   * You can also add the error management adding this
   * <div class="error-label"><%= _.i18n('common.serverError') %>
   *     <div clas="error-msg"></div>
   * </div>
   * Behavior will listen to model's request and sync events and show saving, saved or error
   * You could show the messages manually by doing triggerMethod('saving'), triggerMethod('saved'),
   * triggerMethod('error')
   */
  var SavingBehavior = Marionette.Behavior.extend({
    ui: {
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      label: '.saving-label',
      errorLabel: '.error-label',
      errorMsg: '.error-msg'
    },

    events: {
      'keyup @ui.input': 'onEnableCancelConfirm'
    },

    modelEvents: {
      request: 'onSaving',
      error: 'onError'
    },

    onSaved: function() {
      var that = this;
      this.ui.label.text(_.i18n('common.saved'));
      setTimeout(function() {
        that.onHideLabel();
      }, 1000);
    },

    onHideLabel: function() {
      // We need this check in case when label element was destroyed
      // For example redirection from the page.
      if(this.ui.label.fadeOut) {
        this.ui.label.fadeOut();
      }
    },

    onEnableCancelConfirm: function() {
      this.ui.cancel.prop('disabled', false);
      this.ui.confirm.prop('disabled', false);
    },

    onUploading: function() {
      this._showMessage(_.i18n('common.upload'));
    },

    onSaving: function() {
      this.ui.errorLabel.hide();
      this.ui.errorMsg.empty();
      this._showMessage(_.i18n('common.saving'));
      this._disableCancelConfirm();
    },

    onError: function(model, response) {
      if (response.status === 405) {
          alert(_.i18n('error.noPermission'));
          this.onEnableCancelConfirm();
      } else if(response.status !== 409) {
        this.ui.label.text('');
        this.ui.errorLabel.fadeIn();
        if(response.responseJSON && response.responseJSON.label){
            this.ui.errorMsg.append('<span class="error">' + response.responseJSON.label + '</span>');
        }
      }
    },

    _showMessage: function(message) {
      this.ui.label.text(message);
      this.ui.label.fadeIn();
    },

    _disableCancelConfirm: function() {
      this.ui.cancel.prop('disabled', true);
      this.ui.confirm.prop('disabled', true);
    }
  });

  module.exports = SavingBehavior;
});
