
define('template!wellTableTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content m-0-i">\n\t<div class="well-table jq-grid-table-page hidden-print" style="height: 682px;">\n\t\t<div>\n          <div class="row m-b-0 m-l-10">\n            <div class=" col-xs-6 p-l-0">\n                <div class="col-xs-5 p-l-0">\n                  <input type="text" class="form-control floating-label input-multi-res" data-field-name="multiRes" placeholder="' +
((__t = ( _.i18n('well.multiRes') )) == null ? '' : __t) +
'" value="' +
((__t = ( multiRes )) == null ? '' : __t) +
'">\n                </div>\n                <button class="btn add-btn-text btn-search btn-sm js-confirm m-t-0 m-b-0">' +
((__t = ( _.i18n('common.search') )) == null ? '' : __t) +
'</button>\n            </div>\n            <label class="btn add-btn-text btn-sm edit-smpid-button m-t-0 m-b-0 ' +
((__t = ( !editEnable?'':'hide' )) == null ? '' : __t) +
'">\n              <i class="mdi-editor-mode-edit"></i>\n              <span>' +
((__t = ( _.i18n("well.editSmpId") )) == null ? '' : __t) +
'</span>\n            </label>\n            <label class="btn add-btn-text btn-sm edit-refassay-button m-t-0 m-b-0 ' +
((__t = ( !editEnable?'':'hide' )) == null ? '' : __t) +
'">\n              <i class="mdi-editor-mode-edit"></i>\n              <span>' +
((__t = ( _.i18n("well.editRefAssay") )) == null ? '' : __t) +
'</span>\n            </label>\n            <label class="btn add-btn-text btn-sm display-graph-button m-t-0 m-b-0 ' +
((__t = ( editEnable?'':'hide' )) == null ? '' : __t) +
'">\n              <i class="mdi-action-done"></i>\n              <span>' +
((__t = ( _.i18n("well.displayGraph") )) == null ? '' : __t) +
'</span>\n            </label>\n              <div class="col-xs-6 p-l-0">\n              </div>\n          </div>\n\t\t</div>\n\t  <div class="loading-block js-loading-block">\n\t    <span class="loading-message js-loading-message label"></span>\n\t  </div>\t\t\t      \n\t  <table id="jq-grid-well" class="wellTable" role="grid"></table>\n\t</div>\n</div>';

}
return __p
};});

