/**
 * Created by OLD on 25/11/2015.
 */
define('wellTableView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'jqgridView',
  'template!wellTableTpl',
  'wellResultIconView',
  'wellDisplayGraphView',
  'validationUtils',
  'entities/wells'
], function(
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  JqGridView,
  viewTpl,
  WellResultIconView,
  DisplayGraphLayoutView,
  ValidationUtils
) {
  'use strict';

  var WellTableView = JqGridView.extend({
    filterEnable: false,
    GRID_MIN_WIDTH: 600,
    template: viewTpl,
    channelToDisplay: null,

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-well',
        jqgRow: 'tr.jqgrow',
        editRefAssayBtn: '.edit-refassay-button',
        editRefKitLBtn: '.edit-refkitl-button',
        editSmpIdBtn: '.edit-smpid-button',
        displayGraphBtn: '.display-graph-button',
        inputMultiRes: '.input-multi-res'
      }, this.gridUi);
    },

    events: {
      'click @ui.editRefAssayBtn': 'onEditRefAssayBtnClick',
      'click @ui.editRefKitLBtn': 'onEditRefKitLBtnClick',
      'click @ui.editSmpIdBtn': 'onEditSmpIdBtnClick',
      'click @ui.displayGraphBtn': 'onDisplayGraphBtnClick',
      'click .edit-smpid-button1': 'onEditSmpIdBtnClick',
      'click .btn-search': 'onSearchMultiRes',
      'mousedown .ui-jqgrid-sortable': 'onHeaderClick'
    },

    onShow: function() {
      $.material.init();
    },

    initialize: function() {
      App.on('well:refresh:well', this.onWellChange, this);
    },

    onSearchMultiRes: function() {
      this.trigger('well-table:search-multi-res', this.ui.inputMultiRes.val());
    },

    onWellChange: function(well, wells) {
      if (!wells) {
        wells = [];
      }
      if (well) {
        wells.push(well.toJSON());
      }
      if (wells) {
          _.each(wells, _.bind(function(w) {
              var coltd, html, linetr = this.$el.find('#' + w.secId);
              var well2 = _.find(this.model.get('wells'), function(item){ return item.secId === w.secId; });
              var indexWell = this.model.get('wells').indexOf(well2);
              this.model.get('wells')[indexWell] = w;
              for (var i = 1 ; i <= 6 ; i++) {
                  coltd = linetr.find('.well-ch' + i);
                  html = this.squareIconFormatter(null, {colModel: {name: i.toString()}}, w);
                  coltd.html(html);
              }
          }, this));
      }
    },

    serializeData: function() {
      var templateData = {};
      templateData.editEnable = false;
      if (this.channelToDisplay) {
          templateData.editEnable = true;
      }
      templateData.sortOn = this.sortOn;
      templateData.multiRes = this.multiRes;
      return templateData;
    },

    onHeaderClick: function(e) {
      var models = [];
      _.each(this.codeTableData, function(well) {
        models.push(App.request('well:model', well));
      });
      //this.data = this.model.getAssaysTargets();
      this.displayGraphView = new DisplayGraphLayoutView({
          models: models,
          model: this.model.clone(),
          newItem: false
      });
      this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-validate:click', 
          _.bind(this.onWellsValidateClick, this));
      this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-validate2:click', 
          _.bind(this.onWellsValidate2Click, this));
      this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-invalidate:click', 
          _.bind(this.onWellsInValidateClick, this));
      this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-repeat-dna:click', 
          _.bind(this.onWellsRepeatDnaClick, this));
      this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-repeat-dna-dil12:click', 
          _.bind(this.onWellsRepeatDnaDil12Click, this));
      this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-repeat-dna-dil15:click', 
          _.bind(this.onWellsRepeatDnaDil15Click, this));
      this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-expert:click', 
          _.bind(this.onWellsExpertClick, this));
      this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-confirm-result:click', 
          _.bind(this.onWellsConfirmResultClick, this));
      this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-change-to-neg:click', 
          _.bind(this.onWellsChangeToNegClick, this));
          
      this.displayGraphView.allModel = models;
      this.channelToDisplay = this.displayGraphView.channelToDisplay = Number(e.currentTarget.id.replace('jqgh_jq-grid-well_', ''));
      this.displayGraphView.graphToDisplay = this.model.getGraphToDisplay(this.model);
      this.displayGraphView.hideOkButton = true;
      this.displayGraphView.hideNextPreviousButton = true;
      this.displayGraphView.selectionMode = false;                

      this.displayGraphView.show({
          title: '',
          className: 'popupTarget'
      });
    },

    onWellsValidateClick: function() {
      this.changeValidationStatus('well:target:validate');
    },

    onWellsValidate2Click: function() {
      this.changeValidationStatus('well:target:validate2');
    },

    onWellsInValidateClick: function() {
        this.changeValidationStatus('well:target:invalidate');
    },

    onWellsRepeatDnaClick: function() {
        this.changeValidationStatus('well:target:invalidateallextract');
    },

    onWellsRepeatDnaDil12Click: function() {
      var validationUtils = new ValidationUtils({
        models: this.displayGraphView.currentModels,
        modelRun: this.model,
        target: this.channelToDisplay
      });
      validationUtils.end = _.bind(function() {
      }, this);
      validationUtils.repeatDnaDil12();
    },

    onWellsRepeatDnaDil15Click: function() {
      var validationUtils = new ValidationUtils({
        models: this.displayGraphView.currentModels,
        modelRun: this.model,
        target: this.channelToDisplay
      });
      validationUtils.end = _.bind(function() {
      }, this);
      validationUtils.repeatDnaDil15();
    },

    onWellsExpertClick: function() {
        this.changeValidationStatus('well:target:validate:expert-required');
    },

    onWellsConfirmResultClick: function() {
      var validationUtils = new ValidationUtils({
          models: this.displayGraphView.currentModels,
          modelRun: this.model,
          target: this.channelToDisplay
      });
      validationUtils.end = _.bind(function() {
      }, this);
      validationUtils.validate();
    },

    onWellsChangeToNegClick: function() {
      var validationUtils = new ValidationUtils({
          models: this.displayGraphView.currentModels,
          modelRun: this.model,
          target: this.channelToDisplay
      });
      validationUtils.end = _.bind(function() {
      }, this);
      validationUtils.changeToNeg();
    },

    changeValidationStatus: function(status) {
      var target = this.displayGraphView.channelToDisplay;
      var lstString = {
          lst: []
      };
      _.each(this.displayGraphView.currentModels, function(well) {
          lstString.lst.push(well.get('secId'));
      });
      App.request(status, this.model.get('secId'),
        this.displayGraphView.currentModels[0].get('refAssay').secId, target, lstString).then(_.bind(function(result) {
          if(result.run) {
              App.trigger('well:refresh:well', null, result.run.wells);
          } else {
              App.trigger('well:refresh:well', null, result.wells);
          }
      },this)).fail(_.bind(function (err) {
          if (err && err.status === 405) {
              alert(_.i18n('error.noPermission'));
          }
      }, this));
    },

    onDisplayGraphBtnClick: function() {
      this.trigger('well-table:display-graph', this.ui.jqGrid.getRowData());
    },

    onEditRefAssayBtnClick: function() {
      this.trigger('well-table:edit-ref-assay', this.ui.jqGrid.getRowData());
    },

    onEditRefKitLBtnClick: function() {
      this.trigger('well-table:edit-ref-kitl', this.ui.jqGrid.getRowData());
    },

    onEditSmpIdBtnClick: function() {
      this.trigger('well-table:edit-smpid', this.ui.jqGrid.getRowData());
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: 100,
        currentPage: 1
      };
    },

    gridOptions: function(data) {
       var defaultsOptions, options, 
        _colModel = [];

        _colModel.push({
            label: '',
            name: 'secId',
            key: true,
            hidden: true
        });

        _colModel.push({
            label: _.i18n('well.expSt'),
            name: 'expSt',
            classes: 'well-list-pos-label',
            formatter: this.defaultFormatter,
            search: false,
            sortable: false,
            index: 'expSt',
            width: 20
        });

        _colModel.push({
            label: _.i18n('well.pos'),
            name: 'pos',
            classes: 'well-list-pos-label',
            formatter: this.defaultFormatter,
            search: false,
            sortable: false,
            index: 'pos',
            width: 40
        });

        _colModel.push({
            label: _.i18n('well.smpid'),
            name: 'smpid',
            classes: 'well-list-label',
            formatter: _.bind(this.smpIdFormater, this),
            search: false,
            sortable: false,
            index: 'smpid',
            width: 80
        });

        if (this.channelToDisplay) {
            _colModel.push({
                label: _.i18n('well.target'),
                name: 'refAssay',
                classes: 'well-list-label',
                formatter: _.bind(this.displayTargetFormater, this),
                search: false,
                sortable: false,
                index: 'refAssay',
                width: 50
            });
        } else {
            _colModel.push({
                label: _.i18n('well.refAssay'),
                name: 'refAssay.code',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: false,
                sortable: false,
                index: 'refAssay.code',
                width: 50
            });
        }


        _colModel.push({
            label: _.i18n('well.ch1'),
            name: '1',
            classes: 'well-list-label well-ch1',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });

        _colModel.push({
            label: _.i18n('well.ch2'),
            name: '2',
            classes: 'well-list-label well-ch2',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });

        _colModel.push({
            label: _.i18n('well.ch3'),
            name: '3',
            classes: 'well-list-label well-ch3',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });
        _colModel.push({
            label: _.i18n('well.ch4'),
            name: '4',
            classes: 'well-list-label well-ch4',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });
        _colModel.push({
            label: _.i18n('well.ch5'),
            name: '5',
            classes: 'well-list-label well-ch5',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });
        _colModel.push({
            label: _.i18n('well.ch6'),
            name: '6',
            classes: 'well-list-label well-ch6',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });

      if (this.channelToDisplay) {
          _colModel.push({
              label: _.i18n('well.res'+this.channelToDisplay+'Num'),
              name: 'resNum',
              classes: 'well-list-label',
              formatter: _.bind(this.resNumFormatter, this),
              search: false,
              sortable: false,
              index: 'resNum'
          });
      }
      defaultsOptions = this.gridInitOptions(data);
      options = {
          colModel: _colModel,
          sortname: this.sortOn?this.sortOn:'row',
          height: 610
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    smpIdFormater: function(cellValue, col, model) {
      cellValue = this.model.getFormatedSmpId(model);
      return '<span class="cell-default">' + (cellValue || '') + '</span>';
    },

    displayTargetFormater: function(cellValue) {
      var targetName = this.model.getTargetName(cellValue, this.channelToDisplay);
      return '<span class="cell-default">' + targetName + '</span>';
    },

    resNumFormatter: function(cellValue, call, object) {
        var model = App.request('well:model', object);
        return '<span class="cell-default">' + model.getResFormated(this.channelToDisplay) + '</span>';
    },

    squareIconFormatter: function(cellValue, call, object) {

      var start, end;
      start = '<div class="row m-b-0">';
      var i = call.colModel.name;
        this['iconResult'+i+'View'] = new WellResultIconView({model: object, modelRun: this.model, channel: Number(i)});
        this['iconResult'+i+'View'].render();
        start += '<div class="col-xs-12 p-r-0">';
        start += this['iconResult'+i+'View'].$el.html();
        start += '<span class="col-xs-1 p-l-10" style="min-width: 5px; min-height: 20px; font-size:12px;">'+
            (object['res'+i+'Num1']?object['res'+i+'Num1']:' ') + '</span>';
        start += '</div>';

      end = '</div>';

      return start + end;
    },

    onLoadComplete: function(data) {
      this.codeTableData = data;
      if(data && data.length) {
        this.ui.jqGrid.setSelection(data[0].secId).click();
      }
    },

    onPreselectRow: function(rowId) {
      if (this.codeTableData) {
        this.ui.jqgRow.removeClass('btn-secondary');
        this.ui.jqGrid.find('tr#' + rowId).addClass('btn-secondary');
        this.trigger('well-table:loaded', rowId);
      } else {
        return;
      }
    },

    onCheckRowCount: function(rowCount) {
      if (rowCount === 0) {
        this.trigger('well:check-rowcount');
      }
    }
  });

  module.exports = WellTableView;
});
