/**
 * Created by RKL on 13/08/2015.
 */
define('runAssaysTargets2TabController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'runAssaysTargets2TabView',
  'wellDisplayGraphView',
  'targetsView',
  'emptyView',
  'wellDetailView',
  'validationUtils',
  'entities/wells',
  'settings'
], function(
  module,
  _,
  Backbone,
  App,
  View,
  DisplayGraphLayoutView,
  TargetsView,
  EmptyView,
  WellDetailView,
  ValidationUtils
) {
  'use strict';

  var RunAssaysTargets2TabController = {
    showAssaysTargetsTab: function(region, model) {
      this.region = region;
      this.model = model;
      this.createView();
    },

    createView: function() {
      this.view = new View({
        model: this.model
      });
      this.view.listenTo(this.view, 'well:assay-target:click', _.bind(this.onWellsAssayTargetClick, this));
      this.view.listenTo(this.view, 'well:assay-target-previous:click', _.bind(this.onWellsPreviousTargetClick, this));
      this.view.listenTo(this.view, 'well:assay-target-next:click', _.bind(this.onWellsNextTargetClick, this));
      this.view.listenTo(this.view, 'well:assay-target-validate:click', _.bind(this.onWellsValidateClick, this));
      this.view.listenTo(this.view, 'well:assay-target-validate2:click', _.bind(this.onWellsValidate2Click, this));
      this.view.listenTo(this.view, 'well:assay-target-invalidate:click', _.bind(this.onWellsInValidateClick, this));
      this.view.listenTo(this.view, 'well:assay-target-invalidateallextract', _.bind(this.onWellsRepeatDnaClick, this));
      this.view.listenTo(this.view, 'well:assay-target-invalidateallextract-dil12', _.bind(this.onWellsRepeatDnaDil12Click, this));
      this.view.listenTo(this.view, 'well:assay-target-invalidateallextract-dil15', _.bind(this.onWellsRepeatDnaDil15Click, this));
      this.view.listenTo(this.view, 'well:assay-target-confirm-result:click', _.bind(this.onWellsConfirmResultClick, this));
      this.view.listenTo(this.view, 'well:assay-target-change-to-neg:click', _.bind(this.onWellsChangeToNegClick, this));
      this.view.listenTo(this.view, 'well:show-well-detail', _.bind(this.onWellDetailClick, this));
      this.view.listenTo(this.view, 'well:show-well-edit', _.bind(this.onWellEditClick, this));
      this.view.listenTo(this.view, 'well:assay-target-validate-expert-required:click',
          _.bind(this.onWellsValidateExpertRequiredClick, this));
      this.view.listenTo(this.view, 'well:filteredDetailRow', _.bind(this.onFilteredDetailRow, this));
      this.view.listenTo(this.view, 'well:filteredDetailColumn', _.bind(this.onFilteredDetailColumn, this));
      this.view.listenTo(this.view, 'well:removeSelection', _.bind(this.removeSelection, this));
      this.view.listenTo(this.view, 'well:addSelection', _.bind(this.addSelection, this));

      this.region.show(this.view);
    },

    onFilteredDetailColumn: function(pos) {
        this.onRowOrColumnClick(pos, null);
    },

    onFilteredDetailRow: function(pos) {
        this.onRowOrColumnClick(null, pos);
    },

    removeSelection: function(model) {
        if (this.displayGraphView.currentModels.length === 1) {
            this.displayGraphView.selectionMode = false;
        } else {
            var index = this.displayGraphView.currentModels.indexOf(model);
            this.displayGraphView.currentModels.splice(index, 1);
            this.displayGraphView.renderGraphics(this.displayGraphView.currentModels);
        }
    },

    addSelection: function(model) {
        if (this.displayGraphView.selectionMode) {
            this.displayGraphView.currentModels.push(model);
        } else {
            this.displayGraphView.selectionMode = true;
            this.displayGraphView.currentModels = [model];
        }
        this.displayGraphView.renderGraphics(this.displayGraphView.currentModels);
    },

    onWellEditClick: function(well) {
        var wellDetailView = new WellDetailView();
        wellDetailView.modelRun = this.model.clone();
        wellDetailView.model = well.clone();
        wellDetailView.show({
            title: _.i18n('run.edit'),
            className: 'popupEditRun'
        }, _.bind(function () {
            this.onRowOrColumnClick();
        }, this));
    },

    onWellDetailClick: function(well) {
        this.displayGraphView.selectionMode = false;
        this.displayGraphView.currentModels = [well];
        this.displayGraphView.renderGraphics(this.displayGraphView.currentModels);
    },

    onWellsValidateExpertRequiredClick: function() {
        this.changeValidationStatus('well:target:validate:expert-required');
    },

    onWellsValidateClick: function() {
        this.changeValidationStatus('well:target:validate');
    },

    onWellsValidate2Click: function() {
      this.changeValidationStatus('well:target:validate2');
    },

    onWellsInValidateClick: function() {
        this.changeValidationStatus('well:target:invalidate');
    },

    onWellsRepeatDnaClick: function() {
        this.changeValidationStatus('well:target:invalidateallextract');
    },

    onWellsRepeatDnaDil12Click: function() {
        this.emptyView = new EmptyView();
        this.view.getRegion('loader').show(this.emptyView);
        var validationUtils = new ValidationUtils({
            models: this.displayGraphView.currentModels,
            modelRun: this.model,
            target: this.channelToDisplay
        });
        validationUtils.end = _.bind(function() {
            this.emptyView.destroy();
        }, this);
        validationUtils.repeatDnaDil12();
    },

    onWellsRepeatDnaDil15Click: function() {
        this.emptyView = new EmptyView();
        this.view.getRegion('loader').show(this.emptyView);
        var validationUtils = new ValidationUtils({
            models: this.displayGraphView.currentModels,
            modelRun: this.model,
            target: this.channelToDisplay
        });
        validationUtils.end = _.bind(function() {
            this.emptyView.destroy();
        }, this);
        validationUtils.repeatDnaDil15();
    },

    onWellsConfirmResultClick: function() {
        this.emptyView = new EmptyView();
        this.view.getRegion('loader').show(this.emptyView);
        var validationUtils = new ValidationUtils({
            models: this.displayGraphView.currentModels,
            modelRun: this.model,
            target: this.channelToDisplay
        });
        validationUtils.end = _.bind(function() {
            this.emptyView.destroy();
        }, this);
        validationUtils.validate();
    },

    onWellsChangeToNegClick: function() {
        this.emptyView = new EmptyView();
        this.view.getRegion('loader').show(this.emptyView);
        var validationUtils = new ValidationUtils({
            models: this.displayGraphView.currentModels,
            modelRun: this.model,
            target: this.channelToDisplay
        });
        validationUtils.end = _.bind(function() {
            this.emptyView.destroy();
        }, this);
        validationUtils.changeToNeg();
    },

    changeValidationStatus: function(status) {
        var lstString = {
            lst: []
        };
        _.each(this.displayGraphView.currentModels, function(well) {
            lstString.lst.push(well.get('secId'));
        });
        this.emptyView = new EmptyView();
        this.view.getRegion('loader').show(this.emptyView);
        App.request(status, this.model.get('secId'),
            this.currentAssaySecId, this.channelToDisplay, lstString).then(_.bind(function(result) {
            if(result.run) {
                App.trigger('well:refresh:well', null, result.run.wells);
                //alert(result.validationUpdated + '/' + result.validationToUpdate);
            } else {
                App.trigger('well:refresh:well', null, result.wells);
            }
            this.emptyView.destroy();
        },this)).fail(_.bind(function (err) {
            if (err && err.status === 405) {
                alert(_.i18n('error.noPermission'));
                this.emptyView.destroy();
            }
        }, this));
    },

    onWellsPreviousTargetClick: function() {
        var btnsTarget = this.targetsView.$el.find('.btn-target');
        for (var i = 0 ; i < btnsTarget.length ; i++) {
            if($(btnsTarget[i]).hasClass('target-selected')) {
                if (i > 0) {
                    btnsTarget[i - 1].click();
                    break;
                } else {
                    var btnsAssay = this.view.$el.find('.btn-assay-target');
                    for (var j = 0 ; j < btnsAssay.length ; j++) {
                        if($(btnsAssay[j]).hasClass('target-selected')) {
                            if (j > 0) {
                                btnsAssay[j - 1].click();
                                break;
                            } else {
                                btnsAssay[btnsAssay.length - 1].click();
                                break;
                            }
                        }
                    }
                }
            }
        }
    },

    onWellsNextTargetClick: function() {
      var btnsTarget = this.targetsView.$el.find('.btn-target');
      for (var i = 0 ; i < btnsTarget.length ; i++) {
        if($(btnsTarget[i]).hasClass('target-selected')) {
            if (i + 1 < btnsTarget.length) {
                btnsTarget[i + 1].click();
                break;
            } else {
                var btnsAssay = this.view.$el.find('.btn-assay-target');
                for (var j = 0 ; j < btnsAssay.length ; j++) {
                    if($(btnsAssay[j]).hasClass('target-selected')) {
                        if (j + 1 < btnsAssay.length) {
                            btnsAssay[j + 1].click();
                            break;
                        } else {
                            break;
                        }
                    }
                }
            }
        }
      }
    },

    onRowOrColumnClick: function(col, row) {
        var wells = [];
        _.each(this.displayGraphView.allModel, _.bind(function(model) {
            if (col && col.length === 1) {
                col = '0' + col;                
            }
            if ((!col || model.get('pos').endsWith(col)) &&
                (!row || model.get('pos').startsWith(row))) {
                wells.push(model);
            }
        }, this));
        this.displayGraphView.models = wells;
        this.displayGraphView.onChangeCbx();
        this.view.refreshMiniPlateView(this.model, wells, this.channelToDisplay);
    },

    onWellsAssayTargetClick: function(assaySecId, mbAnaSecId, targetSecId, col, row) {
      this.assaySecId = assaySecId;
      this.mbAnaSecId = mbAnaSecId;
      this.targetSecId = targetSecId;
      this.col = col;
      this.row = row;
      var Col = App.request('well:get-collection', this.model.get('secId'), null, null, assaySecId, mbAnaSecId);
        Col.fetch().then(_.bind(function() {
            var wells = [];
            _.each(Col.models, _.bind(function(model) {
                if ((!col || model.get('pos').endsWith(col)) &&
                    (!row || model.get('pos').startsWith(row))) {
                    wells.push(model);
                }
            }, this));

            this.currentAssaySecId = assaySecId;
            var assay = _.findWhere(this.view.data.assays, {secId: assaySecId});
            var region = this.view.getRegion('wellTable');
            if (!this.displayGraphView || !region.currentView) {

                if (targetSecId) {
                    this.target = this.model.getTarget(targetSecId);
                    this.channelToDisplay = this.target.channelNb;
                    this.runAt = this.model.getRunAtFromTarget(targetSecId);
                } else {
                    this.target = null;
                    this.channelToDisplay = assay.kitps[0].channelNb;
                    this.runAt = null;
                }

                this.displayGraphView = new DisplayGraphLayoutView({
                    models: Col.models,
                    model: this.model.clone(),
                    newItem: false,
                    targets: assay.kitps
                });
                this.displayGraphView.allModel = Col.models;
                this.displayGraphView.channelToDisplay = this.channelToDisplay;
                this.displayGraphView.graphToDisplay = this.model.getGraphToDisplay(this.model);
                this.displayGraphView.runAt = this.runAt;
                this.displayGraphView.hideOkButton = true;
                this.displayGraphView.selectionMode = false;

                if (!region.currentView){
                    region.show(this.displayGraphView);
                }

                if (this.targetsView) {
                    this.targetsView.destroy();
                }
            } else {
                this.channelToDisplay = this.displayGraphView.channelToDisplay = assay.kitps[0].channelNb;
                this.displayGraphView.models = wells;
                this.displayGraphView.allModel = Col.models;
                this.displayGraphView.onChangeCbx();
            }

            this.targetsView = new TargetsView({
                targets: assay.kitps
            });

            this.targetsView.listenTo(this.targetsView, 'well:target:click', _.bind(function(channelNb) {
                this.channelToDisplay = this.displayGraphView.channelToDisplay = channelNb;
                this.displayGraphView.allModel = Col.models;
                this.displayGraphView.models = Col.models;
                this.displayGraphView.onChangeCbx();
                this.displayGraphView.selectionMode = false;
                this.view.refreshMiniPlateView(this.model, Col.models, this.channelToDisplay);
            }, this));

            region = this.view.getRegion('targetsView');
            if (region){
                region.show(this.targetsView);
            }

            this.view.refreshMiniPlateView(this.model, Col.models, assay.kitps[0].channelNb);

        }, this));
    },

    onWellsChange: function() {
      this.view.destroy();
      this.createView();
    }
  };

  module.exports = RunAssaysTargets2TabController;
});
