define('entities/runs',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore',
  'colorUtils',
  'wellResultIconView',
  'entities/runtemplates'
], function(
  App,
  Backbone,
  Settings,
  $,
  _,
  ColorUtils,
  WellResultIconView
) {
  'use strict';

  var Run = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/runs/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      'code':  '',
      'plateId': '',
      'plateRowNb': 'H',
      'plateColumnNb': '12',
      'comment': '',
      'status': '0'
    },
    idAttribute: 'secId',

    getImageUid:function() {},

    postUrl: function () {
      return Settings.url('compuzz', 'v2/runs');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    },

    updateWithXmlFile: function(fileInfo) {
      var defer = $.Deferred(),
          formdata =  new FormData(),
          url = Settings.url('compuzz', 'v2/runs/' + this.get('secId'));

      formdata.append('file', fileInfo);

      $.ajax({
        type: 'POST',
        url: url,
        data: formdata,
        processData: false,
        contentType: false,
        success: function(resp) {
          defer.resolve(new Run(resp));
        }
      });

      return defer.promise();
    },

    refreshData: function() {
      var url = Settings.url('compuzz', 'v2/runs/' + this.get('secId') + '/refreshResult'),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(run) {
          defer.resolve(new Run(run));
        },
        error: function(response) {
          defer.reject(response);
        }
      });

      return defer.promise();
    },

    extractOrderIdFromSampleId: function(sampleId) {
        var sidOrderId = this.get('settings')['SID-ORDERID'];
        if (sidOrderId && sampleId) {
            var splitSidOrderId = sidOrderId.split(';');
            sampleId = sampleId.substr(Number(splitSidOrderId[0])>0?Number(splitSidOrderId[0])-1:Number(splitSidOrderId[0]),
                Number(splitSidOrderId[1]));
        }
        return sampleId;
    },

      refreshWellsData: function(lstSecIds) {
        var url = Settings.url('compuzz', 'v2/runs/' + this.get('secId') + '/refreshResult/wells'),
            defer = $.Deferred();

        $.ajax({
            url: url,
            type: 'PUT',
            data: lstSecIds?JSON.stringify(lstSecIds):null,
            contentType: 'application/json',
            success: function(run) {
                defer.resolve(new Run(run));
            },
            error: function(err) {
                defer.reject(err);
            }
        });
        return defer.promise();
    },

    createTemplate: function(name) {
      var params= {};
      if (name) {
        params.name = name;
      }
      var url = Settings.url('compuzz', 'v2/runs/' + this.get('secId') + '/createTemplate', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(runTemplate) {
          defer.resolve(App.request('run:model', runTemplate));
        }
      });

      return defer.promise();
    },

    getFormatedSmpId: function(well) {
      var sidShort = this.get('settings').SID_SHORT;
      if (sidShort) {
        var arSid = sidShort.split(';');
        var from = arSid[0];
        var to = arSid[1];
        var smpid = well.smpid;
        if (!smpid) {
          return '';
        } else if (smpid.length <= parseInt(from, 10)) {
          return smpid;
        } else {
          return smpid.substr(from, to);
        }
      }
    },

    getAssaysTargets: function() {
      var data = {
        wellCCount: 0,
        wellCCountOk: 0,
        wellCCountWarn: 0,
        wellCCountErr: 0,
        wellCount: 0,
        wellCountOk: 0,
        wellCountWarn: 0,
        wellCountErr: 0,
        assays: []
      };
      _.each(this.get('wells'), _.bind(function (well) {
        if (well.refAssay && well.smpid) {
          var assay = _.findWhere(data.assays, {secId: well.refAssay.secId});
          if (!assay) {
            assay = _.clone(well.refAssay);
            assay.kitps = [];
            assay.wells = [];
            data.assays.push(assay);
          }
          if (assay) {
            assay.wells.push(well);
            for (var i = 1 ; i <= 6 ; i++) {
              this.addKitPInAssay(assay, i, well, data);
            }
          }
        }
      }, this));
      return data;
    },

    sortWell: function() {
      this.set('wells', this.get('wells').sort(function(well, well2) {
        var pos1 = well.pos.substr(1) + well.pos.substr(0, 1);
        var pos2 = well2.pos.substr(1) + well2.pos.substr(0, 1);
        return pos1.localeCompare(pos2);
      }));
    },

    addKitPInAssay: function(assay, channel, well, data) {
      var kitp = _.findWhere(assay.kitps, {channelNb: channel});
      if (!kitp) {
        kitp = _.find(this.get('kitps'), function(kitp) {
          return (kitp.refAssay.secId === assay.secId && kitp.channelNb === channel);
        });
        if (kitp) {
          kitp = _.clone(kitp);
          assay.kitps.push(kitp);
          kitp.wells = [];
          kitp.wellCount = 0;
          kitp.wellCountOk = 0;
          kitp.wellCountWarn = 0;
          kitp.wellCountErr = 0;
          kitp.wellCCount = 0;
          kitp.wellCCountOk = 0;
          kitp.wellCCountWarn = 0;
          kitp.wellCCountErr = 0;
          var runAt = _.find(this.get('runAts'), function(runAt) {
            return (runAt.refKitP.secId === kitp.secId);
          });
          if (runAt) {
            kitp.baseline = runAt.baseline;
            kitp.threshold = runAt.threshold;
            kitp.runAtSecId = runAt.secId;
          } else {
            kitp.baseline = '';
            kitp.threshold = '';
            kitp.runAtSecId = '';
          }
        }
      }
      if (kitp) {
        kitp.wells.push(well);
        if (well.smptype === 'C' || well.smptype === 'NC' || well.smptype === 'PC') {
          kitp.wellCCount++;
          data.wellCCount++;
        } else {
          kitp.wellCount++;
          data.wellCount++;
        }

        if (well.smptype === 'C' || well.smptype === 'NC' || well.smptype === 'PC') {
            switch(well.errorInd) {
                case 'E':
                    kitp.wellCCountErr++;
                    data.wellCCountErr++;
                    break;
                case 'W':
                    kitp.wellCCountWarn++;
                    data.wellCCountWarn++;
                    break;
                case 'O':
                    kitp.wellCCountOk++;
                    data.wellCCountOk++;
                    break;
                default:
                    kitp.wellCCountOk++;
                    data.wellCCountOk++;
                    break;
            }
        } else {
            switch(well['res'+channel+'CodeErr']) {
                case 'E':
                    kitp.wellCountErr++;
                    data.wellCountErr++;
                    break;
                case 'W':
                    kitp.wellCountWarn++;
                    data.wellCountWarn++;
                    break;
                case 'O':
                    kitp.wellCountOk++;
                    data.wellCountOk++;
                    break;
                default:
                    kitp.wellCountOk++;
                    data.wellCountOk++;
                    break;
            }
        }

      }
    },

    getRefMbAnaResGrSecId: function(assay, channel) {
        var mbAnaResGrSecId = null;
        if (assay) {
            _.each(this.get('kitps'), _.bind(function (kitp) {
                if (kitp.refAssay.secId === assay.secId && kitp.channelNb === channel &&
                    kitp.refMbAna && kitp.refMbAna.refMbAnaResGr && (!this.get('refMbAModel') ||
                        (kitp.refMbAModel && this.get('refMbAModel').secId === kitp.refMbAModel.secId))) {
                    mbAnaResGrSecId = kitp.refMbAna.refMbAnaResGr.secId;
                }
            }, this));
        }
        return mbAnaResGrSecId;
    },

    getResultColor: function(assay, result, channel) {
      var color = null;
      if (assay && result) {
        _.each(this.get('kitps'), _.bind(function (kitp) {
          if (kitp.refAssay.secId === assay.secId && kitp.channelNb === channel &&
              kitp.refMbAna && kitp.refMbAna.refMbAnaResGr && (!this.get('refMbAModel') ||
                  (kitp.refMbAModel && this.get('refMbAModel').secId === kitp.refMbAModel.secId))) {
            _.each(kitp.refMbAna.refMbAnaResGr.mbAnaRes, function (mbAnaRes) {
              if (mbAnaRes.code === result) {
                color = mbAnaRes.color;
              }
            });
          }
        }, this));
      }
      if (color !== null) {
        color = ColorUtils.decimalToHexa(color);
      }
      return color;
    },

    getResultText: function(assay, result, channel) {
      var text = null;
      if (assay && result) {
        _.each(this.get('kitps'), _.bind(function (kitp) {
          if (kitp.refAssay.secId === assay.secId && kitp.channelNb === channel &&
              kitp.refMbAna && kitp.refMbAna.refMbAnaResGr && (!this.get('refMbAModel') ||
                  (kitp.refMbAModel && this.get('refMbAModel').secId === kitp.refMbAModel.secId))) {

            _.each(this.get('resTrans'), function(resTrans) {
              if (resTrans.refLmbAna.refMbAna.secId === kitp.refMbAna.secId && resTrans.res === result) {
                text = resTrans.name;
              }
            });

            if (!text) {
              _.each(kitp.refMbAna.refMbAnaResGr.mbAnaRes, function (mbAnaRes) {
                if (mbAnaRes.code === result) {
                  text = mbAnaRes.txt;
                }
              });
            }
          }
        }, this));
      }
      return text;
    },

    getTarget: function(targetSecId) {
      var target = null;
      if (targetSecId) {
        _.each(this.get('kitps'), function (kitp) {
            if (kitp.secId === targetSecId) {
                target = kitp;
            }
        });
      }
      return target;
    },

    getTargetByChannel: function(assay, channel) {
        var target = null;
        if (assay && channel) {
            _.each(this.get('kitps'), _.bind(function (kitp) {
                if (kitp.refAssay.secId === assay.secId && kitp.channelNb === channel && (!this.get('refMbAModel') ||
                        (kitp.refMbAModel && this.get('refMbAModel').secId === kitp.refMbAModel.secId))) {
                    target = kitp;
                }
            }, this));
        }
        return target;
    },

    getAlgoFromChannel: function(assay, channel, code) {
      var algo, target = this.getTargetByChannel(assay, channel);

      if (target) {
        algo = target.settings[code];
      }

      if (!algo) {
        algo = this.get('settings')[code];
      }
      return algo;
    },

    getTargetName: function(assay, channel) {
      var text = null;
      if (assay) {
        _.each(this.get('kitps'), _.bind(function (kitp) {
          if (kitp.refAssay.secId === assay.secId && kitp.channelNb === channel &&
              kitp.refMbAna && kitp.refMbAna.refMbAnaResGr && (!this.get('refMbAModel') ||
                  (kitp.refMbAModel && this.get('refMbAModel').secId === kitp.refMbAModel.secId))) {
            text = kitp.refMbAna.code;
          }
        }, this));
      }
      return text?(channel + ' ' + text):channel;
    },

    getKitCQcts: function(well, channel) {
      var controlType = 'NEG';
      if (well.smptype === 'PC') {
        controlType = 'POS';
      }
      var kitcqcts = null;
      if (well.refAssay) {
          _.each(this.get('kitcqcts'), _.bind(function(kitcqct) {
              if (kitcqct.refKitP.refAssay.secId === well.refAssay.secId &&
                  kitcqct.refKitP.channelNb === channel &&
                  kitcqct.refKitC.ctrlType === controlType) {
                  if (kitcqct.algoCtrlType === 'ABSOLUTE_VAL') {
                      kitcqcts = kitcqct;
                  }
              }
          }));
      }
      return kitcqcts;
    },

    getKitCQctsValue: function(well, channel) {
      var controlType = 'NEG';
      if (well.get('smptype') === 'PC') {
          controlType = 'POS';
      }
      var kitcqcts = '';
      var value = '';
      if (well.get('refAssay')) {
        _.each(this.get('kitcqcts'), _.bind(function(kitcqct) {
          if (kitcqct.refKitP.refAssay.secId === well.get('refAssay').secId &&
              kitcqct.refKitP.channelNb === channel &&
              (kitcqct.refKitC.code === well.get('smpid') ||
                  ((!well.get('smptype') || well.get('smptype') === 'U') &&
                  kitcqct.refKitP.ic && kitcqct.refKitC.ctrlType === controlType))) {
              if (kitcqct.algoCtrlType === 'ABSOLUTE_VAL') {
                value = '(' + kitcqct.qcTargetValNum +' +/- '+ kitcqct.valueWarn +', '+ kitcqct.valueErr +')';
              } else if (kitcqct.algoCtrlType === 'EXACT_RES') {
                kitcqcts = kitcqct.qcTargetValCode;
              }
          }
        }));
      }
      if (kitcqcts) {
          return kitcqcts + ' ' + value;
      }
      return '';
    },

    isUsingChannel: function(assay, channel) {
      var useThisChannel = false;
      if (assay && channel) {
        _.each(this.get('kitps'), _.bind(function (kitp) {
          if (kitp.channelNb === channel && kitp.refAssay.secId === assay.secId && (!this.get('refMbAModel') ||
                  (kitp.refMbAModel && this.get('refMbAModel').secId === kitp.refMbAModel.secId))) {
            useThisChannel = true;
            return;
          }
        }, this));
      } else {
        useThisChannel = true;
      }
      return useThisChannel;
    },

    getChannelFromTarget: function(targetSecId) {
      var channel = null, target;
      target = this.getTarget(targetSecId);
      if (target) {
        channel = target.channelNb;
      }
      return channel;
    },

    getRunAtFromTarget: function(targetSecId) {
      var runAt = _.find(this.get('runAts'), function(runAt) {
        return (runAt.refKitP.secId === targetSecId);
      });
      return runAt;
    },

    getResultForWell: function(well) {
      var results = [];
      for (var i = 1 ; i <= 6 ; i++) {
        if (this.isUsingChannel(well.get('refAssay'), i)) {
          var result = {};
          result.index = i;
          result.target = this.getTargetName(well.get('refAssay'), i);
          result.valSt = well.get('val' + i + 'St');
          result.resColor = this.getResultColor(well.get('refAssay'), well.get('res' + i + 'Code'), i);
          result.resTxt = this.getResultText(well.get('refAssay'), well.get('res' + i + 'Code'), i);
          result.resCode = well.get('res' + i + 'Code');
          result.comment = well.get('res' + i + 'Comment');
          result.resCodeErr = well.get('res' + i + 'CodeErr');
          result.resCommentErrAuto = well.get('res' + i + 'CommentErrAuto');
          result.user = well.get('refVal' + i + 'User');
          result.user2 = well.get('refVal' + i + 'User2');
          result.resFormated = well.getResFormated(i);
          result.propC = well.get('propC'+i);
          result.propCFormated = [];
          for (var prop in result.propC) {
              result.propCFormated.push(prop + ': ' + result.propC[prop]);
          }
          result.propCFormated = result.propCFormated.join(', ');
          result.valColor = '';
          var kitcqct = this.getKitCQctsValue(well, i);
          if (kitcqct) {
              result.kitcqct = _.i18n('well.qcTarget') + ' ' + kitcqct;
          } else {
              result.kitcqct = '';
          }

          var iconResult1View = new WellResultIconView({model: well.toJSON(), modelRun: this, channel: i});
          iconResult1View.render();
          result.iconHtml = iconResult1View.$el.html();

          results.push(result);
        }
      }
      return results;
    },

    getGraphToDisplay: function(well) {
      if (well && well.get('refAssay') && well.get('refAssay').refKit &&
          well.get('refAssay').refKit.refKitProt &&
          well.get('refAssay').refKit.refKitProt.resGraph) {
        return well.get('refAssay').refKit.refKitProt.resGraph;
      } else if(this.get('refKitProt') && this.get('refKitProt').resGraph) {
        return this.get('refKitProt').resGraph;
      } else {
        return 'AM';
      }
    }
  });

  var RunCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/runs');
    },
    model: Run
  });

  var API = {

    importXmlFile: function(fileInfo, lmbAnalyzer, kitProt) {
      var defer = $.Deferred(),
          formdata =  new FormData(),
          param = {};

      if (lmbAnalyzer) {
        param.lmbAnalyzerSecId = lmbAnalyzer.get('secId');
      }
      if (kitProt) {
        param.kitProtSecId = kitProt.get('secId');
      }

      var url = Settings.url('compuzz', 'v2/runs', param);

      formdata.append('file', fileInfo);

      $.ajax({
        type: 'POST',
        url: url,
        data: formdata,
        processData: false,
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        },
        error: function() {
          defer.reject();
        }
      });

      return defer.promise();
    },

    getRunCollectionUrl: function() {
      new RunCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/runs', params);
    },

    getAllRuns: function(params) {
      var url = Settings.url('compuzz', 'v2/runs', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(runs) {
          var runsList = [];
          _.each(runs, function(run){
            runsList.push(run.secId);
          });
          defer.resolve(runsList);
        }
      });

      return defer.promise();
    },

    getRunCountUrl: function() {
      var params = '';
      return 'v2/runs/rowCount' + params;
    },

    deleteRun: function(secId){
      var url = Settings.url('compuzz', 'v2/runs/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    fromTemplate: function(templateSecId) {
      var url = Settings.url('compuzz', 'v2/runs/fromTemplate/' + templateSecId),
          defer = $.Deferred();

      $.ajax({
        method: 'post',
        url: url,
        success: function(run) {
          defer.resolve(new Run(run));
        }
      });

      return defer.promise();
    },

    resetValidation: function(runSecId) {
        var defer = $.Deferred(),
            url = Settings.url('compuzz', 'v2/runs/' + runSecId + '/resetValidation');

        $.ajax({
            url: url,
            method: 'PUT',
            success: function(resp) {
                defer.resolve(resp);
            },
            error: function() {
                defer.reject();
            }
        });
        return defer.promise();
    },

    autoValidation: function(runSecId) {
        var defer = $.Deferred(),
            url = Settings.url('compuzz', 'v2/runs/' + runSecId + '/autovalidation');

        $.ajax({
            url: url,
            method: 'PUT',
            success: function(resp) {
                defer.resolve(resp);
            },
            error: function(err) {
                defer.reject(err);
            }
        });
        return defer.promise();
    },

    getSampleIds: function(runSecId, sampleId) {
        var url = Settings.url('compuzz', 'v2/runs/' + runSecId + '/wells/' + sampleId),
            defer = $.Deferred();

        $.ajax({
            method: 'get',
            url: url,
            success: function(runs) {
              var wells = [], run, well, j, i;
              for (i = 0 ; i < runs.length ; i++) {
                run = runs[i];
                for (j = 0 ; j < run.wells.length ; j++) {
                  well = run.wells[j];
                  well.refRun = run;
                  wells.push(well);
                }
              }
              defer.resolve(wells);
            }
        });

        return defer.promise();
    },

    getWellSampleIdsCollectionUrl: function(runSecId, sampleId, params) {
      if (!params) {
        params = {};
      }
      return Settings.url('compuzz', 'v2/runs/' + runSecId + '/wells/' + sampleId, params);
    },

    getWellQueryCollectionUrl: function(params) {
      if (!params) {
          params = {};
      }
      return Settings.url('compuzz', 'v2/runs/wells/find', params);
    },

    countWellQueryCollectionUrl: function(params) {
        var stringParams = '',
        ampersand = '?';
        _.each(params, function (value, key) {
            if ((value || value === 0) && key) {
                stringParams += (ampersand + key + '=' + value.toString());
                ampersand = '&';
            }
        });
      return 'v2/runs/wells/find/rowCount' + stringParams;
    }
  };

  App.reqres.setHandler('run:get-collection-url', function() {
      return API.getRunCollectionUrl();
  });

  App.reqres.setHandler('run:get-collection-to-page', function(params) {
      return API.getAllRuns(params);
  });

  App.reqres.setHandler('run:get-count-url', function() {
    return API.getRunCountUrl();
  });

  App.reqres.setHandler('run:model', function(runJSON) {
    return new Run(runJSON);
  });

  App.reqres.setHandler('run:delete', function(secId) {
    return API.deleteRun(secId);
  });

  App.reqres.setHandler('run:upload-xml', function(fileInfo, lmbAnalyzer, kitProt) {
    return API.importXmlFile(fileInfo, lmbAnalyzer, kitProt);
  });

  App.reqres.setHandler('run:from-template', function(templateSecId) {
    return API.fromTemplate(templateSecId);
  });

  App.reqres.setHandler('run:reset-validation', function(runSecId) {
    return API.resetValidation(runSecId);
  });

  App.reqres.setHandler('run:auto-validation', function(runSecId) {
    return API.autoValidation(runSecId);
  });

  App.reqres.setHandler('run:get-sampleids', function(runSecId, sampleId) {
    return API.getSampleIds(runSecId, sampleId);
  });

  App.reqres.setHandler('run:get-collection-sampleids-url', function(runSecId, sampleId, params) {
    return API.getWellSampleIdsCollectionUrl(runSecId, sampleId, params);
  });

  App.reqres.setHandler('run:get-collection-query-url', function(params) {
    return API.getWellQueryCollectionUrl(params);
  });

    App.reqres.setHandler('run:count-collection-query-url', function(params) {
        return API.countWellQueryCollectionUrl(params);
    });
});
