/**
 * Created by BFR on 18/04/2019.
 */
define('sampleIdsMain',[
  'module',
  'logger',
  'sampleIdsMainRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var SampleIds = {
    start: function() {
      Logger.log(module, 'starting SampleModule');
    },

    stop: function() {
      Logger.log(module, 'stopping SampleModule');
    }
  };

  module.exports = SampleIds;
});
