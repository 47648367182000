/**
 * Created by RKL on 20/08/2015.
 */
define('autocompleteView',[
  'module',
  'marionette',
  'marionetteAutocomplete',
  'underscore',
  'app',
  'settings',
  'template!autocompleteTpl',
  'backbone'
], function (
  module,
  Marionette,
  MarionetteAutocomplete,
  _,
  App,
  Settings,
  viewTpl,
  Backbone
) {
  'use strict';

  var KEY_ENTER = 13, KEY_DOWN = 40, KEY_UP = 38, KEY_ESC = 27;

  var autocompleteView =  Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      autocomplete: 'input.autocomplete'
    },

    className: 'pos-rel',

    events: {
      'change @ui.autocomplete': 'onChange',
      'focus @ui.autocomplete': 'onClick',
      'keyup @ui.autocomplete': 'showDropdown',
      'click .js-dropdown-icon': 'onIconClick',
      'keydown @ui.autocomplete': 'onKeyDown'
    },

    triggers: {
      'blur @ui.autocomplete': 'blur'
    },

    initialize: function() {
      this.listenTo(this, 'autocomplete:selected', _.bind(this.onSelect, this));
    },

    serializeData: function() {
      return {
        placeholder: this.options.placeholder || null,
        value: this.options.value || null,
        fieldName: this.options.name || null,
        inputType: this.options.inputType || null
      };
    },

    clearSelection: function() {
      this.selected = false;
      this.ui.autocomplete.val('');
      this._nullifyIfNotSelected();
    },

    onBeforeAttach: function() {
      var that = this;
      this.ui.autocomplete.parent().on('show.bs.dropdown', function() {
        that.ui.autocomplete.parent().next('ul').show();
      });
      this.ui.autocomplete.parent().on('hide.bs.dropdown', function() {
        that.ui.autocomplete.parent().next('ul').hide();
        that._nullifyIfNotSelected();
      });
    },

    onShown: function() {
      this.selectTextInInput(this.ui.autocomplete);
    },

    onSelect: function(model) {
      var target = this.ui.autocomplete,
        fieldName = target.data('field-name'),
        callback = this.options.callback;

      if (callback) {
        callback(fieldName, model, this);
      }
      this.selected = true;
      this.selectTextInInput(target);
    },

    selectTextInInput: function(target) {
      setTimeout(function(){
        target.get(0).setSelectionRange(0, target.val().length);
      }, 10);
    },

    onChange: function(event) {
      this.changed = true;
      var callback = this.options.callback;
      if (this.ui.autocomplete && !this.ui.autocomplete.val() && callback) {
        callback(this.ui.autocomplete.data('field-name'), null, this);
      } else {
        if (event.currentTarget.className !== 'ac-suggestion' && this._behaviors[0].collectionView.collection.length === 1) {
            callback(this.ui.autocomplete.data('field-name'), this._behaviors[0].collectionView.collection.models[0], this);
            this.ui.autocomplete.val(this._behaviors[0].collectionView.collection.models[0].get(this.options.valueKey));
        }
      }
    },

    showDropdown: function(e) {
      if(this.options.alwaysOpen) return;
      // We require this for the case when user navigates to autocomplete by tabbing
      if(e && (e.keyCode || e.which) &&
        e.which !== KEY_ESC && e.keyCode !== KEY_ESC &&
        e.which !== KEY_ENTER && e.keyCode !== KEY_ENTER) {
        if(!this.ui.autocomplete.parent().hasClass('open')) {
          this.ui.autocomplete.dropdown('toggle');
        }
      }
    },

    _nullifyIfNotSelected: function() {
      var that = this,
        target = this.ui.autocomplete,
        fieldName = target.data('field-name'),
        callback = this.options.callback,
        inputValue, valueInCollection;
      if (!that.selected && this.changed && that.options.type !== 'remote') {
        inputValue = this.ui.autocomplete.val();
        valueInCollection = this._valueInCollection(inputValue);
        if(valueInCollection) {
          that.ui.autocomplete.val(valueInCollection[this.options.valueKey]).change();
          if(callback) callback(fieldName, new Backbone.Model(valueInCollection, this));
        } else {
          that.ui.autocomplete.val('').change();
          if(callback) callback(fieldName, null, this);
        }

      }
      that.selected = false;
      this.changed  = false;
    },

    _valueInCollection: function(inputValue) {
      var that = this;

      if(!inputValue) return;

      return _.find(this.options.data, function(model) {
        return model[that.options.valueKey].toLowerCase() === inputValue.trim().toLocaleLowerCase();
      });
    },

    onClick: function($e) {
      var focusin = typeof $e.originalEvent === 'object' ? true : false;
      this.ui.autocomplete.trigger('keyup', focusin);
      this.trigger('focus');
    },

    onIconClick: function(e) {
      e.stopPropagation();
      this.ui.autocomplete.click();
    },

    onKeyDown: function(e) {
      var charCode = (e.which) ? e.which : event.keyCode;

      if (charCode === KEY_ENTER && !this.options.onPressKey) {
        return false;
      } else if(charCode === KEY_DOWN || charCode === KEY_UP) {
        return false;
      }
    },

    behaviors: function() {
      var that = this,
        type = this.options.type || 'dataset',
        remote = this.options.remote || null,
        keys = this.options.keys || null,
        limit = this.options.limit || 10,
        rateLimit = this.options.lazyLoad ? 500 : 0;

      var behavior = {
        AutoComplete: {
          behaviorClass: MarionetteAutocomplete.Behavior,
          rateLimit: rateLimit,
          collection: {
            options: {
              minLength: -1,
              type: type,
              remote: remote,
              keys: keys,
              inputType: this.options.inputType,
              data: this.options.data,
              valueKey: this.options.valueKey,
              lazyLoad: this.options.lazyLoad,
              values: {
                apiKey: this.options.apiKey,
                limit: limit
              }
            }
          }
        }
      };

      if (this.options.childTemplate) {
        behavior.AutoComplete.childView = {
          'class': MarionetteAutocomplete.ChildView.extend({
            getTemplate: function() {
              if(!this.model.get('autocompleteValue')) {
                this.model.set({autocompleteValue: 'Loading'});
              }
              return that.options.childTemplate;
            },

            serializeData: function() {
              if(that.options.childViewSerializeData) {
                return _.bind(that.options.childViewSerializeData, this)();
              }
              var templateData = this.model.toJSON(),
                params = {
                  action: 'res',
                  params: 't'
                };

              templateData.url = Settings.url(
                'file',
                this.model.get('cataloguePictureUid'),
                params
              );

              return templateData;
            }
          })
        };
      }

      return behavior;
    }

  });

  module.exports = autocompleteView;
});
