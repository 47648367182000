define('runDetailsGraphView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'moment',
  'mobioChartView',
  'colorUtils'
], function (
  module,
  Marionette,
  _,
  App,
  moment,
  MobioChartView,
  ColorUtils
) {
  'use strict';

  var colorTarget = function(target, run) {
    if (run && run.get('settings') && run.get('settings').TARGETS_COLORS) {
      var col = run.get('settings').TARGETS_COLORS.split(';')[target + 1];
      return ColorUtils.hexToRgb(col);
    } else {
      switch(target) {
        case 1: return {
          r:226,
          g:147,
          b:239
        };
        case 2: return { 
          r:0,
          g:0,
          b:239
        };
        case 3: return {
          r:0,
          g:239,
          b:0
        };
        case 4: return {
          r:239,
          g:0,
          b:0
        };
        case 5: return {
          r:239,
          g:0,
          b:239
        };
        case 6: return {
          r:0,
          g:239,
          b:239
        };
      }
    }
    
  };

  var RunGraphView =  MobioChartView.extend({

      serializeData: function() {

      this.data = {
        datasets : [
        ]
      };
      var curve1, curve2, curve3, curve4, curve5, curve6, algo1, algo2, algo3, algo4, algo5, algo6;
      if (!this.amplGraph) {
        curve1 = (!this.channelToDisplay || this.channelToDisplay === 1)?this.model.get('meltCurve1'):null;
        curve2 = (!this.channelToDisplay || this.channelToDisplay === 2)?this.model.get('meltCurve2'):null;
        curve3 = (!this.channelToDisplay || this.channelToDisplay === 3)?this.model.get('meltCurve3'):null;
        curve4 = (!this.channelToDisplay || this.channelToDisplay === 4)?this.model.get('meltCurve4'):null;
        curve5 = (!this.channelToDisplay || this.channelToDisplay === 5)?this.model.get('meltCurve5'):null;
        curve6 = (!this.channelToDisplay || this.channelToDisplay === 6)?this.model.get('meltCurve6'):null;
        algo1 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 1, 'MC-SMOO');
        algo2 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 2, 'MC-SMOO');
        algo3 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 3, 'MC-SMOO');
        algo4 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 4, 'MC-SMOO');
        algo5 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 5, 'MC-SMOO');
        algo6 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 6, 'MC-SMOO');
      } else {
        curve1 = (!this.channelToDisplay || this.channelToDisplay === 1)?this.model.get('amplCurve1'):null;
        curve2 = (!this.channelToDisplay || this.channelToDisplay === 2)?this.model.get('amplCurve2'):null;
        curve3 = (!this.channelToDisplay || this.channelToDisplay === 3)?this.model.get('amplCurve3'):null;
        curve4 = (!this.channelToDisplay || this.channelToDisplay === 4)?this.model.get('amplCurve4'):null;
        curve5 = (!this.channelToDisplay || this.channelToDisplay === 5)?this.model.get('amplCurve5'):null;
        curve6 = (!this.channelToDisplay || this.channelToDisplay === 6)?this.model.get('amplCurve6'):null;
        algo1 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 1, 'AC-SMOO');
        algo2 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 2, 'AC-SMOO');
        algo3 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 3, 'AC-SMOO');
        algo4 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 4, 'AC-SMOO');
        algo5 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 5, 'AC-SMOO');
        algo6 = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), 6, 'AC-SMOO');
      }

      if ( !_.isUndefined(curve1) && !_.isEmpty(curve1) && this.modelRun.isUsingChannel(this.model.get('refAssay'), 1)) {
        var colorTarget1 = colorTarget(1, this.modelRun); 
        this.data.datasets.push(this.addData(curve1, colorTarget1.r, colorTarget1.g, colorTarget1.b,
            this.modelRun.getTargetName(this.model.get('refAssay'), 1), algo1));
      }
      if ( !_.isUndefined(curve2) && !_.isEmpty(curve2) && this.modelRun.isUsingChannel(this.model.get('refAssay'), 2)) {
        var colorTarget2 = colorTarget(2, this.modelRun); 
        this.data.datasets.push(this.addData(curve2, colorTarget2.r, colorTarget2.g, colorTarget2.b,
            this.modelRun.getTargetName(this.model.get('refAssay'), 2), algo2));
      }
      if ( !_.isUndefined(curve3) && !_.isEmpty(curve3) && this.modelRun.isUsingChannel(this.model.get('refAssay'), 3)) {
        var colorTarget3 = colorTarget(3, this.modelRun); 
        this.data.datasets.push(this.addData(curve3, colorTarget3.r, colorTarget3.g, colorTarget3.b,
            this.modelRun.getTargetName(this.model.get('refAssay'), 3), algo3));
      }
      if ( !_.isUndefined(curve4) && !_.isEmpty(curve4) && this.modelRun.isUsingChannel(this.model.get('refAssay'), 4)) {
        var colorTarget4 = colorTarget(4, this.modelRun); 
        this.data.datasets.push(this.addData(curve4, colorTarget4.r, colorTarget4.g, colorTarget4.b,
            this.modelRun.getTargetName(this.model.get('refAssay'), 4), algo4));
      }
      if ( !_.isUndefined(curve5) && !_.isEmpty(curve5) && this.modelRun.isUsingChannel(this.model.get('refAssay'), 5)) {
        var colorTarget5 = colorTarget(5, this.modelRun); 
        this.data.datasets.push(this.addData(curve5, colorTarget5.r, colorTarget5.g, colorTarget5.b,
            this.modelRun.getTargetName(this.model.get('refAssay'), 5), algo5));
      }
      if ( !_.isUndefined(curve6) && !_.isEmpty(curve6) && this.modelRun.isUsingChannel(this.model.get('refAssay'), 6)) {
        var colorTarget6 = colorTarget(6, this.modelRun); 
        this.data.datasets.push(this.addData(curve6, colorTarget6.r, colorTarget6.g, colorTarget6.b,
            this.modelRun.getTargetName(this.model.get('refAssay'), 6), algo6));
      }

      if (this.data.datasets.length) {
        if (this.baseline !== null && this.baseline !== undefined) {
          this.data.datasets.push(this.addLine(this.baseline, 'baseline', 255, 0, 0));
        }
        if (this.threshold !== null && this.threshold !== undefined) {
          this.data.datasets.push(this.addLine(this.threshold, 'threshold', 0, 0, 255));
        }
      }

      this.generateLegend();
      return this.data;
    }
  });


  module.exports = RunGraphView;
  module.exports.colorTarget = colorTarget;
});
