
define('template!runAssaysTargets2TabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="tab-content content">\n  <div class="well col-xs-3">\n    <div class="run-detail col-xs-12 col-md-5">\n      <div class="row">\n        <div class="col-xs-12">\n          <div class="row">\n            <div class="col-xs-12">\n              <label>' +
((__t = ( _.i18n('well.assay') )) == null ? '' : __t) +
'</label>\n            </div>\n          </div>\n        </div>\n      </div>\n      <hr class="m-t-5 m-b-5"/>\n      ';
 var firstAssay = true; ;
__p += '\n      ';
 _.each(data.assays, function(assay) { var printAssay = true; ;
__p += '\n      <div class="row">\n        <div class="col-xs-12">\n          <div class="row">\n            <div class="btn-assay-target clickable col-xs-12 ' +
((__t = ( firstAssay?'target-selected':'')) == null ? '' : __t) +
'"\n                 data-assay-secid="' +
((__t = ( assay.secId )) == null ? '' : __t) +
'">\n              <label class="clickable">' +
((__t = ( printAssay?assay.code:'' )) == null ? '' : __t) +
'</label>\n            </div>\n            ';
 firstAssay = false; ;
__p += '\n          </div>\n        </div>\n      </div>\n      ';
 printAssay = false; ;
__p += '\n      ';
 }); ;
__p += '\n    </div>\n    <div class="col-md-2" style="width: 0;"></div>\n    <div id="targets-view" class="col-xs-12 col-md-5">\n\n    </div>\n    <div class="col-xs-12">\n\n    </div>\n    <div id="mini-plate-view">\n\n    </div>\n  </div>\n  <div class="col-md-1" style="width: 0;"></div>\n  <div class="well run-detail col-xs-8" style="">\n    <div class="col-xs-12 p-l-0" style="display: none;">\n      <div class="row">\n        <div class="col-xs-6 p-r-0">\n          <div class="col-xs-3 p-l-5 well">\n            <div class="col-xs-12 p-l-0">\n              <label class="lbl-assay"></label>\n            </div>\n            <div class="col-xs-12 p-l-0">\n              <label class="lbl-target"></label>\n            </div>\n          </div>\n          <div class="col-xs-3 p-l-5 well">\n\n          </div>\n          <div class="col-xs-3 p-l-5 well">\n\n          </div>\n          <div class="col-xs-3 p-l-5 well">\n\n          </div>\n        </div>\n        <div class="col-xs-6 p-l-0 p-r-0">\n          <div class="col-xs-4">\n            <label class="btn add-btn-text btn-sm btn-invalidate btn-target-view-top col-xs-12 m-b-0 m-t-0">\n              <i class="mdi-av-new-releases"></i>\n              <span>' +
((__t = ( _.i18n('well.invalidate') )) == null ? '' : __t) +
'</span>\n            </label>\n          </div>\n          <div class="col-xs-4">\n            <label class="btn add-btn-text btn-sm btn-validate btn-target-view-top col-xs-12 m-b-0 m-t-0">\n              <i class="mdi-action-done"></i>\n              <span>' +
((__t = ( _.i18n('well.validate') )) == null ? '' : __t) +
'</span>\n            </label>\n          </div>\n          <div class="col-xs-4">\n            <label class="btn add-btn-text btn-sm btn-expert-required btn-target-view-top col-xs-12 m-t-0">\n              <i class="mdi-action-verified-user"></i>\n              <span>' +
((__t = ( _.i18n('well.expertRequired') )) == null ? '' : __t) +
'</span>\n            </label>\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-xs-6">\n          <label class="btn add-btn-text btn-sm btn-previous col-xs-12 m-b-0 m-t-0">\n            <i class="mdi-image-navigate-before"></i>\n            <span>' +
((__t = ( _.i18n('common.previous') )) == null ? '' : __t) +
'</span>\n          </label>\n        </div>\n        <div class="col-xs-6">\n          <label class="btn add-btn-text btn-sm btn-next col-xs-12 m-b-0 m-t-0">\n            <i class="mdi-image-navigate-next"></i>\n            <span>' +
((__t = ( _.i18n('common.next') )) == null ? '' : __t) +
'</span>\n          </label>\n        </div>\n      </div>\n    </div>\n    <div id="right-view" class="col-xs-12">\n    </div>\n  </div>\n</div>\n<div id="region-loader">\n</div>';

}
return __p
};});

