
define('template!kitpCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitp.refMbAModel') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-mbamodel-region"></div>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitp.refMbAna') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-mbaana-region"></div>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="channelNb">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitp.channelNb') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="channelNb"\n                 placeholder="' +
((__t = ( _.i18n('kitp.channelNb') )) == null ? '' : __t) +
'" value="' +
((__t = ( channelNb )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="algoresCh1">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitp.algoresCh1') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="algoresCh1"\n                 placeholder="' +
((__t = ( _.i18n('kitp.algoresCh1') )) == null ? '' : __t) +
'" value="' +
((__t = ( algoresCh1 )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="paramCh1">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitp.paramCh1') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="paramCh1"\n                 placeholder="' +
((__t = ( _.i18n('kitp.paramCh1') )) == null ? '' : __t) +
'" value="' +
((__t = ( paramCh1 )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="algoresACurve">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitp.algoresACurve') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="algoresACurve"\n                 placeholder="' +
((__t = ( _.i18n('kitp.algoresACurve') )) == null ? '' : __t) +
'" value="' +
((__t = ( algoresACurve )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="paramACurve">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitp.paramACurve') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="paramACurve"\n                 placeholder="' +
((__t = ( _.i18n('kitp.paramACurve') )) == null ? '' : __t) +
'" value="' +
((__t = ( paramACurve )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="algoresMCurve">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitp.algoresMCurve') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="algoresMCurve"\n                 placeholder="' +
((__t = ( _.i18n('kitp.algoresMCurve') )) == null ? '' : __t) +
'" value="' +
((__t = ( algoresMCurve )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="paramMCurve">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitp.paramMCurve') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="paramMCurve"\n                 placeholder="' +
((__t = ( _.i18n('kitp.paramMCurve') )) == null ? '' : __t) +
'" value="' +
((__t = ( paramMCurve )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group checkbox-group" data-field-name="ic">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("kitp.ic") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 checkbox checkbox-primary">\n          <label>\n            <input type="checkbox" id="ic" data-field-name="ic" class="js-keep-logged-in js-info-input" ' +
((__t = ( ic ? 'checked' : '' )) == null ? '' : __t) +
'>\n          </label>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

