define('kitCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!kitCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'settings',
  'entities/kits'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView,
  Settings
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      createKitRadio: '.create-kit-radio',
      createKitRadioManuf: '.create-kit-radio-manuf',
      createKitFromEmpty: '.createKitFromEmpty',
      createKitFromManufacturer: '.createKitFromManufacturer',
      shareKitFromManufacturer: '.shareKitFromManufacturer'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm',
      'change @ui.createKitRadio': 'onCreateKitRadioChange'
    },

    regions: {
      cAccountSelect: '.js-select-account-region',
      cAccount1Select: '.js-select-account1-region',
      kitSelect: '.js-select-kit-region',
      kit1Select: '.js-select-kit1-region',
      kitProtSelect: '.js-select-kitprot-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'code', type: 'required'}
    ],

    copy: true,

    initialize: function(options) {
      this.model = options.kitModel.clone();
      this.copy = options.newItem;
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();
      this.triggerMethod('enable:cancel:confirm');

      var cAccountView = new AutocompleteView(
          this._getAutocompleteOptions('', 'code', 'code', 'v2/caccounts/manufacturer',
              'selectmanufacturer.placeholder', 'code', this.onChangeManufacturer)
      );
      this.getRegion('cAccountSelect').options.allowMissingEl = true;
      this.getRegion('cAccountSelect').show(cAccountView);

      var cAccount1View = new AutocompleteView(
          this._getAutocompleteOptions('', 'code', 'code', 'v2/caccounts/manufacturer',
              'selectmanufacturer.placeholder', 'code', this.onChangeManufacturer)
      );
      this.getRegion('cAccount1Select').options.allowMissingEl = true;
      this.getRegion('cAccount1Select').show(cAccount1View);

      var kitView = new AutocompleteView(
          this._getKitAutocompleteOptions()
      );
      this.getRegion('kitSelect').options.allowMissingEl = true;
      this.getRegion('kitSelect').show(kitView);

      var kit1View = new AutocompleteView(
          this._getKitAutocompleteOptions()
      );
      this.getRegion('kit1Select').options.allowMissingEl = true;
      this.getRegion('kit1Select').show(kit1View);

      var kitProtView = new AutocompleteView(
          this._getAutocompleteOptions('refKitProt', 'code', 'code', 'v2/kitprots',
              'selectkitprot.placeholder', 'code')
      );
      this.getRegion('kitProtSelect').options.allowMissingEl = true;
      this.getRegion('kitProtSelect').show(kitProtView);
    },

    onChangeManufacturer: function(fieldName, model) {
      this.currentManufacturer = model.toJSON();
      var kitView = new AutocompleteView(
          this._getKitAutocompleteOptions()
      );
      this.getRegion('kitSelect').show(kitView);

      var kit1View = new AutocompleteView(
          this._getKitAutocompleteOptions()
      );
      this.getRegion('kit1Select').show(kit1View);
    },

    _getKitAutocompleteOptions: function() {
      return {
        type: 'remote',
        remote: Settings.url('compuzz', 'v2/kits/shared', {
          cAccountSecIdFrom: this.currentManufacturer?this.currentManufacturer.secId:null
        }),
        valueKey: 'code',
        apiKey: 'secId',
        placeholder: _.i18n('selectkit.placeholder'),
        value: null,
        name: null,
        onPressKey: true,
        lazyLoad: true,
        keys: {
          query: 'code',
          limit: 'pageSize'
        },
        callback: _.bind(this.onChangeKit, this)
      };
    },

    onChangeKit: function(fieldName, model) {
      this.currentManufacturerKit = App.request('kit:model', model.toJSON());
    },

    onCreateKitRadioChange: function() {
      if(this.ui.createKitRadio){
        this.copy = false;
        this.share = false;
        _.each(this.ui.createKitRadio, _.bind(function(radio) {
          switch(radio.value) {
            case 'copy':
                  if (radio.checked) {
                    this.copy = true;
                  }
                  this.ui.createKitFromManufacturer.toggleClass('hidden',!radio.checked);
                  break;
            case 'share':
              if (radio.checked) {
                this.share = true;
              }
              this.ui.shareKitFromManufacturer.toggleClass('hidden',!radio.checked);
              break;
            case 'notcopy':
                  this.ui.createKitFromEmpty.toggleClass('hidden',!radio.checked);
                  break;
          }
        }, this));
      }
    },

    onConfirm: function() {
      if (this.copy) {
        if (this.currentManufacturerKit) {
          this.currentManufacturerKit.copyShared().done(_.bind(function() {
            this.hide();
          }, this));
        }
      } else if(this.share) {
        if (this.currentManufacturerKit) {
          this.currentManufacturerKit.shareShared().done(_.bind(function() {
            this.hide();
          }, this));
        }
      } else {
        if(this.validate(this.fieldsToValidate)) {
          this.model.save();
        }
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.options.kitModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('kit:header:rerender', modelJSON);
      App.navigate('admin/kits/' + model.get('secId'), {trigger: true});
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'admin/kits/new') {
        App.navigate('admin/kits', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
