
define('template!wellDisplayGraphTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container popup-graphs">\n      <div class="form-group">\n          <div class="row hidden-print m-b-0">\n            <div class="row m-b-0" style="font-weight: bold;">\n                <div class="col-xs-6 p-r-0">\n                    <div class="col-xs-3">\n                        ' +
((__t = ( _.i18n('graph.analyse') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="col-xs-3" style="' +
((__t = ( displayCC?'':'display: none;' )) == null ? '' : __t) +
'">\n                        ' +
((__t = ( _.i18n('graph.cc') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="col-xs-3">\n                        ' +
((__t = ( _.i18n('graph.res') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="col-xs-3">\n                        ' +
((__t = ( _.i18n('graph.colorView') )) == null ? '' : __t) +
'\n                    </div>\n                </div>\n                <div class="col-xs-6 p-l-0">\n                    <div class="col-xs-12">\n                        ' +
((__t = ( _.i18n('graph.action') )) == null ? '' : __t) +
'\n                        <a href="javascript:window.print()" style="color: #000000;"><i class="mdi-action-print pull-right clickable p-r-20"></i></a>\n                    </div>\n                </div>\n            </div>\n            <div class="row m-b-0">\n                <div class="col-xs-6 p-r-0">\n                    <div class="col-xs-3" style="font-weight: 400;">\n                        <div class="col-xs-12 p-l-0">\n                            <span class="lbl-assay"></span>\n                        </div>\n                        <div class="col-xs-12 p-l-0">\n                            <span class="lbl-target"></span>\n                        </div>\n                    </div>\n                    <div class="col-xs-3 p-l-5" style="' +
((__t = ( displayCC?'':'display: none;' )) == null ? '' : __t) +
'">\n                        <div class="radio radio-primary col-xs-12 p-l-0 m-b-0 p-t-0" style="min-height: 2px;">\n                            <label class="p-l-27">\n                                <input type="radio" class="cc-radio legend-radio-hide" name="cc-radio" value="yes" />\n                                ' +
((__t = ( _.i18n('graph.cc.yes') )) == null ? '' : __t) +
'\n                            </label>\n                        </div>\n                        <div class="radio radio-primary col-xs-12 p-l-0 m-b-0 p-t-0" style="min-height: 2px;">\n                            <label class="p-l-27">\n                                <input type="radio" class="cc-radio legend-radio-well" name="cc-radio" value="no" />\n                                ' +
((__t = ( _.i18n('graph.cc.no') )) == null ? '' : __t) +
'\n                            </label>\n                        </div>\n                    </div>\n                    <div class="col-xs-3 p-l-5">\n                        <div class="radio radio-primary col-xs-12 p-l-0 m-b-0 p-t-0" style="min-height: 2px;">\n                            <label class="p-l-27">\n                                <input type="radio" class="res-radio res-radio-all" name="res-radio" value="all" />\n                                ' +
((__t = ( _.i18n('graph.res.all') )) == null ? '' : __t) +
'\n                            </label>\n                        </div>\n                        ';
 _.each(results, function(result) { ;
__p += '\n                        <div class="radio radio-primary col-xs-12 p-l-0 m-b-0 p-t-0" style="min-height: 2px;">\n                            <label class="p-l-27">\n                                <input type="radio" class="res-radio res-radio-' +
((__t = ( result )) == null ? '' : __t) +
'" name="res-radio" value="' +
((__t = ( result )) == null ? '' : __t) +
'" />\n                                ' +
((__t = ( result )) == null ? '' : __t) +
'\n                            </label>\n                        </div>\n                        ';
 }); ;
__p += '\n                        <div class="radio radio-primary col-xs-12 p-l-0 m-b-0 p-t-0" style="min-height: 2px;">\n                            <label class="p-l-27">\n                                <input type="radio" class="res-radio res-radio-all" name="res-radio" value="warn/err" />\n                                ' +
((__t = ( _.i18n('graph.res.warnErr') )) == null ? '' : __t) +
'\n                            </label>\n                        </div>\n                    </div>\n                    <div class="col-xs-3 p-l-5">\n                        <div class="radio radio-primary col-xs-12 p-l-0 m-b-0 p-t-0" style="min-height: 2px;">\n                            <label class="p-l-27">\n                                <input type="radio" class="cv-radio legend-radio-hide" name="cv-radio" value="yes" />\n                                ' +
((__t = ( _.i18n('graph.colorView.yes') )) == null ? '' : __t) +
'\n                            </label>\n                        </div>\n                        <div class="radio radio-primary col-xs-12 p-l-0 m-b-0 p-t-0" style="min-height: 2px;">\n                            <label class="p-l-27">\n                                <input type="radio" class="cv-radio legend-radio-well" name="cv-radio" value="no" />\n                                ' +
((__t = ( _.i18n('graph.colorView.no') )) == null ? '' : __t) +
'\n                            </label>\n                        </div>\n                    </div>\n                </div>\n                <div class="col-xs-6 p-l-0">\n                    <div class="col-xs-4">\n                        <label class="btn add-btn-text btn-sm btn-validate btn-target-view-top col-xs-12 m-b-0 m-t-0">\n                            <i class="mdi-action-done"></i>\n                            <span>' +
((__t = ( _.i18n('well.validate') )) == null ? '' : __t) +
'</span>\n                        </label>\n                    </div>\n                    <div class="col-xs-4">\n                        <label class="btn add-btn-text btn-sm btn-validate2 btn-target-view-top col-xs-12 m-b-0 m-t-0">\n                            <i class="mdi-action-done"></i>\n                            <span>' +
((__t = ( _.i18n('well.validate2') )) == null ? '' : __t) +
'</span>\n                        </label>\n                    </div>\n                    <div class="col-xs-4">\n                        <nav class="navbar navbar-default main-navbar-menu" style="background-color:#f47b00; height: 60px;">\n                            <div class="container-fluid">\n                                <!-- Collect the nav links, forms, and other content for toggling -->\n                                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">\n                                    <ul class="nav navbar-nav">\n                                        <li class="dropdown">\n                                            <a href="#" class="dropdown-toggle m-t-15" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="color: #ffffff; padding-top: 5px !important; font-weight: normal;">' +
((__t = ( _.i18n("targets.actions") )) == null ? '' : __t) +
' <span class="caret"></span></a>\n                                            <ul class="dropdown-menu navbar-menu-dropdown" style="background-color:#f47b00;">\n                                                <li class="orange">\n                                                    <a class="btn-invalidate hidden-print p-l-0" style="color:#ffffff;">' +
((__t = ( _.i18n("update.repeat") )) == null ? '' : __t) +
'</a>\n                                                </li>\n                                                <li class="orange">\n                                                    <a class="btn-repeat-dna hidden-print p-l-0" style="color:#ffffff;">' +
((__t = ( _.i18n("update.repeatExtract") )) == null ? '' : __t) +
'</a>\n                                                </li>\n                                                <li class="orange">\n                                                    <a class="btn-repeat-dna-dil12 hidden-print p-l-0" style="color:#ffffff;">' +
((__t = ( _.i18n("update.repeatExtractDil12") )) == null ? '' : __t) +
'</a>\n                                                </li>\n                                                <li class="orange">\n                                                    <a class="btn-repeat-dna-dil15 hidden-print p-l-0" style="color:#ffffff;">' +
((__t = ( _.i18n("update.repeatExtractDil15") )) == null ? '' : __t) +
'</a>\n                                                </li>\n                                                <li class="orange">\n                                                    <a class="btn-expert-required hidden-print p-l-0" style="color:#ffffff;">' +
((__t = ( _.i18n("update.validationExpert") )) == null ? '' : __t) +
'</a>\n                                                </li>\n                                                <li class="orange">\n                                                    <a class="btn-confirm-result hidden-print p-l-0" style="color:#ffffff;">' +
((__t = ( _.i18n("update.confirmResult") )) == null ? '' : __t) +
'</a>\n                                                </li>\n                                                <li class="orange">\n                                                    <a class="btn-change-to-neg hidden-print p-l-0" style="color:#ffffff;">' +
((__t = ( _.i18n("update.changeToNeg") )) == null ? '' : __t) +
'</a>\n                                                </li>\n                                            </ul>\n                                        </li>\n                                    </ul>\n                                </div>\n                            </div>\n                        </nav>\n                    </div>\n                </div>\n            </div>\n            ';
 if (!hideNextPreviousButton) { ;
__p += ' \n            <div class="col-xs-12 m-b-0">\n                <div class="col-xs-6 p-l-0">\n                    <label class="btn add-btn-text btn-sm btn-previous col-xs-12 m-b-0 m-t-0">\n                        <i class="mdi-image-navigate-before"></i>\n                        <span>' +
((__t = ( _.i18n('common.previous') )) == null ? '' : __t) +
'</span>\n                    </label>\n                </div>\n                <div class="col-xs-6 p-r-0">\n                    <label class="btn add-btn-text btn-sm btn-next col-xs-12 m-b-0 m-t-0">\n                        <i class="mdi-image-navigate-next"></i>\n                        <span>' +
((__t = ( _.i18n('common.next') )) == null ? '' : __t) +
'</span>\n                    </label>\n                </div>\n            </div>\n            ';
 } ;
__p += '\n            <div class="col-xs-6">\n                <div class="row m-b-0" style="display: none;">\n                    <div class="col-xs-2 p-t-3" style="font-weight: bold;">\n                        ' +
((__t = ( _.i18n('graph.validation') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="col-xs-2">\n                        <div class="row m-b-0">\n                            <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0" style="font-weight: normal;">' +
((__t = ( _.i18n('graph.res.all') )) == null ? '' : __t) +
'</label>\n                            <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                <label>\n                                    <input type="checkbox" id="validation-all" data-value="all" class="cbx-validation js-keep-logged-in">\n                                </label>\n                            </div>\n                        </div>\n                    </div>\n                    <div class="col-xs-2">\n                        <div class="row m-b-0">\n                            <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0" style="font-weight: normal;">' +
((__t = ( _.i18n('graph.validation.tovalidate') )) == null ? '' : __t) +
'</label>\n                            <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                <label>\n                                    <input type="checkbox" id="validation-tovalidate" data-value="1" class="cbx-validation js-keep-logged-in">\n                                </label>\n                            </div>\n                        </div>\n                    </div>\n                    <div class="col-xs-2">\n                        <div class="row m-b-0">\n                            <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0" style="font-weight: normal;">' +
((__t = ( _.i18n('graph.validation.expert') )) == null ? '' : __t) +
'</label>\n                            <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                <label>\n                                    <input type="checkbox" id="validation-expert" data-value="2" class="cbx-validation js-keep-logged-in">\n                                </label>\n                            </div>\n                        </div>\n                    </div>\n                    <div class="col-xs-2">\n                        <div class="row m-b-0">\n                            <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0" style="font-weight: normal;">' +
((__t = ( _.i18n('graph.res.validated') )) == null ? '' : __t) +
'</label>\n                            <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                <label>\n                                    <input type="checkbox" id="validation-validated" data-value="4" class="cbx-validation js-keep-logged-in">\n                                </label>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n          </div>\n          <ul class="nav nav-tabs">\n            ';
 if(displayMelt) { ;
__p += '\n            <li class="nav-tab active">\n                <a href="#meltCurves" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurve') )) == null ? '' : __t) +
'</a>\n            </li>\n              <li class="nav-tab">\n                  <a href="#meltCurves1" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurveDeriv') )) == null ? '' : __t) +
'</a>\n              </li>\n              <li class="nav-tab">\n                  <a href="#meltCurves2" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurve1') )) == null ? '' : __t) +
'</a>\n              </li>\n            ';
 } ;
__p += '\n            ';
 if(displayAmpl) { ;
__p += '\n            <li class="nav-tab ' +
((__t = ( !displayMelt?'active':'' )) == null ? '' : __t) +
'">\n                <a href="#amplCurves" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurve') )) == null ? '' : __t) +
'</a>\n            </li>\n              <li class="nav-tab">\n                  <a href="#amplCurves1" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurve1') )) == null ? '' : __t) +
'</a>\n              </li>\n              <li class="nav-tab">\n                  <a href="#amplCurves2" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurveDeriv') )) == null ? '' : __t) +
'</a>\n              </li>\n            ';
 } ;
__p += '\n          </ul>\n          <div class="tab-content ">\n            ';
 if(displayMelt) { ;
__p += '\n            <div class="tab-pane active" id="meltCurves">\n              <div id="run-graph-region"></div>\n            </div>\n            <div class="tab-pane" id="meltCurves1">\n                <div id="run-graph1-region"></div>\n            </div>\n              <div class="tab-pane" id="meltCurves2">\n                  <div id="run-graph2-region"></div>\n              </div>\n            ';
 } ;
__p += '\n            ';
 if(displayAmpl) { ;
__p += '\n            <div class="tab-pane ' +
((__t = ( !displayMelt?'active':'' )) == null ? '' : __t) +
'" id="amplCurves">\n              <div id="run-ampl-graph-region"></div>\n            </div>\n            <div class="tab-pane" id="amplCurves1">\n                <div id="run-ampl-graph1-region"></div>\n            </div>\n              <div class="tab-pane" id="amplCurves2">\n                  <div id="run-ampl-graph2-region"></div>\n              </div>\n            ';
 } ;
__p += '\n          </div>\n      </div>\n        ';
 if (!hideOkButton) { ;
__p += '\n        <div class="row hidden-print">\n            <div class="pull-right p-r-15">\n\n             <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.ok') )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n        ';
 } ;
__p += '\n    </div>\n  </div>\n</div>';

}
return __p
};});

