define('wellDetailView',[
    'module',
    'app',
    'marionette',
    'template!wellDetailTpl',
    'underscore',
    'dialogFormView',
    'runDetailsGraphView',
    'headerWellView'
], function(
    module,
    App,
    Marionette,
    wellDetailTpl,
    _,
    DialogFormView,
    RunGraphView,
    HeaderWellView
) {
    'use strict';

    var DetailsTabView = DialogFormView.extend({
        template: wellDetailTpl,

        ui: {
            tabs1: '.tabs',
            firstTabMelt: '.first-tab-melt',
            firstTabAmpl: '.first-tab-ampl',
            tabMelt: '.tab-melt',
            tabAmpl: '.tab-ampl',
            firstTabMeltContent: '.first-tab-melt-content',
            firstTabAmplContent: '.first-tab-ampl-content',
            tabMeltContent: '.tab-melt-content',
            tabAmplContent: '.tab-ampl-content',
            ccRadio: '.cc-radio'
        },

        events: {
            'change @ui.ccRadio': 'onCCRadioChange'
        },

        regions: {
            runGraph: '#run-graph-region',
            runAmplGraph: '#run-ampl-graph-region',
            runGraph1: '#run-graph1-region',
            runAmplGraph1: '#run-ampl-graph1-region',
            runGraph2: '#run-graph2-region',
            runAmplGraph2: '#run-ampl-graph2-region',
            headerWell: '#header-region'
        },

        initialize: function() {
            this.withoutCC = false;
            App.on('well:refresh:well', this.onWellChange, this);
        },

        onWellChange: function(well, wells) {
            if (!wells) {
                wells = [];
            }
            if (well) {
                wells.push(well.toJSON());
            }
            if (wells) {
                _.each(wells, _.bind(function(w) {
                    if (w && w.pos === this.model.get('pos')) {
                        this.model.set(w);
                        this.refreshView();
                    }
                }, this));
            }
        },

        onDestroy: function() {
          App.off('well:refresh:well', this.onWellChange, this);
        },

        onShow: function() {
            $.material.init();
            $(this.ui.ccRadio[0]).prop('checked', true);

            this.refreshView();
        },

        refreshView: function() {
            if (this.headerWellView) {
                this.headerWellView.destroy();
            }
            this.headerWellView = new HeaderWellView();
            this.headerWellView.model = this.model.clone();
            this.headerWellView.modelRun = this.modelRun.clone();
            this.headerWellView.channelToDisplay = this.channelToDisplay;
            var region = this.getRegion('headerWell');
            if (region){
                region.show(this.headerWellView);
            }

            this.refreshGraphs();
        },

        onCCRadioChange: function() {
            if(this.ui.ccRadio) {
                _.each(this.ui.ccRadio, _.bind(function(radio) {
                    if (radio.checked) {
                        this.withoutCC = radio.value==='no'?true:false;
                    }
                }, this));
                this.refreshGraphs();
            }
        },

        serializeData: function() {
            var templateData = {};
            templateData.displayCC = this.modelRun.get('refMbAModel').code === 'LC480';
            return templateData;
        },

        activeMelt: function() {
            this.ui.tabMelt.removeClass('active');
            this.ui.tabMeltContent.removeClass('active');
            this.ui.firstTabMelt.addClass('active');
            this.ui.firstTabMeltContent.addClass('active');
        },

        activeAmpl: function() {
            this.ui.tabAmpl.removeClass('active');
            this.ui.tabAmplContent.removeClass('active');
            this.ui.firstTabAmpl.addClass('active');
            this.ui.firstTabAmplContent.addClass('active');
        },

        showMelt: function() {
            this.ui.tabMelt.show();
        },

        showAmpl: function() {
            this.ui.tabAmpl.show();
        },

        refreshGraphs: function() {
            var graphToDisplay = this.modelRun.getGraphToDisplay(this.model);
            var region;
            if (graphToDisplay.indexOf('M') !== -1) {
                this.showMelt();
                this.activeMelt();
                if (this.runGraphView) {
                    this.runGraphView.destroy();
                }
                this.runGraphView = new RunGraphView({type: 'line'});
                this.runGraphView.model = this.model;
                this.runGraphView.modelRun = this.modelRun;
                this.runGraphView.slopeEnable = true;
                this.runGraphView.brutEnable = false;
                this.runGraphView.channelToDisplay = this.channelToDisplay;
                this.runGraphView.yLabel = _.i18n('well.graph.legend.temp');
                this.runGraphView.xLabel = _.i18n('well.graph.legend.1stDerivateFluo');
                this.runGraphView.withoutCC = this.withoutCC;
                region = this.getRegion('runGraph');
                if (region) {
                    region.show(this.runGraphView);
                }

                if (this.runGraphView1) {
                    this.runGraphView1.destroy();
                }
                this.runGraphView1 = new RunGraphView({type: 'line'});
                this.runGraphView1.model = this.model;
                this.runGraphView1.modelRun = this.modelRun;
                this.runGraphView1.slopeEnable = true;
                this.runGraphView1.brutEnable = false;
                this.runGraphView1.deriv2Enable = true;
                this.runGraphView1.channelToDisplay = this.channelToDisplay;
                this.runGraphView1.yLabel = _.i18n('well.graph.legend.temp');
                this.runGraphView1.xLabel = _.i18n('well.graph.legend.2ndDerivateFluo');
                this.runGraphView1.withoutCC = this.withoutCC;
                region = this.getRegion('runGraph1');
                if (region) {
                    region.show(this.runGraphView1);
                }

                if (this.runGraphView2) {
                    this.runGraphView2.destroy();
                }
                this.runGraphView2 = new RunGraphView({type: 'line'});
                this.runGraphView2.model = this.model;
                this.runGraphView2.modelRun = this.modelRun;
                this.runGraphView2.slopeEnable = false;
                this.runGraphView2.brutEnable = true;
                this.runGraphView2.channelToDisplay = this.channelToDisplay;
                this.runGraphView2.yLabel = _.i18n('well.graph.legend.temp');
                this.runGraphView2.xLabel = _.i18n('well.graph.legend.fluo');
                this.runGraphView2.withoutCC = this.withoutCC;
                region = this.getRegion('runGraph2');
                if (region) {
                    region.show(this.runGraphView2);
                }
            }

            if (graphToDisplay.indexOf('A') !== -1) {
                this.showAmpl();
                if (graphToDisplay.indexOf('M') === -1) {
                    this.activeAmpl();
                }

                if (this.runMeltGraphView) {
                    this.runMeltGraphView.destroy();
                }
                this.runMeltGraphView = new RunGraphView({type: 'line'});
                this.runMeltGraphView.model = this.model;
                this.runMeltGraphView.modelRun = this.modelRun;
                this.runMeltGraphView.slopeEnable = true;
                this.runMeltGraphView.brutEnable = false;
                this.runMeltGraphView.amplGraph = true;
                this.runMeltGraphView.channelToDisplay = this.channelToDisplay;
                this.runMeltGraphView.yLabel = _.i18n('well.graph.legend.cycle');
                this.runMeltGraphView.xLabel = _.i18n('well.graph.legend.1stDerivateFluo');
                this.runMeltGraphView.withoutCC = this.withoutCC;
                region = this.getRegion('runAmplGraph');
                if (region) {
                    region.show(this.runMeltGraphView);
                }

                if (this.runMeltGraph1View) {
                    this.runMeltGraph1View.destroy();
                }
                this.runMeltGraph1View = new RunGraphView({type: 'line'});
                this.runMeltGraph1View.model = this.model;
                this.runMeltGraph1View.modelRun = this.modelRun;
                this.runMeltGraph1View.slopeEnable = true;
                this.runMeltGraph1View.brutEnable = false;
                this.runMeltGraph1View.amplGraph = true;
                this.runMeltGraph1View.deriv2Enable = true;
                this.runMeltGraph1View.channelToDisplay = this.channelToDisplay;
                this.runMeltGraph1View.yLabel = _.i18n('well.graph.legend.cycle');
                this.runMeltGraph1View.xLabel = _.i18n('well.graph.legend.2ndDerivateFluo');
                this.runMeltGraph1View.withoutCC = this.withoutCC;
                region = this.getRegion('runAmplGraph1');
                if (region) {
                    region.show(this.runMeltGraph1View);
                }

                if (this.runMeltGraph2View) {
                    this.runMeltGraph2View.destroy();
                }
                this.runMeltGraph2View = new RunGraphView({type: 'line'});
                this.runMeltGraph2View.model = this.model;
                this.runMeltGraph2View.modelRun = this.modelRun;
                this.runMeltGraph2View.slopeEnable = false;
                this.runMeltGraph2View.brutEnable = true;
                this.runMeltGraph2View.amplGraph = true;
                this.runMeltGraph2View.channelToDisplay = this.channelToDisplay;
                this.runMeltGraph2View.yLabel = _.i18n('well.graph.legend.cycle');
                this.runMeltGraph2View.xLabel = _.i18n('well.graph.legend.fluo');
                this.runMeltGraph2View.withoutCC = this.withoutCC;
                if (this.runAt) {
                    this.runMeltGraph2View.baseline = this.runAt.baseline;
                    this.runMeltGraph2View.threshold = this.runAt.threshold;
                }
                region = this.getRegion('runAmplGraph2');
                if (region) {
                    region.show(this.runMeltGraph2View);
                }
            }
        }
    });

    module.exports = DetailsTabView;
});
