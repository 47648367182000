
define('template!sampleIdsQcTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page col-xs-12">\n  \t<h1 class="title-list"></h1>\n    <div class="form-group">\n    </div>\n    <div class="sub-caccounts-table col-md-6">\n      <div class="sample-ids-view">\n\n      </div>\n    </div>\n    <div class="row col-md-6 col-sm-12 right-view">\n    </div>\n  </div>\n</div>';

}
return __p
};});

