
define('template!kitCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      ';
 if (newItem) { ;
__p += '\n        <div class="radio pull-left radio-primary col-xs-12">\n          <label>\n            <input type="radio" class="create-kit-radio create-kit-radio-manuf" name="create-kit-radio" value="copy" checked>\n            ' +
((__t = ( _.i18n('kit.fromManufacturer') )) == null ? '' : __t) +
'\n          </label>\n        </div>\n        <div class="createKitFromManufacturer">\n          <div class="form-group">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kit.manufacturer') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n              <div class="js-select-account-region"></div>\n            </div>\n          </div>\n        </div>\n        <div class="createKitFromManufacturer">\n          <div class="form-group">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kit.manufacturerKit') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n              <div class="js-select-kit-region"></div>\n            </div>\n          </div>\n        </div>\n        <div class="radio pull-left radio-primary col-xs-12">\n          <label>\n            <input type="radio" class="create-kit-radio share-kit-radio-manuf" name="create-kit-radio" value="share">\n            ' +
((__t = ( _.i18n('kit.shareFromManufacturer') )) == null ? '' : __t) +
'\n          </label>\n        </div>\n        <div class="shareKitFromManufacturer hidden">\n          <div class="form-group">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kit.manufacturer') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n              <div class="js-select-account1-region"></div>\n            </div>\n          </div>\n        </div>\n        <div class="shareKitFromManufacturer hidden">\n          <div class="form-group">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kit.manufacturerKit') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n              <div class="js-select-kit1-region"></div>\n            </div>\n          </div>\n        </div>\n        <div class="radio pull-left radio-primary col-xs-12" >\n          <label>\n            <input type="radio" class="create-kit-radio"  name="create-kit-radio" value="notcopy">\n            ' +
((__t = ( _.i18n('kit.fromEmpty') )) == null ? '' : __t) +
'\n          </label>\n        </div>\n      ';
 } ;
__p += '\n      <div class="createKitFromEmpty ' +
((__t = ( newItem?'hidden':'' )) == null ? '' : __t) +
'">\n        <div class="form-group" data-field-name="code">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kit.productCode') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <input type="text" class="form-control floating-label js-info-input js-code"\n                   data-field-name="code" placeholder="' +
((__t = ( _.i18n('kit.productCode') )) == null ? '' : __t) +
'"\n                   value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('kit.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                   data-placement="top">\n          </div>\n        </div>\n        <div class="form-group" data-field-name="ordRefName">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kit.ordRefName') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="ordRefName"\n                   placeholder="' +
((__t = ( _.i18n('kit.ordRefName') )) == null ? '' : __t) +
'" value="' +
((__t = ( ordRefName )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <div class="form-group">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kit.refKitProt') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <div class="js-select-kitprot-region"></div>\n          </div>\n        </div>\n        <div class="form-group" data-field-name="nbTests">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kit.nbTests') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <input type="number" class="form-control floating-label js-info-input" data-field-name="nbTests" placeholder="' +
((__t = ( _.i18n('kit.nbTests') )) == null ? '' : __t) +
'" value="' +
((__t = ( nbTests )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <div class="form-group" data-field-name="description">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="description" placeholder="' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'">' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

