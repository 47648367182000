define('headerWellView',[
    'module',
    'marionette',
    'settings',
    'template!headerWellTpl',
    'targetCreateEditView',
    'runDetailsGraphView',
    'underscore',
    'colorUtils',
    'wellDisplayGraphView',
    'app',
    'validationUtils',
    'settings',
    'envHandler',
    'backbone',
    'entities/wells'
], function(
    module,
    Marionette,
    Settings,
    headerWellTpl,
    CreateEditLayoutView,
    RunGraphView,
    _,
    colorUtils,
    DisplayGraphLayoutView,
    App,
    ValidationUtils
) {
    'use strict';

    var HeaderWellView = Marionette.LayoutView.extend({
        template: headerWellTpl,

        ui: {
            btnEditWell: '.btn-edit-well'
        },

        events: {
            'click @ui.btnEditWell': 'onEditWell',
            'click .btn-info-well': 'onInfoWell',
            'click .btn-graph-well': 'onBtnGraphClick',
            'click .btn-validate-result': 'onValidateTargetClick',
            'click .btn-negative-result': 'onBtnNegativClick',
            'click .btn-expert-result': 'onBtnExpertClick',
            'click .btn-repeat-pcr-result': 'onBtnRepeatPcrClick',
            'click .btn-repeat-dna-result': 'onBtnRepeatDnaClick'
        },

        serializeData: function() {
            var templateData = this.model.toJSON();
            templateData.results = this.modelRun.getResultForWell(this.model);
            _.each(templateData.results, _.bind(function(r) {
                r.colorGraph = colorUtils.rgbObjectToHexa(RunGraphView.colorTarget(r.index, this.modelRun));
                r.userCode = '';
                var user = r.user;
                if (user) {
                    if (user.nickName && user.nickName !== 'null') {
                        r.userCode = user.nickName;
                    } else {
                        r.userCode = user.logonId.substr(0, 600);
                    }
                }
                var user2 = r.user2;
                if (user2) {
                    if (r.userCode) {
                        r.userCode += ', ';
                    }
                    if (user.nickName && user2.nickName !== 'null') {
                        r.userCode += user2.nickName;
                    } else {
                        r.userCode += user2.logonId.substr(0, 600);
                    }
                }
            }, this));
            templateData.channelToDisplay = this.channelToDisplay;
            templateData = _.extend(templateData, this.model.getErrorObject());
            return templateData;
        },

        onBtnRepeatDnaClick: function(event) {
            var validationUtils = new ValidationUtils({
                model: this.model,
                modelRun: this.modelRun,
                target: $(event.target).data('target')
            });
            validationUtils.repeatDna();
        },

        onBtnRepeatPcrClick: function(event) {
            var validationUtils = new ValidationUtils({
                model: this.model,
                modelRun: this.modelRun,
                target: $(event.target).data('target')
            });
            validationUtils.repeatPcr();
        }, 

        onBtnExpertClick: function(event) {
            var validationUtils = new ValidationUtils({
                model: this.model,
                modelRun: this.modelRun,
                target: $(event.target).data('target')
            });
            validationUtils.expertValidation();
        },

        onBtnNegativClick: function (event) {
            var validationUtils = new ValidationUtils({
                model: this.model,
                modelRun: this.modelRun,
                target: $(event.target).data('target')
            });
            validationUtils.changeToNeg();
        },

        onValidateTargetClick: function (event) {
            var validationUtils = new ValidationUtils({
                model: this.model,
                modelRun: this.modelRun,
                target: $(event.target).data('target')
            });
            validationUtils.validate();
        },

        onEditWell: function(event, readOnly) {
            this.createEditView = new CreateEditLayoutView({
                wellModel: this.model,
                runModel: this.modelRun,
                readOnly: readOnly,
                target: $(event.target).data('target'),
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.target.edit'),
                className: 'popupEditResult'
            });
        },

        onInfoWell: function(event) {
            this.onEditWell(event, true);
        },

        onShow: function() {
            this.$el.find('[data-toggle="tooltip"]').tooltip();
        },

        onBtnGraphClick: function(event) {
            var Col = App.request('well:get-collection', this.modelRun.get('secId'), null, null, this.model.get('refAssay').secId, null);
            Col.fetch().then(_.bind(function() {
                this.data = this.modelRun.getAssaysTargets();
                this.displayGraphView = new DisplayGraphLayoutView({
                    models: Col.models,
                    model: this.modelRun.clone(),
                    newItem: false,
                    highlight: this.model
                });
                this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-validate:click', 
                    _.bind(this.onWellsValidateClick, this));
                this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-validate2:click', 
                    _.bind(this.onWellsValidate2Click, this));
                this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-invalidate:click', 
                    _.bind(this.onWellsInValidateClick, this));
                this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-repeat-dna:click', 
                    _.bind(this.onWellsRepeatDnaClick, this));
                this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-expert:click', 
                    _.bind(this.onWellsExpertClick, this));
                this.displayGraphView.allModel = Col.models;
                this.channelToDisplay = this.displayGraphView.channelToDisplay = $(event.target).data('target');
                this.displayGraphView.graphToDisplay = this.modelRun.getGraphToDisplay(this.modelRun);
                //this.displayGraphView.runAt = this.runAt;
                this.displayGraphView.hideOkButton = true;
                this.displayGraphView.hideNextPreviousButton = true;
                this.displayGraphView.selectionMode = false;                
    
                this.displayGraphView.show({
                    title: '',
                    className: 'popupTarget'
                });
            }, this));
        },

        onWellsValidateClick: function() {
            this.changeValidationStatus('well:target:validate');
        },
    
        onWellsValidate2Click: function() {
          this.changeValidationStatus('well:target:validate2');
        },
    
        onWellsInValidateClick: function() {
            this.changeValidationStatus('well:target:invalidate');
        },

        onWellsRepeatDnaClick: function() {
            this.changeValidationStatus('well:target:invalidateallextract');
        },

        onWellsExpertClick: function() {
            this.changeValidationStatus('well:target:validate:expert-required');
        },

        changeValidationStatus: function(status) {
            var target = this.channelToDisplay;
            var lstString = {
                lst: []
            };
            _.each(this.displayGraphView.currentModels, function(well) {
                lstString.lst.push(well.get('secId'));
            });
            App.request(status, this.modelRun.get('secId'),
                    this.model.get('refAssay').secId, target, lstString).then(_.bind(function(result) {
                if(result.run) {
                    App.trigger('well:refresh:well', null, result.run.wells);
                } else {
                    App.trigger('well:refresh:well', null, result.wells);
                }
                this.emptyView.destroy();
            },this)).fail(_.bind(function (err) {
                if (err && err.status === 405) {
                    alert(_.i18n('error.noPermission'));
                    this.emptyView.destroy();
                }
            }, this));
        }
    });

    module.exports = HeaderWellView;
});
