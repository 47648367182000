
define('template!wellDetailTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div id="right-graph" class="detailed-info-form-container right-area col-sm-12 well well-left-border" style="float: initial; min-height: 720px;">\n    <div id="header-region"></div>\n    <div class="row m-b-0" style="' +
((__t = ( displayCC?'':'display: none;' )) == null ? '' : __t) +
'">\n        <div class="col-xs-2 p-t-0" style="font-weight: bold;">\n            ' +
((__t = ( _.i18n('graph.cc') )) == null ? '' : __t) +
'\n        </div>\n        <div class="radio radio-primary col-xs-2 p-l-0 m-t-0">\n            <label class="p-l-27">\n                <input type="radio" class="cc-radio legend-radio-hide" name="cc-radio" value="yes" />\n                ' +
((__t = ( _.i18n('graph.cc.yes') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="radio radio-primary col-xs-2 p-l-0 m-t-0">\n            <label class="p-l-27">\n                <input type="radio" class="cc-radio legend-radio-well" name="cc-radio" value="no" />\n                ' +
((__t = ( _.i18n('graph.cc.no') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n    </div>\n    <ul class="nav nav-tabs row">\n        <li class="nav-tab first-tab-melt tab-melt" style="display: none;">\n            <a href="#meltCurve" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurve') )) == null ? '' : __t) +
'</a>\n        </li>\n        <li class="nav-tab tab-melt" style="display: none;"">\n        <a href="#meltCurve1" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurveDeriv') )) == null ? '' : __t) +
'</a>\n        </li>\n        <li class="nav-tab tab-melt" style="display: none;">\n            <a href="#meltCurve2" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurve1') )) == null ? '' : __t) +
'</a>\n        </li>\n        <li class="nav-tab first-tab-ampl tab-ampl" style="display: none;">\n            <a href="#amplCurve2" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurve') )) == null ? '' : __t) +
'</a>\n        </li>\n        <li class="nav-tab tab-ampl" style="display: none;">\n            <a href="#amplCurve" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurve1') )) == null ? '' : __t) +
'</a>\n        </li>\n        <li class="nav-tab tab-ampl" style="display: none;">\n            <a href="#amplCurve1" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurveDeriv') )) == null ? '' : __t) +
'</a>\n        </li>\n    </ul>\n    <div class="tab-content ">\n        <div class="tab-pane first-tab-melt-content tab-melt-content" id="meltCurve">\n            <div id="run-graph-region"></div>\n        </div>\n        <div class="tab-pane tab-melt-content" id="meltCurve1">\n            <div id="run-graph1-region"></div>\n        </div>\n        <div class="tab-pane tab-melt-content" id="meltCurve2">\n            <div id="run-graph2-region"></div>\n        </div>\n        <div class="tab-pane first-tab-ampl-content tab-ampl-content" id="amplCurve2">\n            <div id="run-ampl-graph2-region"></div>\n        </div>\n        <div class="tab-pane tab-ampl-content" id="amplCurve">\n            <div id="run-ampl-graph-region"></div>\n        </div>\n        <div class="tab-pane tab-ampl-content" id="amplCurve1">\n            <div id="run-ampl-graph1-region"></div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

