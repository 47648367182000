define('runDetailsTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'runDetailsTabView',
    'emptyView',
    'wellTableView',
    'wellEditRefAssayView',
    'wellEditRefKitLView',
    'wellEditSmpIdView',
    'wellDisplayGraphView',
    'entities/wells'
], function(
    module,
    App,
    _,
    Backbone,
    DetailsTabView,
    EmptyView,
    WellTableView,
    CreateEditLayoutView,
    CreateEditRefKitLLayoutView,
    CreateEditSmpIdLayoutView,
    DisplayGraphLayoutView
) {
    'use strict';

    var DetailsTabController = {
        showDetailsTab: function(region, runModel, row, column, filters, selectedValues) {
            var that = this;
            this.model = runModel;
            this.view = new DetailsTabView({
                model: runModel
            });

            this.row = row;
            this.column = column;
            this.filters = filters;
            this.selectedValues = selectedValues;
            if (this.model.get('settings')['PLATE-FILL-DIRECTION'] === 'C') {
                this.sortOn = 'column';
            } else {
                this.sortOn = 'row';
            }
            if (this.filters && this.filters.targetSecId) {
                this.target = this.model.getTarget(this.filters.targetSecId);
                this.channelToDisplay = this.target.channelNb;
                this.runAt = this.model.getRunAtFromTarget(this.filters.targetSecId);
            } else {
                this.target = null;
                this.channelToDisplay = null;
                this.runAt = null;
            }

            region.show(this.view);
            this.view.getRegion('wellTable').show(new EmptyView());
            that.onShowWellTable();
        },

        onShowWellTable: function() {
            this.wellView = new WellTableView({
                tabName: this.tabName
            });
            this.wellView.model = this.model;
            this.wellView.channelToDisplay = this.channelToDisplay;
            this.wellView.sortOn = this.sortOn;
            this.wellView.multiRes = this.multiRes;

            this.wellView.listenTo(this.wellView, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.wellView.listenTo(this.wellView, 'well-table:loaded', _.bind(this.onLoadSubViews, this));
            this.wellView.listenTo(this.wellView, 'well-table:edit-ref-assay', _.bind(this.onEditWellsRefAssay, this));
            this.wellView.listenTo(this.wellView, 'well-table:edit-ref-kitl', _.bind(this.onEditWellsRefKitL, this));
            this.wellView.listenTo(this.wellView, 'well-table:edit-smpid', _.bind(this.onEditWellsSmpId, this));
            this.wellView.listenTo(this.wellView, 'well-table:display-graph', _.bind(this.onDisplayGraph, this));
            this.wellView.listenTo(this.wellView, 'well-table:sort-row', _.bind(this.onSortWellRow, this));
            this.wellView.listenTo(this.wellView, 'well-table:sort-column', _.bind(this.onSortWellColumn, this));
            this.wellView.listenTo(this.wellView, 'well-table:search-multi-res', _.bind(this.onSearchMultiRes, this));
            this.wellView.listenTo(App, 'well:header:rerender', _.bind(this.onWellChange, this));
            this.wellView.listenTo(App, 'well:mass:edit', _.bind(this.onWellsChange, this));
            this.view.getRegion('wellTable').show(this.wellView);
        },

        onSearchMultiRes: function(multiRes) {
            this.multiRes = multiRes;
            this.onShowWellTable();
        },

        onGridLoaded:function () {
            var smpid = this.filters?this.filters.smpid:null,
                assaySecId = this.filters?this.filters.assaySecId:null,
                mbAnaSecId = this.filters?this.filters.mbAnaSecId:null,
                result = this.filters?this.filters.result:null,
                errorInd = this.filters?this.filters.errorInd:null,
                res1CodeErr = this.filters?this.filters.res1CodeErr:null,
                res2CodeErr = this.filters?this.filters.res2CodeErr:null,
                res3CodeErr = this.filters?this.filters.res3CodeErr:null,
                res4CodeErr = this.filters?this.filters.res4CodeErr:null,
                res5CodeErr = this.filters?this.filters.res5CodeErr:null,
                res6CodeErr = this.filters?this.filters.res6CodeErr:null,
                smptype = this.filters?this.filters.smptype:null,
                multiRes = this.multiRes;


            var data = {
                url: App.request('well:get-collection-url', this.model.get('secId'), this.row,
                    this.column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype, res1CodeErr,
                    res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr, res6CodeErr, this.selectedValues, multiRes),
                rowCountUrl: App.request('well:get-count-url', this.model.get('secId'), this.row,
                    this.column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype, res1CodeErr,
                    res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr, res6CodeErr, this.selectedValues, multiRes)
            };
            this.wellView.displayGrid(data);
        },

        onSortWellRow: function() {
          this.sortOn = 'row';
          this.onShowWellTable();
        },

        onSortWellColumn: function() {
          this.sortOn = 'column';
          this.onShowWellTable();
        },

        onDisplayGraph: function(items) {
            var models = this.getModels(items);

            this.displayGraphView = new DisplayGraphLayoutView({
                models: models,
                model: this.model.clone(),
                newItem: false
            });
            this.displayGraphView.channelToDisplay = this.channelToDisplay;
            this.displayGraphView.graphToDisplay = this.model.getGraphToDisplay(this.view.model);
            this.displayGraphView.runAt = this.runAt;

            this.displayGraphView.show({
                title: _.i18n('well.graph') +
                    (this.target?(' - ' + this.target.refAssay.code + ' - ' + this.target.refMbAna.code):'')
            });
        },

        onEditWellsSmpId:function(items) {
            var models = this.getModels(items);

            this.createEditSmpIdView = new CreateEditSmpIdLayoutView({
                models: models,
                newItem: false
            });
            this.createEditSmpIdView.show({
                title: _.i18n('well.edit')
            });
        },

        onEditWellsRefAssay: function(items) {
            var models = this.getModels(items);

            this.createEditView = new CreateEditLayoutView({
                models: models,
                runModel: this.model,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.edit')
            });
        },

        onEditWellsRefKitL: function(items) {
            var models = this.getModels(items);

            this.createEditView = new CreateEditRefKitLLayoutView({
                models: models,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.edit')
            });
        },

        getModels: function(items) {
            var models = [];
            _.each(items, _.bind(function (item) {
                var well = _.findWhere(this.model.get('wells'), {secId: item.secId});
                models.push(App.request('well:model', well));
            }, this));
            return models;
        },

        onWellsChange: function (wells) {
            _.each(wells, _.bind(function(well) {
                var wellJson = well.toJSON();
                for (var i = 0 ; i < this.model.get('wells').length ; i++) {
                    if (this.model.get('wells')[i].secId === wellJson.secId) {
                        this.model.get('wells')[i]= wellJson;
                    }
                }
            }, this));
            this.onShowWellTable();
        },

        onWellChange: function (wellJson) {
            for (var i = 0 ; i < this.model.get('wells').length ; i++) {
                if (this.model.get('wells')[i].secId === wellJson.secId) {
                    this.model.get('wells')[i]= wellJson;
                }
            }
            this.onShowWellTable();
        },

        onLoadSubViews: function(wellSecId) {
            var well = _.findWhere(this.model.get('wells'), {secId: wellSecId});
            this.onCreateRunGraphView(well);
        },

        onCreateRunGraphView: function(well) {
            this.view.model = App.request('well:model', well);
            this.view.modelRun = this.model;

            this.view.initViews();
        }
    };

    module.exports = DetailsTabController;
});
