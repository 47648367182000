
define('template!chartViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12" style="height:500px;">\n    <canvas class="chart-canvas"></canvas>\n</div>\n';

}
return __p
};});

