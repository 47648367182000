define('targetCreateEditView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!targetCreateEditTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'settings',
  'entities/wells'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView,
  Settings
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      updateRadio: '.update-radio',
      updateForm: '.update-form',
      inputComment: '.js-comment-input',
      inputNum1: '.js-num1-input',
      inputNum2: '.js-num2-input',
      inputNum3: '.js-num3-input',
      inputCodeErr: '.js-codeerr-input',
      inputTags: '.js-tags-input',
      inputValSt: '.js-valst',
      resultIcon: '.well-circle-icon',
      wellResTxt: '.well-res-txt',
      repeatSt: '.well-repeat-st'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onAllInputChange',
      'change @ui.updateRadio': 'onUpdateRadioChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      mbAnaResSelect: '.js-select-mbanares-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [

    ],

    onAllInputChange: function(event) {
      this.onInputChange(event);
    },

    preselectKeepResult: function() {
        if(this.ui.updateRadio) {
            _.each(this.ui.updateRadio, _.bind(function(radio) {
                if (radio.value === 'keep-result') {
                    radio.checked = true;
                    this.selectedRadio = radio.value;
                } else {
                    radio.checked = false;
                }
            }, this));
            var autoComp = this.$el.find('.autocomplete');
            this.ui.input.prop('disabled', true);
            autoComp.prop('disabled', true);
            this.ui.inputComment.prop('disabled', false);
            this.ui.inputTags.prop('disabled', false);
        }
    },

    resetValues: function() {
        this.originalModel._events = this.model._events;
        this.model = this.originalModel.clone();
        this.model._events = this.originalModel._events;
        var autoComp = this.$el.find('.autocomplete');
        autoComp.val(this.model.get('res' + this.options.target + 'Code'));
        this.ui.inputNum1.val(this.model.get('res' + this.options.target + 'Num1'));
        this.ui.inputNum2.val(this.model.get('res' + this.options.target + 'Num2'));
        this.ui.inputNum3.val(this.model.get('res' + this.options.target + 'Num3'));
        this.ui.inputCodeErr.val(this.model.get('res' + this.options.target + 'CodeErr'));
        this.ui.inputValSt.html(this.model.get('val' + this.options.target + 'St'));
        this.ui.inputComment.val(this.model.get('res' + this.options.target + 'Comment', ''));
        this.ui.repeatSt.html(this.model.get('repeat' + this.options.target + 'St', ''));
        this.refreshResultIconAndResText();
    },

    onUpdateRadioChange: function() {
      if(this.ui.updateRadio) {
        _.each(this.ui.updateRadio, _.bind(function(radio) {
          if (radio.checked) {
            this.selectedRadio = radio.value;
            var autoComp = this.$el.find('.autocomplete');
            switch(radio.value) {
                case 'confirm-result':
                    this.resetValues();
                    this.ui.input.prop('disabled', true);
                    autoComp.prop('disabled', true);
                    this.ui.inputComment.prop('disabled', false);
                    this.ui.inputTags.prop('disabled', false);
                    if (this.model.get('val' + this.options.target + 'St') === 3.1) {
                        this.model.set('val' + this.options.target + 'St', 4.1);
                    } else {
                        this.model.set('val' + this.options.target + 'St', 3.2);
                    }
                    this.ui.inputValSt.html(this.model.get('val' + this.options.target + 'St'));
                    this.ui.inputCodeErr.val('');
                    this.ui.inputComment.val('');
                    this.model.set('res' + this.options.target + 'Comment', '');
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    break;
                case 'change-to-neg':
                    this.ui.input.prop('disabled', true);
                    autoComp.prop('disabled', true);
                    this.ui.inputComment.prop('disabled', false);
                    this.ui.inputTags.prop('disabled', false);
                    autoComp.val('N');
                    this.ui.inputNum1.val('');
                    this.ui.inputNum2.val('');
                    this.ui.inputNum3.val('');
                    this.ui.inputCodeErr.val('');
                    this.ui.inputComment.val('');

                    this.model.set('res' + this.options.target + 'Comment', '');
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    this.model.set('res' + this.options.target + 'Num1', '');
                    this.model.set('res' + this.options.target + 'Num2', '');
                    this.model.set('res' + this.options.target + 'Num3', '');
                    this.model.set('res' + this.options.target + 'Code', 'N');
                    if (this.model.get('val' + this.options.target + 'St') === 3.1) {
                        this.model.set('val' + this.options.target + 'St', 4.1);
                    } else {
                        this.model.set('val' + this.options.target + 'St', 3.2);
                    }
                    this.ui.inputValSt.html(this.model.get('val' + this.options.target + 'St'));
                    this.refreshResultIconAndResText();
                    break;
                case 'repeat':
                    this.resetValues();
                    this.ui.input.prop('disabled', true);
                    autoComp.prop('disabled', true);
                    this.ui.inputComment.prop('disabled', false);
                    this.ui.inputTags.prop('disabled', false);
                    if (this.model.get('val' + this.options.target + 'St') === 3.1) {
                        this.model.set('val' + this.options.target + 'St', 4.1);
                    } else {
                        this.model.set('val' + this.options.target + 'St', 3.2);
                    }
                    this.ui.inputValSt.html(this.model.get('val' + this.options.target + 'St'));
                    this.ui.inputCodeErr.val('');
                    this.ui.inputComment.val('');
                    this.ui.repeatSt.html('P');
                    this.model.set('res' + this.options.target + 'Comment', '');
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    break;
                case 'repeat-all':
                    this.resetValues();
                    this.ui.input.prop('disabled', true);
                    autoComp.prop('disabled', true);
                    this.ui.inputComment.prop('disabled', false);
                    this.ui.inputTags.prop('disabled', false);
                    if (this.model.get('val' + this.options.target + 'St') === 3.1) {
                        this.model.set('val' + this.options.target + 'St', 4.1);
                    } else {
                        this.model.set('val' + this.options.target + 'St', 3.2);
                    }
                    this.ui.inputValSt.html(this.model.get('val' + this.options.target + 'St'));
                    this.ui.inputCodeErr.val('');
                    this.ui.inputComment.val('');
                    this.ui.repeatSt.html('P');
                    this.model.set('res' + this.options.target + 'Comment', '');
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    break;
                case 'repeat-extract-dil12':
                    this.resetValues();
                    this.ui.input.prop('disabled', true);
                    autoComp.prop('disabled', true);
                    this.ui.inputComment.prop('disabled', false);
                    this.ui.inputTags.prop('disabled', false);
                    if (this.model.get('val' + this.options.target + 'St') === 3.1) {
                        this.model.set('val' + this.options.target + 'St', 4.1);
                    } else {
                        this.model.set('val' + this.options.target + 'St', 3.2);
                    }
                    this.ui.inputValSt.html(this.model.get('val' + this.options.target + 'St'));
                    this.ui.inputCodeErr.val('');
                    this.ui.inputComment.val('Dilution 1:2');
                    this.ui.repeatSt.html('D');
                    this.model.set('res' + this.options.target + 'Comment', 'Dilution 1:2');
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    break;
                case 'repeat-extract-dil15':
                    this.resetValues();
                    this.ui.input.prop('disabled', true);
                    autoComp.prop('disabled', true);
                    this.ui.inputComment.prop('disabled', false);
                    this.ui.inputTags.prop('disabled', false);
                    if (this.model.get('val' + this.options.target + 'St') === 3.1) {
                        this.model.set('val' + this.options.target + 'St', 4.1);
                    } else {
                        this.model.set('val' + this.options.target + 'St', 3.2);
                    }
                    this.ui.inputValSt.html(this.model.get('val' + this.options.target + 'St'));
                    this.ui.inputCodeErr.val('');
                    this.ui.inputComment.val('Dilution 1:5');
                    this.ui.repeatSt.html('D');
                    this.model.set('res' + this.options.target + 'Comment', 'Dilution 1:5');
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    break;
                case 'repeat-extract':
                    this.resetValues();
                    this.ui.input.prop('disabled', true);
                    autoComp.prop('disabled', true);
                    this.ui.inputComment.prop('disabled', false);
                    this.ui.inputTags.prop('disabled', false);
                    if (this.model.get('val' + this.options.target + 'St') === 3.1) {
                        this.model.set('val' + this.options.target + 'St', 4.1);
                    } else {
                        this.model.set('val' + this.options.target + 'St', 3.2);
                    }
                    this.ui.inputValSt.html(this.model.get('val' + this.options.target + 'St'));
                    this.ui.inputCodeErr.val('');
                    this.ui.inputComment.val('Rerun for confirmation');
                    this.ui.repeatSt.html('D');
                    this.model.set('res' + this.options.target + 'Comment', 'Rerun for confirmation');
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    break;
                case 'validation-expert':
                    this.resetValues();
                    this.ui.input.prop('disabled', true);
                    autoComp.prop('disabled', true);
                    this.ui.inputComment.prop('disabled', false);
                    this.ui.inputTags.prop('disabled', false);
                    this.model.set('val' + this.options.target + 'St', 3.1);
                    this.ui.inputValSt.html(this.model.get('val' + this.options.target + 'St'));
                    break;
                case 'modify-result':
                    this.resetValues();
                    this.ui.input.prop('disabled', false);
                    autoComp.prop('disabled', false);
                    this.ui.inputCodeErr.val('');
                    this.ui.inputComment.val('');
                    this.model.set('res' + this.options.target + 'Comment', '');
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    break;
                case 'keep-result':
                    this.resetValues();
                    this.ui.input.prop('disabled', true);
                    autoComp.prop('disabled', true);
                    this.ui.inputComment.prop('disabled', false);
                    this.ui.inputTags.prop('disabled', false);
                    break;
            }
          }
        }, this));
      }
    },

    initialize: function(options) {
      this.model = options.wellModel.clone();
      this.originalModel = this.model.clone();
      this.modelRun = options.runModel;
    },

    serializeData: function() {
      var templateData = {};
      templateData.unknown = (!this.model.get('smptype') || this.model.get('smptype').indexOf('U') !== -1);
      templateData.ctrl = (this.model.get('smptype') && this.model.get('smptype').indexOf('C') !== -1);
      var target = this.modelRun.getTargetByChannel(this.model.get('refAssay'), Number(this.options.target));
      templateData.ic = target?target.ic:false;
      templateData.model = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      templateData.index =  Number(this.options.target);
      templateData.target = this.modelRun.getTargetName(this.model.get('refAssay'), Number(this.options.target));
      templateData.res1Color = this.modelRun.getResultColor(this.model.get('refAssay'),
          this.model.get('res'+this.options.target+'Code'), Number(this.options.target));
      templateData.res1Txt = this.modelRun.getResultText(this.model.get('refAssay'),
          this.model.get('res'+this.options.target+'Code'), Number(this.options.target));
      templateData.valSt = this.model.get('val'+this.options.target+'St');
      templateData.expSt = this.model.get('expSt');
      templateData.repeatSt = this.model.get('repeat'+this.options.target+'St');
      templateData.readOnly = this.options.readOnly;
      return templateData;
    },

    onShow: function() {
      $.material.init();
      var mbAnaResGrSecId = this.modelRun.getRefMbAnaResGrSecId(this.model.get('refAssay'), this.options.target);
      if (mbAnaResGrSecId){
        this.mbAnaResView = new AutocompleteView(
          this._getAutocompleteOptions('res'+this.options.target+'Code', 'code', 'code',
              'v2/mbanaresgrs/'+mbAnaResGrSecId+'/mbanares?sidx=code&sord=DESC',
              'selectmbanares.placeholder', 'code', _.bind(this.onMbAnaResChange, this))
        );
        this.getRegion('mbAnaResSelect').show(this.mbAnaResView);
      }
      this.triggerMethod('enable:cancel:confirm');
      this.preselectKeepResult();

      if (this.options.readOnly) {
          this.ui.inputComment.prop('disabled', true);
          this.ui.inputTags.prop('disabled', true);
      }
    },

    onMbAnaResChange: function(fieldName, model) {
      if (model) {
        model.unset('autocompleteValue', {silent: true});
        this.onChange(fieldName, model.get('code'));
      } else {
        this.onChange(fieldName, null);
      }
      this.refreshResultIconAndResText();
      this.triggerMethod('enable:cancel:confirm');
    },

    refreshResultIconAndResText: function() {
        var res1Color = this.modelRun.getResultColor(this.model.get('refAssay'),
            this.model.get('res'+this.options.target+'Code'), Number(this.options.target));
        this.ui.resultIcon.css('background', res1Color);
        var res1Txt = this.modelRun.getResultText(this.model.get('refAssay'),
            this.model.get('res'+this.options.target+'Code'), Number(this.options.target));
        this.ui.wellResTxt.html(res1Txt);
    },

    onConfirm: function() {
        if(this.selectedRadio) {
            switch (this.selectedRadio) {
                case 'change-to-neg':
                case 'confirm-result':
                    if (Settings.get('currentUser').type && Settings.get('currentUser').type.indexOf('VAL2') !== -1) {
                        this.changeValidationStatus('well:target:validate2');
                    } else {
                        this.changeValidationStatus('well:target:validate');
                    }

                    break;
                case 'repeat':
                    App.request('well:target:invalidate', this.modelRun.get('secId'),
                        this.model.get('refAssay').secId, -1, {lst:[this.model.get('secId')]})
                            .then(_.bind(this.refreshModel, this)).fail(_.bind(function (err) {
                        if (err && err.status === 405) {
                            alert(_.i18n('error.noPermission'));
                        }
                    }, this));
                    break;
                case 'repeat-all':
                    App.request('well:target:invalidateall', this.modelRun.get('secId'),
                        this.model.get('refAssay').secId, -1, {lst:[]}).then(_.bind(this.refreshModel, this))
                            .fail(_.bind(function (err) {
                        if (err && err.status === 405) {
                            alert(_.i18n('error.noPermission'));
                        }
                    }, this));
                    break;
                case 'repeat-extract-dil12':
                case 'repeat-extract-dil15':
                case 'repeat-extract':
                    App.request('well:target:invalidateallextract', this.modelRun.get('secId'),
                        this.model.get('refAssay').secId, -1, {lst:[this.model.get('secId')]})
                            .then(_.bind(this.refreshModel, this)).fail(_.bind(function (err) {
                        if (err && err.status === 405) {
                            alert(_.i18n('error.noPermission'));
                        }
                    }, this));
                    break;
                case 'validation-expert':
                    this.changeValidationStatus('well:target:validate:expert-required');
                    break;
                default:
                    this.save();
                    return;
            }
        } else {
            this.save();
        }
    },

    refreshModel: function(result) {
        var item = _.find(result.run?result.run.wells:result.wells, _.bind(function(item) {
                return item.secId === this.model.get('secId');
            }, this));
        if (item) {
            this.model.set(item);
        }
      this.save();
    },

    save: function() {
        if(this.selectedRadio) {
            switch(this.selectedRadio) {
                case 'confirm-result':
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    this.model.set('val' + this.options.target + 'St', 3.2);
                    break;
                case 'change-to-neg':
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    this.model.set('res' + this.options.target + 'Num1', '');
                    this.model.set('res' + this.options.target + 'Num2', '');
                    this.model.set('res' + this.options.target + 'Num3', '');
                    this.model.set('res' + this.options.target + 'Code', 'N');
                    this.model.set('val' + this.options.target + 'St', 3.2);
                    break;
                case 'repeat-all':
                    this.model.set('res' + this.options.target + 'CodeErr', '');
                    break;
                case 'repeat-extract':
                    this.model.set('res' + this.options.target + 'Comment', 'Rerun for confirmation');
                    break;
                case 'repeat-extract-dil12':
                    this.model.set('res' + this.options.target + 'Comment', 'Dilution 1:2');
                    break;
                case 'repeat-extract-dil15':
                    this.model.set('res' + this.options.target + 'Comment', 'Dilution 1:5');
                    break;
            }

            this.amplCurve1 = this.model.get('amplCurve1');
            this.amplCurve2 = this.model.get('amplCurve2');
            this.amplCurve3 = this.model.get('amplCurve3');
            this.amplCurve4 = this.model.get('amplCurve4');
            this.amplCurve5 = this.model.get('amplCurve5');
            this.amplCurve6 = this.model.get('amplCurve6');
            this.meltCurve1 = this.model.get('meltCurve1');
            this.meltCurve2 = this.model.get('meltCurve2');
            this.meltCurve3 = this.model.get('meltCurve3');
            this.meltCurve4 = this.model.get('meltCurve4');
            this.meltCurve5 = this.model.get('meltCurve5');
            this.meltCurve6 = this.model.get('meltCurve6');
            this.model.set('amplCurve1', null);
            this.model.set('amplCurve2', null);
            this.model.set('amplCurve3', null);
            this.model.set('amplCurve4', null);
            this.model.set('amplCurve5', null);
            this.model.set('amplCurve6', null);
            this.model.set('meltCurve1', null);
            this.model.set('meltCurve2', null);
            this.model.set('meltCurve3', null);
            this.model.set('meltCurve4', null);
            this.model.set('meltCurve5', null);
            this.model.set('meltCurve6', null);
            this.model.saveResult();
        } else {
            this.hide();
        }
    },

    onSave: function(model) {
        var modelJSON = model.toJSON();
        this.model.set('amplCurve1', this.amplCurve1);
        this.model.set('amplCurve2', this.amplCurve2);
        this.model.set('amplCurve3', this.amplCurve3);
        this.model.set('amplCurve4', this.amplCurve4);
        this.model.set('amplCurve5', this.amplCurve5);
        this.model.set('amplCurve6', this.amplCurve6);
        this.model.set('meltCurve1', this.meltCurve1);
        this.model.set('meltCurve2', this.meltCurve2);
        this.model.set('meltCurve3', this.meltCurve3);
        this.model.set('meltCurve4', this.meltCurve4);
        this.model.set('meltCurve5', this.meltCurve5);
        this.model.set('meltCurve6', this.meltCurve6);
        this.options.wellModel.set(modelJSON);

        this.triggerAndHide();
    },

    changeValidationStatus: function(status) {
      App.request(status, this.modelRun.get('secId'),
          this.model.get('refAssay').secId, this.options.target, {lst:[this.model.get('secId')]})
            .then(_.bind(this.save, this)).fail(_.bind(function (err) {
          if (err && err.status === 405) {
              alert(_.i18n('error.noPermission'));
          }
      }, this));
    },

    triggerAndHide: function(item) {
        this.triggerMethod('saved');
        if (item) {
            if (item.run) {
                App.trigger('well:refresh:well', null, item.run.wells);
            } else {
                App.trigger('well:refresh:well', null, item.wells);
            }
        } else {
            App.trigger('well:refresh:well', this.model);
        }
        this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'runs/new') {
        App.navigate('runs', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.triggerMethod('hide:label');
      }
    }
  });

  module.exports = CreateEditLayoutView;
});
