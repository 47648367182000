/**
 * Created by BFR on 17/01/2018.
 */
define('targetsView',[
  'module',
  'app',
  'underscore',
  'marionette',
  'template!targetsViewTpl',
  'entities/wells',
  'entities/runats'
], function (
  module,
  App,
  _,
  Marionette,
  viewTpl
) {
  'use strict';

  var TargetsView = Marionette.LayoutView.extend({
    template: viewTpl,

    events: {
        'change @ui.targetsRadio': 'onTargetRadioChange',
        'click .btn-target': 'onBtnTargetClick'
    },

    ui: {
    },

    onShow: function() {
      $.material.init();
    },

    serializeData: function () {
      var templateData = {};
      templateData.targets = this.options.targets;
      return templateData;
    },

    onBtnTargetClick : function(event) {
      var btn = $(event.currentTarget);
      this.targetNr = btn.data('target-number');
      this.targetSecId = btn.data('target-secid');
      this.trigger('well:target:click', this.targetNr);
      this.unSelectAll();
      btn.addClass('target-selected');
    },

    unSelectAll: function() {
      this.$el.find('.target-selected').removeClass('target-selected');
    }
  });

  module.exports = TargetsView;
});
