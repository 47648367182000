
define('template!targetCreateEditTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="row">\n        <label class="col-xs-2">' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-2">' +
((__t = ( _.i18n('well.assay') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-2">' +
((__t = ( _.i18n('well.sampleType') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-4">' +
((__t = ( _.i18n('well.smpid') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-2">' +
((__t = ( _.i18n('well.expSt') )) == null ? '' : __t) +
'</label>\n      </div>\n      <div class="row">\n        <div class="col-xs-2">' +
((__t = ( model.pos ? model.pos : '-' )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-2">' +
((__t = ( model.refAssay?model.refAssay.code:'' )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-2">' +
((__t = ( model.smptype )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-4">' +
((__t = ( model.smpid )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-2">' +
((__t = ( expSt )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="row">\n        <div class="col-xs-6" style="' +
((__t = ( readOnly?'display:none;':'' )) == null ? '' : __t) +
'">\n          <div class="row">\n            <div class="radio radio-primary col-xs-12 p-l-0 m-t-0 m-b-0">\n              <label class="p-l-27">\n                <input type="radio" class="cc-radio update-radio" name="update-radio" value="keep-result" />\n                ' +
((__t = ( _.i18n('update.keepResult') )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n          <div class="row">\n            <div class="radio radio-primary col-xs-12 p-l-0 m-t-0 m-b-0">\n              <label class="p-l-27">\n                <input type="radio" class="cc-radio update-radio" name="update-radio" value="confirm-result" />\n                ' +
((__t = ( _.i18n('update.confirmResult') )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n          ';
 if (!ic && !ctrl) { ;
__p += '\n          <div class="row">\n            <div class="radio radio-primary col-xs-12 p-l-0 m-t-0 m-b-0">\n              <label class="p-l-27">\n                <input type="radio" class="cc-radio update-radio" name="update-radio" value="change-to-neg" />\n                ' +
((__t = ( _.i18n('update.changeToNeg') )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n          ';
 } ;
__p += '\n          ';
 if (unknown) { ;
__p += '\n          <div class="row">\n            <div class="radio radio-primary col-xs-12 p-l-0 m-t-0 m-b-0">\n              <label class="p-l-27">\n                <input type="radio" class="cc-radio update-radio" name="update-radio" value="repeat" />\n                ' +
((__t = ( _.i18n('update.repeat') )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n          ';
 } ;
__p += '\n          ';
 if (ctrl) { ;
__p += '\n          <div class="row">\n            <div class="radio radio-primary col-xs-12 p-l-0 m-t-0 m-b-0">\n              <label class="p-l-27">\n                <input type="radio" class="cc-radio update-radio" name="update-radio" value="repeat-all" />\n                ' +
((__t = ( _.i18n('update.repeatAll') )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n          ';
 } ;
__p += '\n          ';
 if (unknown) { ;
__p += '\n          <div class="row">\n            <div class="radio radio-primary col-xs-12 p-l-0 m-t-0 m-b-0">\n              <label class="p-l-27">\n                <input type="radio" class="cc-radio update-radio" name="update-radio" value="repeat-extract" />\n                ' +
((__t = ( _.i18n('update.repeatExtract') )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n          <div class="row">\n            <div class="radio radio-primary col-xs-12 p-l-0 m-t-0 m-b-0">\n              <label class="p-l-27">\n                <input type="radio" class="cc-radio update-radio" name="update-radio" value="repeat-extract-dil12" />\n                ' +
((__t = ( _.i18n('update.repeatExtractDil12') )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n          <div class="row">\n            <div class="radio radio-primary col-xs-12 p-l-0 m-t-0 m-b-0">\n              <label class="p-l-27">\n                <input type="radio" class="cc-radio update-radio" name="update-radio" value="repeat-extract-dil15" />\n                ' +
((__t = ( _.i18n('update.repeatExtractDil15') )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n          ';
 } ;
__p += '\n          <div class="row">\n            <div class="radio radio-primary col-xs-12 p-l-0 m-t-0 m-b-0">\n              <label class="p-l-27">\n                <input type="radio" class="cc-radio update-radio" name="update-radio" value="validation-expert" />\n                ' +
((__t = ( _.i18n('update.validationExpert') )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n          <div class="row">\n            <div class="radio radio-primary col-xs-12 p-l-0 m-t-0">\n              <label class="p-l-27">\n                <input type="radio" class="cc-radio update-radio" name="update-radio" value="modify-result" />\n                ' +
((__t = ( _.i18n('update.modifyResult') )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <label class="col-xs-1">' +
((__t = ( _.i18n('well.valSt') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-2">' +
((__t = ( _.i18n('well.target') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-offset-1 col-xs-2">' +
((__t = ( _.i18n('well.res') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-1">' +
((__t = ( _.i18n('well.repeatSt') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-3">' +
((__t = ( _.i18n('well.values') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-1">' +
((__t = ( _.i18n('well.codeErr') )) == null ? '' : __t) +
'</label>\n      </div>\n      <div class="row update-form">\n        <div class="row">\n          <div class="col-xs-12">\n            <div class="col-xs-1 js-valst p-t-5">\n              ' +
((__t = ( model['val'+index+'St'] )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-xs-2 p-t-5">\n              ' +
((__t = ( target )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-xs-1 p-t-5">\n              ';
 if (res1Color) { ;
__p += '\n              <div class="well-square-icon-green">\n                <div class="well-circle-icon" style="background: ' +
((__t = ( res1Color )) == null ? '' : __t) +
';"/>\n              </div>\n              ';
 } ;
__p += '\n            </div>\n            <div class="col-xs-2">\n              <div class="js-select-mbanares-region col-xs-6 p-l-0"></div>\n              <div class="p-t-5 well-res-txt">' +
((__t = ( res1Txt )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="col-xs-1">\n              <div class="p-t-5 well-repeat-st">' +
((__t = ( repeatSt )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="col-xs-3">\n              <div class="col-xs-4 p-l-0 p-r-5">\n                <input type="text" class="form-control floating-label js-info-input js-num1-input"\n                       data-field-name="' +
((__t = ( 'res'+index+'Num1' )) == null ? '' : __t) +
'"\n                       placeholder="" value="' +
((__t = ( model['res'+index+'Num1'] )) == null ? '' : __t) +
'">\n              </div>\n              <div class="col-xs-4 p-l-0 p-r-5">\n                <input type="text" class="form-control floating-label js-info-input js-num2-input"\n                       data-field-name="' +
((__t = ( 'res'+index+'Num2' )) == null ? '' : __t) +
'"\n                       placeholder="" value="' +
((__t = ( model['res'+index+'Num2'] )) == null ? '' : __t) +
'">\n              </div>\n              <div class="col-xs-4 p-l-0 p-r-5">\n                <input type="text" class="form-control floating-label js-info-input js-num3-input"\n                       data-field-name="' +
((__t = ( 'res'+index+'Num3' )) == null ? '' : __t) +
'"\n                       placeholder="" value="' +
((__t = ( model['res'+index+'Num3'] )) == null ? '' : __t) +
'">\n              </div>\n            </div>\n            <div class="col-xs-1">\n              <input type="text" class="form-control floating-label js-info-input js-codeerr-input"\n                     data-field-name="' +
((__t = ( 'res'+index+'CodeErr' )) == null ? '' : __t) +
'"\n                     placeholder="" value="' +
((__t = ( model['res'+index+'CodeErr'] )) == null ? '' : __t) +
'">\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-xs-12 p-t-15">\n          <label class="col-xs-3 p-l-0">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <input type="text" class="form-control floating-label js-info-input js-comment-input"\n                   data-field-name="' +
((__t = ( 'res'+index+'Comment' )) == null ? '' : __t) +
'"\n                   placeholder="" value="' +
((__t = ( model['res'+index+'Comment'] )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-xs-12 p-t-15">\n          <label class="col-xs-3 p-l-0">' +
((__t = ( _.i18n('well.tags') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <input type="text" class="form-control floating-label js-info-input js-tags-input"\n                   data-field-name="tags"\n                   placeholder="" value="' +
((__t = ( model['tags'] )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-xs-12 p-t-15">\n          <label class="col-xs-3 p-l-0">' +
((__t = ( _.i18n('well.commentErrAuto') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            ' +
((__t = ( model['res'+index+'CommentErrAuto'] )) == null ? '' : __t) +
'\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          ';
 if (readOnly) { ;
__p += '\n            <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.ok') )) == null ? '' : __t) +
'</button>\n          ';
 } else { ;
__p += '\n            <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n            <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n          ';
 } ;
__p += '\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

