/**
 * Created by OLD on 04/09/2015.
 */
define('colorUtils',[
  'module'
], function(
  module
) {
  'use strict';

  var ColorConverter = {
    decimalToHexa: function (decimal) {
      var hexa = parseInt(decimal, 10).toString(16);

      while (hexa.length < 6) {
        hexa = '0' + hexa;
      }
      return '#' + hexa;
    },

    rgbToDecimal: function(r, g, b) {
      return ((r * 65536) + (g * 256) + b);
    },

    rgbObjectToHexa: function(rgb) {
      return this.decimalToHexa(this.rgbToDecimal(rgb.r, rgb.g, rgb.b));
    },

    hexToRgb: function(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
      } : null;
    }
  };

  module.exports = ColorConverter;
});

