
define('template!miniWellTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="h-16 miniWellSelectableItem">\n    <div class="m-b-0">\n        ';
 if (displayResult) { ;
__p += '\n            <div class="result-icon" style="margin-left:-4px;">\n\n            </div>\n        ';
 } ;
__p += '\n    </div>\n    <span class="empty-circle item-selector-circle multi-selector">\n      <i class="mdi-action-check-circle icon" style="font-size: 11px; position: absolute;"></i>\n    </span>\n</div>';

}
return __p
};});

