define('wellResultIconView',[
    'module',
    'marionette',
    'template!wellResultIconTpl',
    'underscore',
    'app',
    'entities/wells'
], function(
    module,
    Marionette,
    wellResultIconTpl,
    _,
    App
) {
    'use strict';

    var WellResultIconView = Marionette.ItemView.extend({
        template: wellResultIconTpl,

        serializeData: function() {
            var templateData = {
            well: _.clone(this.model),
            color: this.options.modelRun.getResultColor(this.model.refAssay,
                this.model['res'+this.options.channel+'Code'], this.options.channel),
            resCodeErr: this.model['res'+this.options.channel+'CodeErr'],
            resCommentErrAuto: this.model['res'+this.options.channel+'CommentErrAuto'],
            resComment: this.model['res'+this.options.channel+'Comment'],
            colorVal: null
          };

          templateData.valSt = this.model['val'+this.options.channel+'St'];
          templateData.repeatSt = this.model['repeat'+this.options.channel+'St'];
          templateData.resCommentErrAuto = this.model['res'+this.options.channel+'CommentErrAuto'];

            var kitcqct = this.options.modelRun.getKitCQctsValue(App.request('well:model', this.model), this.options.channel);
            if (kitcqct) {
                templateData.kitcqct = _.i18n('well.qcTarget') + ' ' + kitcqct;
            } else {
                templateData.kitcqct = '';
            }

            var target = this.options.modelRun.getTargetByChannel(this.model.refAssay, this.options.channel);
            templateData.ic = target?target.ic:false;

          return templateData;
        }
    });

    module.exports = WellResultIconView;
});
