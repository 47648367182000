
define('template!sampleIdsQueryTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page col-xs-12">\n  \t<h1 class="title-list"></h1>\n    <div class="form-group">\n    </div>\n    <div class="form-horizontal col-xs-12">\n      <div class="form-inputs-container col-xs-6">\n        <div class="form-group col-xs-6">\n          <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.refMbAModel') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-8">\n            <div class="js-select-mbamodel-region"></div>\n          </div>\n        </div>\n        <div class="form-group col-xs-6">\n          <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.refLmbAnalyzer') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-8">\n            <div class="js-select-lmbanalyzer-region"></div>\n          </div>\n        </div>\n        <div class="form-group col-xs-6">\n          <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('well.refAssay') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-8">\n            <div class="js-select-assay-region"></div>\n          </div>\n        </div>\n        <div class="form-group col-xs-6" data-field-name="refRun">\n          <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('well.refRun') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-8">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="refRun"\n                   placeholder="' +
((__t = ( _.i18n('well.refRun') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-6" data-field-name="smptype">\n          <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('well.smptype') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-8">\n            <select class="form-control js-info-input" data-field-name="smptype" value="">\n              <option value=" " selected></option>\n              <option value="E">' +
((__t = ( _.i18n('well.smptype.e') )) == null ? '' : __t) +
'</option>\n              <option value="C">' +
((__t = ( _.i18n('well.smptype.c') )) == null ? '' : __t) +
'</option>\n              <option value="PC">' +
((__t = ( _.i18n('well.smptype.pc') )) == null ? '' : __t) +
'</option>\n              <option value="NC">' +
((__t = ( _.i18n('well.smptype.nc') )) == null ? '' : __t) +
'</option>\n              <option value="U">' +
((__t = ( _.i18n('well.smptype.u') )) == null ? '' : __t) +
'</option>\n            </select>\n          </div>\n        </div>\n        <div class="form-group col-xs-6" data-field-name="sampleId">\n          <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('well.sampleId') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-8">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="sampleId"\n                   placeholder="' +
((__t = ( _.i18n('well.sampleId') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="pos">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="pos"\n                   placeholder="' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="tag">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.tag') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="tag"\n                   placeholder="' +
((__t = ( _.i18n('well.tag') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n\n        <div class="row">\n          <div class="pull-right p-r-15">\n            <button class="btn btn-default btn-flat cancel-b js-reset">' +
((__t = ( _.i18n('common.reset') )) == null ? '' : __t) +
'</button>\n            <button class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.search') )) == null ? '' : __t) +
'</button>\n          </div>\n        </div>\n      </div>\n      <div class="form-inputs-container col-xs-6">\n        <div class="form-group col-xs-3" data-field-name="res1Code">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch1') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res1Code"\n                   placeholder="' +
((__t = ( _.i18n('well.ch1') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res1CodeErr">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch1Err') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res1CodeErr"\n                   placeholder="' +
((__t = ( _.i18n('well.ch1Err') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res2Code">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch2') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res2Code"\n                   placeholder="' +
((__t = ( _.i18n('well.ch2') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res2CodeErr">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch2Err') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res2CodeErr"\n                   placeholder="' +
((__t = ( _.i18n('well.ch2Err') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res3Code">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch3') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res3Code"\n                   placeholder="' +
((__t = ( _.i18n('well.ch3') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res3CodeErr">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch3Err') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res3CodeErr"\n                   placeholder="' +
((__t = ( _.i18n('well.ch3Err') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res4Code">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch4') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res4Code"\n                   placeholder="' +
((__t = ( _.i18n('well.ch4') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res4CodeErr">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch4Err') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res4CodeErr"\n                   placeholder="' +
((__t = ( _.i18n('well.ch4Err') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res5Code">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch5') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res5Code"\n                   placeholder="' +
((__t = ( _.i18n('well.ch5') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res5CodeErr">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch5Err') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res5CodeErr"\n                   placeholder="' +
((__t = ( _.i18n('well.ch5Err') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res6Code">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch6') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res6Code"\n                   placeholder="' +
((__t = ( _.i18n('well.ch6') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n        <div class="form-group col-xs-3" data-field-name="res6CodeErr">\n          <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('well.ch6Err') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-6">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="res6CodeErr"\n                   placeholder="' +
((__t = ( _.i18n('well.ch6Err') )) == null ? '' : __t) +
'" value="">\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="sub-caccounts-table col-md-6">\n      <div class="sample-ids-view">\n\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-primary disabled-grey js-download-selection">' +
((__t = ( _.i18n('common.export') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n    <div class="row col-md-6 col-sm-12 right-view">\n    </div>\n  </div>\n</div>';

}
return __p
};});

