
define('template!runDetailsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content info-page">\n    <div class="container-fluid">\n        <div class="col-md-6 left-area col-sm-12 well hidden-print p-l-5 p-r-5" style="max-width: 550px; width:40%;">\n            <div class="well-table">\n            </div>\n        </div>\n        <div class="col-md-1 hidden-print" style="width: 2%;" />\n        <div class="row col-md-6 col-sm-12 right-view" style="width:58%;">\n        </div>\n    </div>\n</div>';

}
return __p
};});

