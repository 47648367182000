define('runTableTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'runTableTabView',
    'jquery',
    'emptyView',
    'bootbox'
], function(
    module,
    App,
    _,
    Backbone,
    TableTabView,
    $,
    EmptyView,
    bootbox
) {
    'use strict';

    var TableTabController = {
        showTableTab: function(region, runModel) {
            this.region = region;
            this.model = runModel;
            this.view = new TableTabView({
                model: runModel
            });
            this.view.listenTo(this.view, 'well:filteredDetailRow', _.bind(this.filteredDetailRow, this));
            this.view.listenTo(this.view, 'well:filteredDetailAssay', _.bind(this.filteredDetailAssay, this));
            this.view.listenTo(this.view, 'well:filteredDetailColumn', _.bind(this.filteredDetailColumn, this));
            this.view.listenTo(this.view, 'well:filteredDetailItem', _.bind(this.filteredDetailItem, this));
            this.view.listenTo(this.view, 'well:showSampleIds', _.bind(this.showSampleIds, this));
            this.view.listenTo(this.view, 'well:showSampleIdsQc', _.bind(this.showSampleIdsQc, this));
            this.view.listenTo(this.view, 'run:importXml', _.bind(this.onImportXml, this));
            this.view.listenTo(this.view, 'run:refresh', _.bind(this.onRefresh, this));
            this.view.listenTo(this.view, 'run:refreshWells', _.bind(this.onRefreshWells, this));
            this.view.listenTo(this.view, 'run:reset-validation', _.bind(this.onResetValidation, this));
            this.view.listenTo(this.view, 'run:auto-validation', _.bind(this.onAutoValidation, this));

            region.show(this.view);
        },

        showSampleIds: function(sampleId, model) {
            sampleId = this.model.extractOrderIdFromSampleId(sampleId);
            App.navigate('samplesIds/'+this.view.model.get('secId')+'/' + sampleId + '/' + model.refAssay.secId + '/' +
                model.pos, {trigger: true});
        },

        showSampleIdsQc: function(sampleId, model) {
            App.navigate('samplesIdsQc/'+this.view.model.get('secId')+'/' + sampleId + '/' + model.refAssay.secId + '/' +
                this.view.model.get('refMbAModel').secId, {trigger: true});
        },

        onRefresh: function() {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);

            this.view.model.refreshData().done(_.bind(function(model) {
                this.emptyView.destroy();
                this.showTableTab(this.region, model);
            }, this)).fail(_.bind(function(response) {
                this.emptyView.destroy();
                this.showTableTab(this.region, this.model);
                if (response.status === 409) {
                 bootbox.alert(_.i18n(response.responseText));
                }
            }, this));
        },

        onRefreshWells: function(wells) {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);

            this.view.model.refreshWellsData(wells).done(_.bind(function(model) {
                this.emptyView.destroy();
                this.showTableTab(this.region, model);
            }, this)).fail(_.bind(function(response) {
                this.emptyView.destroy();
                this.showTableTab(this.region, this.model);
                if (response.status === 409) {
                    bootbox.alert(_.i18n(response.responseText));
                }
            }, this));
        },

        onAutoValidation: function() {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);

            App.request('run:auto-validation', this.model.get('secId')).then(_.bind(function(result) {
                this.emptyView.destroy();
                this.showTableTab(this.region, App.request('run:model', result));
            }, this)).fail(_.bind(function (err) {
                if (err && err.status === 405) {
                    alert(_.i18n('error.noPermission'));
                    this.emptyView.destroy();
                    this.showTableTab(this.region, this.model);
                }
            }, this));
        },

        onResetValidation: function() {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);

            App.request('run:reset-validation', this.model.get('secId')).then(_.bind(function(result) {
                this.emptyView.destroy();
                this.showTableTab(this.region, App.request('run:model', result));
            }, this));
        },

        onImportXml: function(fileInfo) {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);

            $.when(this.view.model.updateWithXmlFile(fileInfo)).done(_.bind(function(model) {
                this.emptyView.destroy();
                this.showTableTab(this.region, model);
            }, this));
        },

        filteredDetailAssay: function(assaySecId) {
            App.navigate('runs/'+this.view.model.get('secId')+'/details/filters/{"assaySecId":"'+assaySecId+'"}');
        },

        filteredDetailRow: function(row) {
            App.navigate('runs/'+this.view.model.get('secId')+'/details/row/' + row, {trigger: true});
        },

        filteredDetailColumn: function(column) {
            if (column) {
                App.navigate('runs/'+this.view.model.get('secId')+'/details/column/' + column, {trigger: true});
            } else {
                App.navigate('runs/'+this.view.model.get('secId')+'/details', {trigger: true});
            }
        },

        filteredDetailItem: function(row, column) {
            App.navigate('runs/'+this.view.model.get('secId')+'/details/row/'+row+'/column/' + column, {trigger: true});
        }
    };

    module.exports = TableTabController;
});
