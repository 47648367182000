
define('template!targetsViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n\n<div class="row">\n  <div class="col-xs-12">\n    <div class="row">\n      <div class="col-xs-12">\n        <label>' +
((__t = ( _.i18n('well.target') )) == null ? '' : __t) +
'</label>\n      </div>\n    </div>\n  </div>\n</div>\n<hr class="m-t-5 m-b-5"/>\n';
 var firstTarget = true; ;
__p += '\n';
 _.each(targets, function(target, index) {  ;
__p += '\n<div class="row">\n  <div class="col-xs-12">\n    <div class="row">\n      <div class="btn-target clickable col-xs-12 ' +
((__t = ( firstTarget?'target-selected':'')) == null ? '' : __t) +
'"\n           data-target-number="' +
((__t = ( target.channelNb )) == null ? '' : __t) +
'">\n        <label class="clickable">' +
((__t = ( target.channelNb )) == null ? '' : __t) +
' ' +
((__t = ( target.refMbAna.code )) == null ? '' : __t) +
'</label>\n      </div>\n      ';
 firstTarget = false; ;
__p += '\n    </div>\n  </div>\n</div>\n';
 }); ;


}
return __p
};});

