/**
 * Created by BFR on 18/04/2019.
 */
define('sampleIdsController',[
  'module',
  'logger',
  'underscore',
  'app',
  'sampleIdsView',
  'entities/runs'
], function(
  module,
  Logger,
  _,
  App,
  View
) {
  'use strict';

  var SampleIdsController = {
    showSampleIds: function(runSecId, sampleId, assaySecId, pos) {
        this.view = new View({
            runSecId: runSecId,
            sampleId: sampleId,
            assaySecId: assaySecId,
            pos: pos
        });
        App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    }
  };

  module.exports = SampleIdsController;
});
