define('entities/wells',[
  'app',
  'backbone',
  'settings',
  'jquery'
], function(
  App,
  Backbone,
  Settings,
  $
) {
  'use strict';

  var Well = Backbone.Model.extend({
    fetch: function() {
      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      'code':  '',
      'smptype': 'U',
      'smpid': '',
      'assay': '',
      'pos': '',
      'refKitL': {},
      'tags': ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    getResFormated: function(index) {
      var num1 = this.get('res'+index+'Num1') || '',
        num2 = this.get('res'+index+'Num2') || '',
        num3 = this.get('res'+index+'Num3') || '';
      if (num1 || num2 || num3) {
        return num1 + ' ' + num2 + ' ' + num3;
      } else {
        return '';
      }
    },

    getErrorObject: function() {
      var errorObject = {
        error: false,
        warning: false,
        ok: false
      };

      switch(this.get('errorInd')) {
        case 'E': errorObject.error = true; break;
        case 'W': errorObject.warning = true; break;
      }

      if (!errorObject.warning && !errorObject.error && (this.get('res1CodeErr') === 'W' ||
        this.get('res2CodeErr') === 'W' || this.get('res3CodeErr') === 'W' ||
      this.get('res4CodeErr') === 'W' || this.get('res5CodeErr') === 'W' || this.get('res6CodeErr') === 'W')) {
        errorObject.warning = true;
      }

      if (!errorObject.error && (this.get('res1CodeErr') === 'E' ||
      this.get('res2CodeErr') === 'E' || this.get('res3CodeErr') === 'E' ||
      this.get('res4CodeErr') === 'E' || this.get('res5CodeErr') === 'E' || this.get('res6CodeErr') === 'E')) {
        errorObject.warning = false;
        errorObject.error = true;
      }

      if (!errorObject.error && !errorObject.warning) {
        errorObject.ok = true;
      }
      return errorObject;
    },

    postUrl: function (runSecId) {
      return Settings.url('compuzz', 'v2/runs/' + runSecId + '/wells');
    },

    postResultUrl: function (runSecId) {
      return Settings.url('compuzz', 'v2/runs/' + runSecId + '/wells/results');
    },

    save: function() {
      this.url = this.postUrl(this.get('run'));
      return Backbone.Model.prototype.save.call(this);
    },

    saveResult: function() {
      this.url = this.postResultUrl(this.get('run'));
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var WellCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/runs');
    },
    model: Well
  });

  var API = {

    invalidateAllExtractWell: function(runSecId, wellSecId, target, lstSecIds) {
        var defer = $.Deferred(),
            url = Settings.url('compuzz', 'v2/runs/' + runSecId + '/assays/' + wellSecId + '/target/'+target+'/invalidateallextract');

        $.ajax({
            url: url,
            type: 'PUT',
            data: lstSecIds?JSON.stringify(lstSecIds):null,
            contentType: 'application/json',
            success: function(resp) {
                defer.resolve(resp);
            },
            error: function(err) {
                defer.reject(err);
            }
        });
        return defer.promise();
    },

    invalidateAllWell: function(runSecId, wellSecId, target, lstSecIds) {
        var defer = $.Deferred(),
            url = Settings.url('compuzz', 'v2/runs/' + runSecId + '/assays/' + wellSecId + '/target/'+target+'/invalidateall');

        $.ajax({
            url: url,
            type: 'PUT',
            data: lstSecIds?JSON.stringify(lstSecIds):null,
            contentType: 'application/json',
            success: function(resp) {
                defer.resolve(resp);
            },
            error: function(err) {
                defer.reject(err);
            }
        });
        return defer.promise();
    },

    invalidateWell: function(runSecId, wellSecId, target, lstSecIds) {
        var defer = $.Deferred(),
            url = Settings.url('compuzz', 'v2/runs/' + runSecId + '/assays/' + wellSecId + '/target/'+target+'/invalidate');

        $.ajax({
            url: url,
            type: 'PUT',
            data: lstSecIds?JSON.stringify(lstSecIds):null,
            contentType: 'application/json',
            success: function(resp) {
                defer.resolve(resp);
            },
            error: function(err) {
                defer.reject(err);
            }
        });
        return defer.promise();
    },

    validateWell: function(runSecId, wellSecId, target, lstSecIds) {
        var defer = $.Deferred(),
            url = Settings.url('compuzz', 'v2/runs/' + runSecId + '/assays/' + wellSecId + '/target/'+target+'/validation');

        $.ajax({
            url: url,
            type: 'PUT',
            data: lstSecIds?JSON.stringify(lstSecIds):null,
            contentType: 'application/json',
            success: function(resp) {
                defer.resolve(resp);
            },
            error: function(err) {
                defer.reject(err);
            }
        });
        return defer.promise();
    },

    validate2Well: function(runSecId, wellSecId, target, lstSecIds) {
      var defer = $.Deferred(),
          url = Settings.url('compuzz', 'v2/runs/' + runSecId + '/assays/' + wellSecId + '/target/'+target+'/validation2');

      $.ajax({
          url: url,
          type: 'PUT',
          data: lstSecIds?JSON.stringify(lstSecIds):null,
          contentType: 'application/json',
          success: function(resp) {
              defer.resolve(resp);
          },
          error: function(err) {
              defer.reject(err);
          }
      });
      return defer.promise();
    },

    validateExpertRequired: function(runSecId, wellSecId, target, lstSecIds) {
        var defer = $.Deferred(),
            url = Settings.url('compuzz', 'v2/runs/' + runSecId + '/assays/' + wellSecId + '/target/'+target+'/validation/requireExpert');

        $.ajax({
            url: url,
            type: 'PUT',
            data: lstSecIds?JSON.stringify(lstSecIds):null,
            contentType: 'application/json',
            success: function(resp) {
                defer.resolve(resp);
            },
            error: function(err) {
                defer.reject(err);
            }
        });
        return defer.promise();
    },

    getWellResultCollectionUrl: function(runSecId) {
      return Settings.url('compuzz', 'v2/runs/' + runSecId + '/results', {});
    },

    getWellCollectionUrl: function(runSecId, row, column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype,
                                   res1CodeErr, res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                                   res6CodeErr, selectedValues, multiRes, pageSize) {
      new WellCollection();
      var params = {};
      if (row) {
        params.row = row;
      }
      if (column) {
        params.column = column;
      }
      if (assaySecId) {
        params.assaySecId = assaySecId;
      }
      if (mbAnaSecId) {
        params.mbAnaSecId = mbAnaSecId;
      }
      if (smpid) {
        params.smpid = smpid;
      }
      if (result) {
        params.result = result;
      }
      if (errorInd) {
        params.errorInd = errorInd;
      }
      if (smptype) {
        params.smptype = smptype;
      }
      if (res1CodeErr) {
        params.res1CodeErr = res1CodeErr;
      }
      if (res2CodeErr) {
        params.res2CodeErr = res2CodeErr;
      }
      if (res3CodeErr) {
        params.res3CodeErr = res3CodeErr;
      }
      if (res4CodeErr) {
        params.res4CodeErr = res4CodeErr;
      }
      if (res5CodeErr) {
        params.res5CodeErr = res5CodeErr;
      }
      if (res6CodeErr) {
        params.res6CodeErr = res6CodeErr;
      }
      if (selectedValues) {
        params.selectedValues = selectedValues;
      }
      if (multiRes) {
        params.multiRes = multiRes;
      }
      if (pageSize) {
          params.pageSize = 99;
      }
      return Settings.url('compuzz', 'v2/runs/' + runSecId + '/wells', params);
    },

    getWellsCountUrl: function(runSecId, row, column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype,
                               res1CodeErr, res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                               res6CodeErr, selectedValues, multiRes) {
      var params = '?';
      if (row) {
        params += 'row=' + row + '&';
      }
      if (column) {
        params += 'column=' + column + '&';
      }
      if (assaySecId) {
        params += 'assaySecId=' + assaySecId + '&';
      }
      if (mbAnaSecId) {
        params += 'mbAnaSecId=' + mbAnaSecId + '&';
      }
      if (smpid) {
        params += 'smpid=' + smpid + '&';
      }
      if (result) {
        params += 'result=' + result + '&';
      }
      if (errorInd) {
        params += 'errorInd=' + errorInd + '&';
      }
      if (smptype) {
        params += 'smptype=' + smptype + '&';
      }
      if (res1CodeErr) {
        params += 'res1CodeErr=' + res1CodeErr + '&';
      }
      if (res2CodeErr) {
        params += 'res2CodeErr=' + res2CodeErr + '&';
      }
      if (res3CodeErr) {
        params += 'res3CodeErr=' + res3CodeErr + '&';
      }
      if (res4CodeErr) {
        params += 'res4CodeErr=' + res4CodeErr + '&';
      }
      if (res5CodeErr) {
        params += 'res5CodeErr=' + res5CodeErr + '&';
      }
      if (res5CodeErr) {
        params += 'res6CodeErr=' + res6CodeErr + '&';
      }
      if (selectedValues) {
        params += 'selectedValues=' + selectedValues + '&';
      }
      if (multiRes) {
        params += 'multiRes=' + multiRes + '&';
      }
      return 'v2/runs/'+runSecId+'/wells/rowCount' + params;
    }
  };

  App.reqres.setHandler('well:get-collection-url', function(runSecId, row, column, assaySecId, mbAnaSecId, smpid,
                                                            result, errorInd, smptype, res1CodeErr,
                                                            res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                                                            res6CodeErr, selectedValues, multiRes) {
      return API.getWellCollectionUrl(runSecId, row, column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype,
                                      res1CodeErr,res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                                      res6CodeErr, selectedValues, multiRes);
  });

  App.reqres.setHandler('well:get-collection', function(runSecId, row, column, assaySecId, mbAnaSecId, smpid,
                                                            result, errorInd, smptype, res1CodeErr,
                                                            res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                                                            res6CodeErr, selectedValues, multiRes) {
      var colUrl = API.getWellCollectionUrl(runSecId, row, column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype,
          res1CodeErr,res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
          res6CodeErr, selectedValues, multiRes, 99);

      var Col = new WellCollection();
      Col.url = colUrl;
      return Col;
  });

  App.reqres.setHandler('well:get-count-url', function(runSecId, row, column, assaySecId, mbAnaSecId, smpid,
                                                       result, errorInd, smptype, res1CodeErr,
                                                       res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                                                       res6CodeErr, selectedValues, multiRes) {
    return API.getWellsCountUrl(runSecId, row, column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype,
      res1CodeErr, res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr, res6CodeErr, selectedValues, multiRes);
  });

  App.reqres.setHandler('well:get-collection-result-url', function(runSecId) {
    return API.getWellResultCollectionUrl(runSecId);
  });

  App.reqres.setHandler('well:model', function(runJSON) {
    return new Well(runJSON);
  });

  App.reqres.setHandler('well:target:invalidateallextract', function(runSecId, wellSecId, target, lstSecIds) {
    return API.invalidateAllExtractWell(runSecId, wellSecId, target, lstSecIds);
  });

  App.reqres.setHandler('well:target:invalidateall', function(runSecId, wellSecId, target, lstSecIds) {
    return API.invalidateAllWell(runSecId, wellSecId, target, lstSecIds);
  });

  App.reqres.setHandler('well:target:invalidate', function(runSecId, wellSecId, target, lstSecIds) {
    return API.invalidateWell(runSecId, wellSecId, target, lstSecIds);
  });

  App.reqres.setHandler('well:target:validate', function(runSecId, wellSecId, target, lstSecIds) {
    return API.validateWell(runSecId, wellSecId, target, lstSecIds);
  });

  App.reqres.setHandler('well:target:validate2', function(runSecId, wellSecId, target, lstSecIds) {
    return API.validate2Well(runSecId, wellSecId, target, lstSecIds);
  });

  App.reqres.setHandler('well:target:validate:expert-required', function(runSecId, wellSecId, target, lstSecIds) {
    return API.validateExpertRequired(runSecId, wellSecId, target, lstSecIds);
  });
});
