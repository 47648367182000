define('miniWellView',[
    'module',
    'marionette',
    'jquery',
    'template!miniWellTpl',
    'wellResultIconView'
], function(
    module,
    Marionette,
    $,
    miniWellTpl,
    WellResultIconView
) {
    'use strict';

    var MiniWellView = Marionette.LayoutView.extend({
        template: miniWellTpl,

        ui: {
            selectableItem: '.miniWellSelectableItem'
        },

        regions: {
            resultIcon: '.result-icon'
        },

        events: {
            'dblclick': 'onItemDblClick',
            'click': 'onItemClick',
            'click .item-selector-circle': 'onSelectClick'
        },

        onSelectClick: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var $template = this.ui.selectableItem,
                selected = $template.hasClass('selected') ? true : false;

            if (selected) {
                $template.removeClass('selected');
                this.trigger('well:removeSelection', this.model);
            } else {
                $template.addClass('selected');
                this.trigger('well:addSelection', this.model);
            }
        },

        onItemDblClick:function() {
            this.trigger('well:filteredDetailItems', this.model);
        },

        onItemClick:function() {
            $('.miniWellSelectableItem').removeClass('selected');
            this.trigger('well:filteredDetailItem', this.model);
        },

        onShow: function() {
            if (this.displayResult) {
                this.iconResultView = new WellResultIconView({
                    model: this.model.toJSON(),
                    modelRun: this.options.modelRun,
                    channel: this.options.channel
                });
                this.getRegion('resultIcon').show(this.iconResultView);
            }
        },

        serializeData: function() {
          var templateData = {
            well: this.model.toJSON()
          };

          templateData.displayResult = '';
          if (templateData.well.smpid && templateData.well.refAssay) {
              templateData.displayResult = true;
          }

          this.displayResult = templateData.displayResult;

          return templateData;
        }
    });

    module.exports = MiniWellView;
});
