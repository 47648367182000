define('qcLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!qcLayoutTpl',
  'savingBehavior',
  'qcGraphView'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  qcLayoutTpl,
  SavingBehavior,
  QcGraphView
) {
  'use strict';

  var QcLayoutView = DialogFormView.extend({
    template: qcLayoutTpl,

    ui: {
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
        qcGraph: '#qc-graph-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    initialize: function() {
    },

    serializeData: function() {
      var templateData = {};
      return templateData;
    },

    onShow: function() {
      $.material.init();
      this.triggerMethod('enable:cancel:confirm');

      this.renderGraphics(this.options.data);
    },

    renderGraphics: function(models) {
      var region;

      this.graphView = new QcGraphView({
          type: 'line',
          models: models,
          channel: this.options.channel
      });

      region = this.getRegion('qcGraph');
      if (region) {
          region.show(this.graphView);
      }
    },

    onConfirm: function() {
        this.hide();
    },

    onSave: function() {
        this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = QcLayoutView;
});
