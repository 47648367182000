define('qcGraphView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'chartView',
  'dateUtils'
], function (
  module,
  Marionette,
  _,
  App,
  ChartView,
  dateConverter
) {
  'use strict';

  var QcGraphView =  ChartView.extend({
    serializeData: function() {

        var val1, val2, val3, val4, val5;

        if (this.options.models) {
            var curModel = this.options.models[0];
            var modelRun = App.request('run:model', curModel.refRun);
            var kitcqct = modelRun.getKitCQcts(curModel.well, this.options.channel);

            if (kitcqct) {
                val3 = kitcqct.qcTargetValNum;
                val2 = val3 - kitcqct.valueWarn;
                val1 = val3 - kitcqct.valueErr;
                val4 = val3 + kitcqct.valueWarn;
                val5 = val3 + kitcqct.valueErr;
            }
        }

      this.data = {
          labels:
              [],
          datasets:[
              {
                  fill: false,
                  borderColor:'rgb(0, 0, 0)',
                  data:[],
                  lineTension: 0,
                  spanGaps: true,
                  borderJoinStyle: 'round'
              }
          ]
      };

      if (val1) {
          this.data.datasets.unshift(
          {
              backgroundColor:'rgba(231, 58, 47, 0.40)',
                  borderColor:'rgba(231, 58, 47, 1)',
              pointRadius: 0,
              data:[]
          },
          {
              backgroundColor:'rgba(231, 58, 47, 0.20)',
                  borderColor:'rgba(46, 158, 73, 1)',
              pointRadius: 0,
              fill: '-1',
              data:[]
          },
          {
              backgroundColor:'rgba(46, 158, 73, 0.20)',
                  borderColor:'rgba(0, 9, 255, 1)',
              pointRadius: 0,
              fill: '-1',
              data:[]
          },
          {
              backgroundColor:'rgba(46, 158, 73, 0.20)',
                  borderColor:'rgba(46, 158, 73, 1)',
              pointRadius: 0,
              fill: '-1',
              data:[]
          },
          {
              backgroundColor:'rgba(231, 58, 47, 0.20)',
                  borderColor:'rgba(231, 58, 47, 1)',
              pointRadius: 0,
              fill: '-1',
              data:[]
          },
          {
              backgroundColor:'rgba(231, 58, 47, 0.40)',
              borderColor: false,
              pointRadius: 0,
              fill: '-1',
              data:[]
          });
          var yAxe = {
              ticks: {
                  max: val5 + 1,
                  min: val1 - 1,
                  stepSize: 0.5
              }
          };

          this.options.scales = {
              yAxes: [yAxe]
          };
      }

      _.each(this.options.models, _.bind(function(model) {
        this.data.labels.push(dateConverter.toDateFormatString(model.refRun.creatDate));
        if (val1) {
            this.data.datasets[0].data.push(val1);
            this.data.datasets[1].data.push(val2);
            this.data.datasets[2].data.push(val3);
            this.data.datasets[3].data.push(val4);
            this.data.datasets[4].data.push(val5);
            this.data.datasets[5].data.push(val5 + 1);
        }
        this.data.datasets[this.data.datasets.length-1].data.push(model.well['res'+this.options.channel+'Num1']);
      }, this));
      this.generateLegend();
      return this.data;
    },

      generateLegend: function() {
          this.options.legend = '';
      }
  });

  module.exports = QcGraphView;
});
