/**
 * Created by BFR on 10/07/2017.
 */
define('mobioChartView',[
  'module',
  'logger',
  'marionette',
  'underscore',
  'chartView',
  'colorUtils'
], function(
    module,
    Logger,
    Marionette,
    _,
    ChartView,
    ColorUtils
) {
  'use strict';

  var MobioChartView = ChartView.extend({

    min: 0,
    max: 0,
    slopeEnable: false,
    brutEnable: false,
    amplGraph: false,
    displayLegend: true,
    deriv2Enable: false,
    withoutCC: false,
    fixedColor: false,
    slopeAlgo: 'STD;5',

    slope: function(list, index, forceBrutSlope, forceSlope, slopeAglo, numberOfPoints) {
      var multi = [0.33, 0.66, 1, 0.66, 0.33], div = 3;

      if (numberOfPoints === null) {
          numberOfPoints = parseInt(slopeAglo.split(';')[1], 10);
      }

      if (forceSlope && numberOfPoints > 0) {
        var slopeValue = 0, start = -2, stop = 2, listdiff = list.length - index,
            j = 0;

        switch(numberOfPoints) {
            case 3:
                multi = [0.50, 1, 0.50];
                start = -1;
                stop = 1;
                if (index < 1) {
                    start = -index;
                }
                switch(listdiff) {
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                }
                break;
            case 5:
                multi = [0.33, 0.66, 1, 0.66, 0.33];
                start = -2;
                stop = 2;
                if (index < 2) {
                    start = -index;
                }
                switch(listdiff) {
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                    case 4: stop = 2; break;
                }
                break;
            case 7:
                multi = [0.25, 0.50, 0.75, 1, 0.75, 0.50, 0.25];
                start = -3;
                stop = 3;
                if (index < 3) {
                    start = -index;
                }
                switch(listdiff) {
                    case 0: stop = -2; break;
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                    case 4: stop = 2; break;
                }
                break;
            case 9:
                multi = [0.20, 0.40, 0.60, 0.80, 1, 0.80, 0.60, 0.40, 0.20];
                start = -4;
                stop = 4;
                if (index < 4) {
                    start = -index;
                }
                switch(listdiff) {
                    case 0: stop = -2; break;
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                    case 4: stop = 2; break;
                    case 5: stop = 3; break;
                }
                break;
            case 11:
                multi = [0.15, 0.30, 0.50, 0.70, 0.85, 1, 0.85, 0.70, 0.50, 0.30, 0.15];
                start = -5;
                stop = 5;
                if (index < 5) {
                    start = -index;
                }
                switch(listdiff) {
                    case 0: stop = -2; break;
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                    case 4: stop = 2; break;
                    case 5: stop = 3; break;
                    case 6: stop = 4; break;
                }
                break;
        }

        div = 0;
        for (var i = start ; i <= stop ; i++) {
          if (forceBrutSlope) {
              slopeValue += this.getFluor(list, index + i) * multi[j];
          } else {
              slopeValue += (this.getFluor(list, index + i) - this.getFluor(list, index + i + 1)) * multi[j];
          }
          div += multi[j];
          j++;
        }

        slopeValue /= div;

        if (forceBrutSlope) {
          return slopeValue;
        } else {
          return -slopeValue;
        }

      } else {
        if (this.brutEnable || forceBrutSlope) {
          return this.getFluor(list, index);
        } else {
          if (index === 0) {
            return this.getFluor(list, index + 1) - this.getFluor(list, index);
          } else if(index === list.length - 1) {
              return (this.getFluor(list, index) - this.getFluor(list, index - 1));
          } else {
            return (this.getFluor(list, index + 1) - this.getFluor(list, index - 1)) / 2;
          }
        }
      }
    },

    getDeriv: function(list, index) {
        if (index === 0) {
            return (this.getFluor(list, index + 1) - this.getFluor(list, index)) / 2;
        } else if(index === list.length - 1) {
            return (this.getFluor(list, index) - this.getFluor(list, index - 1));
        } else {
            return (this.getFluor(list, index + 1) - this.getFluor(list, index - 1)) / 2;
        }
    },

    getFluor: function(list, index) {
        if (this.withoutCC) {
            return parseFloat(list[index].fluorNoCC);
        } else {
            return parseFloat(list[index].fluor);
        }
    },

    generateLegend: function() {
      var xMax = 80, xMin = 30;
      if (this.amplGraph) {
        xMax = 45;
        xMin = 1;
      }
      var stepSize = parseFloat(((this.max - this.min) / 10).toFixed(2)); // this.max-this.min>1.2?0.2:0.1;
      if (!stepSize) {
        stepSize = 0.01;
      }

      var xAxe = {
          type: 'linear',
          position: 'bottom',
          ticks: {
              max: xMax,
              min: xMin,
              stepSize: 5
          }
      };

      if (this.xLabel) {
        xAxe.scaleLabel = {
          display: true,
          labelString: this.xLabel
        };
      }

      var yAxe;

      if (!this.brutEnable) {
        yAxe = {
          ticks: {
            max: this.max + (stepSize - (this.max % stepSize)),
            min: this.min - (stepSize + (this.min % stepSize)),
            stepSize: stepSize
          }
        };
      } else {
          yAxe = {
          };
      }

        if (this.yLabel) {
            yAxe.scaleLabel = {
                display: true,
                labelString: this.yLabel
            };
        }

        this.options.scales = {
            xAxes: [xAxe],
            yAxes: [yAxe]
        };


      this.options.legend = {
        labels: {
          boxWidth: 1
        },
        display: this.displayLegend
      };
    },

    getRandomColor: function () {
      var letters = '0123456789ABCDEF'.split('');
      var color = '#';
      for (var i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    },

    getColorFromRes: function(runModel, assay, smptype, target, res, errCode, forceNull, highlight) {
      if (highlight) {
        return { r:0, g:0, b:0, line: 4 };
      } else if (forceNull) {
        return { r:null, g:null, b:null, line: 1.7 };
      } else {
          var c = runModel.getResultColor(assay, res, target);
          var line = 1.7;
          if(c) {
              c = ColorUtils.hexToRgb(c);
              var dashed = null;
              if (errCode === 'E') {
                  dashed = {
                      borderDashOffset: 3,
                      borderDash: [2,4]
                  };
              } else if (errCode === 'W') {
                  dashed = {
                      borderDashOffset: 10,
                      borderDash: [12,8]
                  };
              }
              if (!smptype || smptype === 'U') {
                  line = 2;
              } else if (smptype === 'PC') {
                  line = 4;
              } else if (smptype === 'NC') {
                  line = 1;
              }
              return { r:c.r, g:c.g, b:c.b, line: line, dashed: dashed };
          } else {
              return { r:null, g:null, b:null, line: line };
          }
      }
    },

    addData: function(curveData, r, g ,b, label, slopeAlgo, borderWidth, dashed) {
      var dataCreated = [],
          color,
          currentValue/*, maxValue = 0*/, multiplicateur = 1;

      if (!borderWidth) {
          borderWidth = 3;
      }

      if (r !== null && g !== null && b !== null) {
        color = 'rgba('+r+', '+g+', '+b+', 1)';
      } else {
        color = this.getRandomColor();
      }

      var curveDateSloped = [], newItem;

      if (!this.brutEnable) {
          _.each(curveData, _.bind(function(item, index) {
              newItem = _.extend({}, item);
              newItem.fluor = this.slope(curveData, index, true, true, slopeAlgo, null);
              curveDateSloped.push(newItem);
          }, this));
          curveData = curveDateSloped;
      }

      curveDateSloped = [];
      _.each(curveData, _.bind(function(item, index) {
          newItem = _.extend({}, item);
          //newItem.fluor = this.getDeriv(curveData, index);
          newItem.fluor = this.slope(curveData, index, !this.deriv2Enable?this.brutEnable:false, !this.deriv2Enable,
              slopeAlgo, this.deriv2Enable?0:(this.brutEnable?null:0));
          curveDateSloped.push(newItem);
      }, this));
      curveData = curveDateSloped;


      /*_.each(curveData, _.bind(function(item, index) {
        maxValue = Math.max(maxValue, this.slope(curveData, index));
      }, this));

      if (maxValue) {
        multiplicateur = 10 / maxValue;
      }*/

      if (this.deriv2Enable) {
        curveDateSloped = [];

        _.each(curveData, _.bind(function(item, index) {
            newItem = _.extend({}, item);
            //newItem.fluor = this.getDeriv(curveData, index);
            newItem.fluor = this.slope(curveData, index, false, false, slopeAlgo, 0) * multiplicateur;
            curveDateSloped.push(newItem);
        }, this));
        curveData = curveDateSloped;
      }

      _.each(curveData, _.bind(function(item, index) {

        if (this.amplGraph) {
          currentValue = index+1;
        } else {
          currentValue = parseFloat(item.temp);
        }

        if (((currentValue > 30 && currentValue < 80) || this.amplGraph)) {
          var dataItem, slopeY;
          //if (this.deriv2Enable) {
              if (this.amplGraph) {
                  slopeY = item.fluor;
              } else {
                  slopeY = item.fluor * -1;
              }

          //} else {
              //slopeY = this.slope(curveData, index, null, null, slopeAlgo, 0) * (this.brutEnable||this.deriv2Enable?1:multiplicateur);
          //}
          this.min = Math.min(this.min, slopeY);
          this.max = Math.max(this.max, slopeY);

          dataItem = {
            x: currentValue,
            y: slopeY
          };

          dataCreated.push(dataItem);
        }
      }, this));

      _.sortBy(dataCreated, function(o) { return o.x; });

      var result = {
          label: label,
          borderColor : color,
          borderWidth: borderWidth,
          fill: false,
          pointRadius: 0,
          cubicInterpolationMode: 'monotone',
          data : dataCreated
      };
      if (dashed) {
          result = _.defaults(dashed, result);
      }
      return result;
    },

    addLine: function(value, name, r, g, b) {
      var dataCreated = [], dataItem, color;

      if (r !== null && g !== null && b !== null) {
        color = 'rgba('+r+', '+g+', '+b+', 1)';
      } else {
        color = this.getRandomColor();
      }

      for (var i = 0 ; i < this.data.datasets[0].data.length ; i++) {
        dataItem = {
          x: this.data.datasets[0].data[i].x,
          y: parseFloat(value)
        };
        dataCreated.push(dataItem);
      }
      return {
        label: name,
        borderColor : color,
        fill: false,
        pointRadius: 0,
        cubicInterpolationMode: 'monotone',
        data : dataCreated
      };
    }
  });

  module.exports = MobioChartView;
});
