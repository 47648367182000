
define('template!runsViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page">\n  \t<h2>' +
((__t = ( _.i18n('menu.runManagement') )) == null ? '' : __t) +
'</h2>\n    <div class="form-group">\n        <a href="#runs/new" class="btn new-run-button btn-sm add-btn-text">\n          <i class="mdi-content-add"></i>\n          <span>' +
((__t = ( _.i18n('run.new') )) == null ? '' : __t) +
'</span>\n        </a>\n      <input type="file"\n             id="run-xml-input"\n             class="hidden js-upload-runs" data-icon="true"\n             accept=".xlsx,.xls,.xml,.rdml,.csv"/>\n      <label for="run-xml-input" class="btn add-btn-text btn-sm run-import">\n        <i class="mdi-content-add"></i>\n        <span>' +
((__t = ( _.i18n("run.import") )) == null ? '' : __t) +
'</span>\n      </label>\n    </div>\n    <div class="sub-caccounts-table">\n      <table id="jq-grid-list" class="cacountTable" role="grid"></table>\n      <div id="jq-grid-pager-list"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

