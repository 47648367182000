define('wellDisplayGraphView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!wellDisplayGraphTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'settings',
  'jquery',
  'wellsGraphView',
  'createEditRunAtLayoutView',
  'entities/runats'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView,
  Settings,
  $,
  WellsGraphView,
  CreateEditRunAtLayoutView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    models: [],

    ui: {
      resRadio: '.res-radio',
      cbxValidation: '.cbx-validation',
      ccRadio: '.cc-radio',
      cvRadio: '.cv-radio',
      lblAssay: '.lbl-assay',
      lblTarget: '.lbl-target'
    },

    events: {
      'click .js-confirm': 'onConfirm',
      'change @ui.resRadio' : 'onChangeCbx',
      'change @ui.cbxValidation' : 'onChangeCbx',
      'click .btn-edit-runat': 'onClickBtnEditRunAt',
      'change @ui.ccRadio': 'onCCRadioChange',
      'change @ui.cvRadio': 'onCVRadioChange',
      'click .btn-validate': 'onValidateClick',
      'click .btn-validate2': 'onValidate2Click',
      'click .btn-invalidate': 'onInValidateClick',
      'click .btn-repeat-dna': 'onRepeatDnaClick',
      'click .btn-repeat-dna-dil12': 'onRepeatDnaDil12Click',
      'click .btn-repeat-dna-dil15': 'onRepeatDnaDil15Click',
      'click .btn-expert-required': 'onExpertClick',
      'click .btn-confirm-result': 'onConfirmResult',
      'click .btn-change-to-neg': 'onChangeToNegClick'
    },

    onInValidateClick: function() {
      this.trigger('well-popup:assay-target-invalidate:click');
    },

    onRepeatDnaClick: function() {
      this.trigger('well-popup:assay-target-repeat-dna:click');
    },

    onRepeatDnaDil12Click: function() {
      this.trigger('well-popup:assay-target-repeat-dna-dil12:click');
    },

    onRepeatDnaDil15Click: function() {
      this.trigger('well-popup:assay-target-repeat-dna-dil15:click');
    },

    onExpertClick: function() {
      this.trigger('well-popup:assay-target-expert:click');
    },

    onValidateClick: function() {
      this.trigger('well-popup:assay-target-validate:click');
    },

    onValidate2Click: function() {
      this.trigger('well-popup:assay-target-validate2:click');
    },

    onConfirmResult: function() {
      this.trigger('well-popup:assay-target-confirm-result:click');
    },

    onChangeToNegClick: function() {
      this.trigger('well-popup:assay-target-change-to-neg:click');
    },

    regions: {
      runGraph: '#run-graph-region',
      runGraph1: '#run-graph1-region',
      runGraph2: '#run-graph2-region',
      runAmplGraph: '#run-ampl-graph-region',
      runAmplGraph1: '#run-ampl-graph1-region',
      runAmplGraph2: '#run-ampl-graph2-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    initialize: function(options) {
      this.models = options.models;
      this.currentModels = this.models;
      this.legend = null;
      this.withoutCC = false;
      this.fixedColor = true;
      this.listenTo(App, 'runat:header:rerender', _.bind(this.onRunAtChange, this));
      App.on('well:refresh:well', this.onWellChange, this);
    },

    onWellChange: function(well) {
      if (well) {
        _.each(this.models, _.bind(function(model) {
          if (well.attributes) {
            if (model && model.pos === well.get('pos')) {
              model.set(well.toJSON());
            }
          } else {
            if (model && model.pos === well.pos) {
              model.set(well);
            }
          }
        },this)); 
      }
    },

    onDestroy: function() {
      App.off('well:refresh:well', this.onWellChange, this);
    },

    serializeData: function() {
      var result,templateData = {};
      this.results = [];

      _.each(this.models, _.bind(function(model) {
          result = model.get('res' + this.channelToDisplay + 'Code');
          if (result && !this.results.includes(result)) {
              this.results.push(result);
          }
      }, this));

      templateData.newItem = this.options.newItem;
      templateData.displayAmpl = this.graphToDisplay.indexOf('A') !== -1;
      templateData.displayMelt = this.graphToDisplay.indexOf('M') !== -1;
      templateData.results = this.results;
      templateData.statuss = this.statuss;
      templateData.ctrls = this.ctrls;
      templateData.hideOkButton = this.hideOkButton;
      templateData.hideNextPreviousButton = this.hideNextPreviousButton;
      templateData.displayCC = this.model.get('refMbAModel').code === 'LC480';
      return templateData;
    },

    onCCRadioChange: function() {
      if(this.ui.ccRadio) {
          _.each(this.ui.ccRadio, _.bind(function(radio) {
              if (radio.checked) {
                  this.withoutCC = radio.value==='no'?true:false;
              }
          }, this));
          this.renderGraphics(this.currentModels);
      }
    },

    onCVRadioChange: function() {
      if(this.ui.cvRadio) {
          _.each(this.ui.cvRadio, _.bind(function(radio) {
              if (radio.checked) {
                  this.fixedColor = radio.value==='yes'?true:false;
              }
          }, this));
          this.renderGraphics(this.currentModels);
      }
    },

    onRunAtChange: function(runAt) {
      this.runAt = runAt;
    },

    onChangeCbx: function() {
        var resultToDisplay = [], validation = [], models = [];
        var addAllRes = false, addOnlyWarningError = false;
        _.each(this.ui.resRadio, _.bind(function(radio) {
            if (radio.checked) {
                if (radio.value === 'all') {
                    addAllRes = true;
                } else if(radio.value === 'warn/err') {
                    addOnlyWarningError = true;
                } else {
                    resultToDisplay.push(radio.value);
                }
            }
        }, this));

        _.each(this.ui.cbxValidation, _.bind(function(cbx) {
            cbx = $(cbx);
            var val = cbx.data('value');
            if (cbx.is(':checked')) {
                validation.push(val);
            }
        }, this));

        _.each(this.models, _.bind(function(model) {
            if ((resultToDisplay.includes(model.get('res' + this.channelToDisplay + 'Code')) ||
                    (addOnlyWarningError && model.get('res' + this.channelToDisplay + 'CodeErr')) || addAllRes) &&
                (validation.includes('all') || validation.includes(model.get('val' + this.channelToDisplay + 'St')))) {
              models.push(model);
            }
        }, this));

        this.currentModels = models;
        this.renderGraphics(models);
    },

    onClickBtnEditRunAt: function() {
      this.createEditRunAtView = new CreateEditRunAtLayoutView({
          model: App.request('runat:model', this.runAt),
          modelRun: this.model,
          newItem: false
      });
      this.createEditRunAtView.show({
          title: _.i18n('runat.edit')
      });
    },

    onShow: function() {
      $.material.init();
      $(this.ui.ccRadio[0]).prop('checked', true);
      $(this.ui.cvRadio[0]).prop('checked', true);
      $(this.ui.resRadio[0]).prop('checked', true);
      this.ui.cbxValidation.prop('checked', true);

      $('#main-region').addClass('hidden-print');

      if(this.options.targets) {
          this.channelToDisplay = this.options.targets[0].channelNb;
      }

      this.renderGraphics(this.models);

      this.triggerMethod('enable:cancel:confirm');
    },

    renderGraphics: function(models) {
        var region;

        if (models && models.length) {
            var assay = models[0].get('refAssay');
            var target = this.model.getTargetByChannel(assay, this.channelToDisplay);
            this.ui.lblAssay.text(assay.code);
            this.ui.lblTarget.text(this.channelToDisplay + ' ' + (target!==null?target.refMbAna.code:''));
        } else {
            this.ui.lblAssay.text('');
            this.ui.lblTarget.text('');
        }

        if (this.graphToDisplay.indexOf('M') !== -1) {
            this.graphView = new WellsGraphView({type: 'line'});
            this.graphView.models = models;
            this.graphView.modelRun = this.model;
            this.graphView.slopeEnable = true;
            this.graphView.brutEnable = false;
            this.graphView.channelToDisplay = this.channelToDisplay;
            this.graphView.legend = this.legend;
            this.graphView.withoutCC = this.withoutCC;
            this.graphView.fixedColor = this.fixedColor;
            this.graphView.highlight = this.options.highlight;

            region = this.getRegion('runGraph');
            if (region) {
                region.show(this.graphView);
            }

            this.graph1View = new WellsGraphView({type: 'line'});
            this.graph1View.models = models;
            this.graph1View.modelRun = this.model;
            this.graph1View.slopeEnable = true;
            this.graph1View.brutEnable = false;
            this.graph1View.deriv2Enable = true;
            this.graph1View.channelToDisplay = this.channelToDisplay;
            this.graph1View.legend = this.legend;
            this.graph1View.withoutCC = this.withoutCC;
            this.graph1View.fixedColor = this.fixedColor;
            this.graph1View.highlight = this.options.highlight;


            region = this.getRegion('runGraph1');
            if (region) {
                region.show(this.graph1View);
            }

            this.graph2View = new WellsGraphView({type: 'line'});
            this.graph2View.models = models;
            this.graph2View.modelRun = this.model;
            this.graph2View.slopeEnable = false;
            this.graph2View.brutEnable = true;
            this.graph2View.channelToDisplay = this.channelToDisplay;
            this.graph2View.legend = this.legend;
            this.graph2View.withoutCC = this.withoutCC;
            this.graph2View.fixedColor = this.fixedColor;
            this.graph2View.highlight = this.options.highlight;

            region = this.getRegion('runGraph2');
            if (region) {
                region.show(this.graph2View);
            }
        }

        if (this.graphToDisplay.indexOf('A') !== -1) {
            this.amplGraphView = new WellsGraphView({type: 'line'});
            this.amplGraphView.models = models;
            this.amplGraphView.modelRun = this.model;
            this.amplGraphView.slopeEnable = false;
            this.amplGraphView.brutEnable = true;
            this.amplGraphView.amplGraph = true;
            this.amplGraphView.channelToDisplay = this.channelToDisplay;
            this.amplGraphView.legend = this.legend;
            this.amplGraphView.withoutCC = this.withoutCC;
            this.amplGraphView.fixedColor = this.fixedColor;
            this.amplGraphView.highlight = this.options.highlight;

            if (this.runAt) {
                this.amplGraphView.baseline = this.runAt.baseline;
                this.amplGraphView.threshold = this.runAt.threshold;
            }

            region = this.getRegion('runAmplGraph');
            if (region) {
                region.show(this.amplGraphView);
            }

            this.amplGraph1View = new WellsGraphView({type: 'line'});
            this.amplGraph1View.models = models;
            this.amplGraph1View.modelRun = this.model;
            this.amplGraph1View.slopeEnable = true;
            this.amplGraph1View.brutEnable = false;
            this.amplGraph1View.amplGraph = true;
            this.amplGraph1View.channelToDisplay = this.channelToDisplay;
            this.amplGraph1View.legend = this.legend;
            this.amplGraph1View.withoutCC = this.withoutCC;
            this.amplGraph1View.fixedColor = this.fixedColor;
            this.amplGraph1View.highlight = this.options.highlight;

            region = this.getRegion('runAmplGraph1');
            if (region) {
                region.show(this.amplGraph1View);
            }

            this.amplGraph2View = new WellsGraphView({type: 'line'});
            this.amplGraph2View.models = models;
            this.amplGraph2View.modelRun = this.model;
            this.amplGraph2View.slopeEnable = true;
            this.amplGraph2View.brutEnable = false;
            this.amplGraph2View.deriv2Enable = true;
            this.amplGraph2View.amplGraph = true;
            this.amplGraph2View.channelToDisplay = this.channelToDisplay;
            this.amplGraph2View.legend = this.legend;
            this.amplGraph2View.withoutCC = this.withoutCC;
            this.amplGraph2View.fixedColor = this.fixedColor;
            this.amplGraph2View.highlight = this.options.highlight;

            region = this.getRegion('runAmplGraph2');
            if (region) {
                region.show(this.amplGraph2View);
            }
        }
    },

    onConfirm: function() {
      this.triggerMethod('saved');
      this.hide();
    },

    onSave: function() {

    },

    hide: function() {
      $('#main-region').removeClass('hidden-print');
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.triggerMethod('hide:label');
      }
    }
  });

  module.exports = CreateEditLayoutView;
});
