/**
 * Created by BFR on 18/04/2019.
 */
define('sampleIdsMainRouter',[
  'underscore',
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'jquery',
  'itemPagesNameMixin'
], function(
  _,
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  $,
  ItemPagesNameMixin
) {
  'use strict';

  var SampleIdsRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'samplesIds/:runSecId/:sampleId/:assay/:pos' : 'showSampleIdsView',
      'samplesIdsQc/:runSecId/:sampleId/:assay/:mbAModel' : 'showSampleIdsQcView',
      'samplesQuery': 'showSampleQuery'
    }
  });

  var API = {
    checkContent: function() {
      var defer = $.Deferred();

      require([
        'contentShowController'
      ], function(ContentShowController) {
        ContentShowController.showContent().done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    },

    showSampleIdsView: function(runSecId, sampleId, assay, pos) {
      this.checkContent().done(function() {
        require([
          'sampleIdsController'
        ], function(SampleIdsController) {
          SampleIdsController.showSampleIds(runSecId, sampleId, assay, pos);
        });
      });
    },

    showSampleIdsQcView: function(runSecId, sampleId, assay, mbAModelSecId) {
      this.checkContent().done(function() {
        require([
          'sampleIdsQcController'
        ], function(SampleIdsQcController) {
            SampleIdsQcController.showSampleIds(runSecId, sampleId, assay, mbAModelSecId);
        });
      });
    },

    showSampleQuery: function() {
      this.checkContent().done(function() {
        require([
            'sampleIdsQueryController'
        ], function(SampleIdsQueryController) {
            SampleIdsQueryController.showSampleIds();
            App.execute('set:active:nav:item', ItemPagesNameMixin.SAMPLESQUERY);
        });
      });
    }
  };

  App.on('sample:show', function() {
    App.navigate('sampleIds');
  });

  App.on('before:start', function() {
    new SampleIdsRouter({
      controller: API
    });
  });

  module.exports = SampleIdsRouter;
});
