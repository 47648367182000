
define('template!sampleIdsQueryGridTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content m-0-i">\n\t<div class="well-table jq-grid-table-page hidden-print" style="height: 682px;">\n\t  <div class="loading-block js-loading-block">\n\t    <span class="loading-message js-loading-message label"></span>\n\t  </div>\n\t\t<div class="col-xs-12">\n\t  \t\t<table id="jq-grid-well" class="wellTable" role="grid"></table>\n\t\t\t<div id="jq-grid-pager-list"></div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

