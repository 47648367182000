/**
 * Created by BFR on 18/04/2019.
 */
define('sampleIdsQueryView',[
    'module',
    'app',
    'underscore',
    'marionette',
    'jquery',
    'settings',
    'template!sampleIdsQueryTpl',
    'sampleIdsQueryGridView',
    'wellDetailView',
    'autocompleteView',
    'entities/wells'
], function (
    module,
    App,
    _,
    Marionette,
    $,
    Settings,
    viewTpl,
    SampleIdsGridView,
    WellDetailView,
    AutocompleteView
) {
    'use strict';

    var RunDetailView = Marionette.LayoutView.extend({
        template: viewTpl,

        param: {},

        ui: {
          'title': '.title-list',
          inputSearch: '.js-info-input'
        },

        events: {
          'click .js-confirm': 'onConfirmClick',
          'click .js-reset': 'onResetClick',
          'click .js-download-selection': 'onDownloadSelectionClick'
        },

        regions: {
            listRegion: '.sample-ids-view',
            selectLmbAnalyzer: '.js-select-lmbanalyzer-region',
            rightView: '.right-view',
            selectMbAModel: '.js-select-mbamodel-region',
            selectAssay: '.js-select-assay-region'
        },

        onDownloadSelectionClick: function() {
            window.location = Settings.url('compuzz', 'v2/runs/queryExport', this.param);
        },

        onShow: function() {
            $.material.init();
            this.mbAModelView = new AutocompleteView(
                this._getMbAModelsAutocompleteOptions('mbAModelSecId')
            );
            this.getRegion('selectMbAModel').show(this.mbAModelView);

            this.assayView = new AutocompleteView(
                this._getAssaysAutocompleteOptions('assaySecId')
            );
            this.getRegion('selectAssay').show(this.assayView);

            this.lmbAnalyzerView = new AutocompleteView(
                this._getLmbAnalyzerAutocompleteOptions('lmbAnalyzerSecId')
            );
            this.getRegion('selectLmbAnalyzer').show(this.lmbAnalyzerView);
        },

        _getLmbAnalyzerAutocompleteOptions: function(property, mbAModelSecId) {
            return {
                type: 'remote',
                remote: Settings.url('compuzz', 'v2/lmbanalyzers' + (mbAModelSecId?('?mbAModelSecId=' + mbAModelSecId):'')),
                valueKey: 'code',
                apiKey: 'secId',
                placeholder: _.i18n('selectlmbanalyzer.placeholder'),
                value: null,
                name: property,
                onPressKey: true,
                lazyLoad: true,
                keys: {
                    query: 'code',
                    limit: 'pageSize'
                },
                callback: _.bind(this.onChangeModel, this)
            };
        },

        _getAssaysAutocompleteOptions: function(property) {
            return {
                type: 'remote',
                remote: Settings.url('compuzz', 'v2/assays'),
                valueKey: 'code',
                apiKey: 'secId',
                placeholder: _.i18n('selectassay.placeholder'),
                value: null,
                name: property,
                onPressKey: true,
                lazyLoad: true,
                keys: {
                    query: 'code',
                    limit: 'pageSize'
                },
                callback: _.bind(this.onChangeModel, this)
            };
        },

        _getMbAModelsAutocompleteOptions: function(property) {
            return {
                type: 'remote',
                remote: Settings.url('compuzz', 'v2/mbamodels'),
                valueKey: 'code',
                apiKey: 'secId',
                placeholder: _.i18n('selectmbamodel.placeholder'),
                value: null,
                name: property,
                onPressKey: true,
                lazyLoad: true,
                keys: {
                    query: 'code',
                    limit: 'pageSize'
                },
                callback: _.bind(this.onChangeAModel, this)
            };
        },

        onChangeAModel: function(fieldName, model) {
            if (model) {
                this.lmbAnalyzerView = new AutocompleteView(
                    this._getLmbAnalyzerAutocompleteOptions('lmbAnalyzerSecId', model.get('secId'))
                );
            } else {
                this.lmbAnalyzerView = new AutocompleteView(
                    this._getLmbAnalyzerAutocompleteOptions('lmbAnalyzerSecId')
                );
            }
            this.getRegion('selectLmbAnalyzer').show(this.lmbAnalyzerView);
            this.onChangeModel(fieldName, model);
        },

        onChangeModel: function(fieldName, model) {
            if (!this.param) {
                this.param = {};
            }
            if (model) {
                this.param[fieldName] = model.get('secId');
            } else {
                delete this.param[fieldName];
            }
        },

        showGrid: function() {
            this.gridView = new SampleIdsGridView();
            this.gridView.listenTo(this.gridView, 'well-table:loaded', _.bind(this.onLoadSubViews, this));
            this.gridView.listenTo(this.gridView, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            var region = this.getRegion('listRegion');
            if (region) {
                region.show(this.gridView);
            }
        },

        onResetClick: function() {
            this.param = null;
            for (var i = 0 ; i < this.ui.inputSearch.length ; i++) {
                var currentP = $(this.ui.inputSearch[i]);
                currentP.val('');
            }
            this.mbAModelView.clearSelection();
            this.assayView.clearSelection();
            this.lmbAnalyzerView.clearSelection();
            this.showGrid();
        },

        onConfirmClick: function() {
            if (!this.param) {
                this.param = {};
            }
            for (var i = 0 ; i < this.ui.inputSearch.length ; i++) {
                var currentP = $(this.ui.inputSearch[i]);
                if (currentP.val()) {
                    this.param[currentP.data('field-name')] = currentP.val();
                }
            }
            this.showGrid();
        },

        onLoadSubViews: function(wellSecId) {
            var line = _.find(this.gridView.codeTableData, function(line) {
                return line.well.secId === wellSecId;
            });
            this.onCreateRunGraphView(line);
        },

        onCreateRunGraphView: function(line) {
            this.model = App.request('well:model', line.well);
            this.modelRun = App.request('run:model', line.refRun);
            if (this.detailView) {
                this.detailView.destroy();
            }
            this.detailView = new WellDetailView({
                model: this.model
            });
            this.detailView.model = this.model;
            this.detailView.modelRun = this.modelRun;

            this.getRegion('rightView').show(this.detailView);
        },

        onGridLoaded:function () {
            if (this.param) {
                var data = {
                    url: App.request('run:get-collection-query-url', this.param),
                    rowCountUrl: App.request('run:count-collection-query-url', this.param)
                };
                this.gridView.displayGrid(data);
            }
        }
    });

    module.exports = RunDetailView;
});
