define('wellsGraphView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'moment',
  'mobioChartView'
], function (
  module,
  Marionette,
  _,
  App,
  moment,
  MobioChartView
) {
  'use strict';

  var RunGraphView =  MobioChartView.extend({

    channelToDisplay: null,

    serializeData: function() {

      this.data = {
        datasets : [
        ]
      };

      var curve1, curve2, curve3, curve4, curve5, curve6, algo1, algo2, algo3, algo4, algo5,
          algo6, legendContent = null, c;

      _.each(this.models, _.bind(function(model) {
          if (this.channelToDisplay) {
              legendContent = model.get('pos');
              if (model.get('res'+this.channelToDisplay+'Num1')) {
                  legendContent += ' (' + model.get('res'+this.channelToDisplay+'Num1');
                  if (model.get('res'+this.channelToDisplay+'Num2')) {
                      legendContent += ',' + model.get('res'+this.channelToDisplay+'Num2');
                  }
                  if (model.get('res'+this.channelToDisplay+'Num3')) {
                      legendContent += ',' + model.get('res'+this.channelToDisplay+'Num3');
                  }
                  legendContent += ')';
              }
              this.displayLegend = true;
          }

          if (model.get('smpid')) {
              if (!this.amplGraph) {
                  curve1 = (!this.channelToDisplay || this.channelToDisplay === 1)?model.get('meltCurve1'):null;
                  curve2 = (!this.channelToDisplay || this.channelToDisplay === 2)?model.get('meltCurve2'):null;
                  curve3 = (!this.channelToDisplay || this.channelToDisplay === 3)?model.get('meltCurve3'):null;
                  curve4 = (!this.channelToDisplay || this.channelToDisplay === 4)?model.get('meltCurve4'):null;
                  curve5 = (!this.channelToDisplay || this.channelToDisplay === 5)?model.get('meltCurve5'):null;
                  curve6 = (!this.channelToDisplay || this.channelToDisplay === 6)?model.get('meltCurve6'):null;
                  algo1 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 1, 'MC-SMOO');
                  algo2 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 2, 'MC-SMOO');
                  algo3 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 3, 'MC-SMOO');
                  algo4 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 4, 'MC-SMOO');
                  algo5 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 5, 'MC-SMOO');
                  algo6 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 6, 'MC-SMOO');
              } else {
                  curve1 = (!this.channelToDisplay || this.channelToDisplay === 1)?model.get('amplCurve1'):null;
                  curve2 = (!this.channelToDisplay || this.channelToDisplay === 2)?model.get('amplCurve2'):null;
                  curve3 = (!this.channelToDisplay || this.channelToDisplay === 3)?model.get('amplCurve3'):null;
                  curve4 = (!this.channelToDisplay || this.channelToDisplay === 4)?model.get('amplCurve4'):null;
                  curve5 = (!this.channelToDisplay || this.channelToDisplay === 5)?model.get('amplCurve5'):null;
                  curve6 = (!this.channelToDisplay || this.channelToDisplay === 6)?model.get('amplCurve6'):null;
                  algo1 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 1, 'AC-SMOO');
                  algo2 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 2, 'AC-SMOO');
                  algo3 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 3, 'AC-SMOO');
                  algo4 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 4, 'AC-SMOO');
                  algo5 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 5, 'AC-SMOO');
                  algo6 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 6, 'AC-SMOO');
              }
              if ( !_.isUndefined(curve1) && !_.isEmpty(curve1)) {
                  c = this.getColorFromRes(this.modelRun, model.get('refAssay'), model.get('smptype'), 1,
                      model.get('res1Code'), model.get('res1CodeErr'), !this.fixedColor, 
                      this.highlight?model.get('secId')===this.highlight.get('secId'):false);
                  this.data.datasets.push(this.addData(curve1, c.r, c.g, c.b,
                      legendContent!==null?legendContent:model.get('pos') + ' CH1', algo1, c.line, c.dashed));
              }
              if ( !_.isUndefined(curve2) && !_.isEmpty(curve2)) {
                  c = this.getColorFromRes(this.modelRun, model.get('refAssay'), model.get('smptype'), 2,
                      model.get('res2Code'), model.get('res2CodeErr'), !this.fixedColor,
                      this.highlight?model.get('secId')===this.highlight.get('secId'):false);
                  this.data.datasets.push(this.addData(curve2, c.r, c.g, c.b,
                      legendContent!==null?legendContent:model.get('pos') + ' CH2', algo2, c.line, c.dashed));
              }
              if ( !_.isUndefined(curve3) && !_.isEmpty(curve3)) {
                  c = this.getColorFromRes(this.modelRun, model.get('refAssay'), model.get('smptype'), 3,
                      model.get('res3Code'), model.get('res3CodeErr'), !this.fixedColor,
                      this.highlight?model.get('secId')===this.highlight.get('secId'):false);
                  this.data.datasets.push(this.addData(curve3, c.r, c.g, c.b,
                      legendContent!==null?legendContent:model.get('pos') + ' CH3', algo3, c.line, c.dashed));
              }
              if ( !_.isUndefined(curve4) && !_.isEmpty(curve4)) {
                  c = this.getColorFromRes(this.modelRun, model.get('refAssay'), model.get('smptype'), 4,
                      model.get('res4Code'), model.get('res4CodeErr'), !this.fixedColor,
                      this.highlight?model.get('secId')===this.highlight.get('secId'):false);
                  this.data.datasets.push(this.addData(curve4, c.r, c.g, c.b,
                      legendContent!==null?legendContent:model.get('pos') + ' CH4', algo4, c.line, c.dashed));
              }
              if ( !_.isUndefined(curve5) && !_.isEmpty(curve5)) {
                  c = this.getColorFromRes(this.modelRun, model.get('refAssay'), model.get('smptype'), 5,
                      model.get('res5Code'), model.get('res5CodeErr'), !this.fixedColor,
                      this.highlight?model.get('secId')===this.highlight.get('secId'):false);
                  this.data.datasets.push(this.addData(curve5, c.r, c.g, c.b,
                      legendContent!==null?legendContent:model.get('pos') + ' CH5', algo5, c.line, c.dashed));
              }
              if ( !_.isUndefined(curve6) && !_.isEmpty(curve6)) {
                  c = this.getColorFromRes(this.modelRun, model.get('refAssay'), model.get('smptype'), 6,
                      model.get('res6Code'), model.get('res6CodeErr'), !this.fixedColor,
                      this.highlight?model.get('secId')===this.highlight.get('secId'):false);
                  this.data.datasets.push(this.addData(curve6, c.r, c.g, c.b,
                      legendContent!==null?legendContent:model.get('pos') + ' CH6', algo6, c.line, c.dashed));
              }
          }
      }, this));

      if (this.data.datasets.length) {
        if (this.baseline !== null && this.baseline !== undefined) {
          this.data.datasets.push(this.addLine(this.baseline, 'baseline', 255, 0, 0));
        }
        if (this.threshold !== null && this.threshold !== undefined) {
          this.data.datasets.push(this.addLine(this.threshold, 'threshold', 0, 0, 255));
        }
      }

      this.generateLegend();
      return this.data;
    }
  });

  module.exports = RunGraphView;
});
