define('runTabsLayoutView',[
  'module',
  'tabsBaseView',
  'template!runTabsHeaderTpl',
  'autocompleteView',
  'jquery',
  'app',
  'envHandler',
  'backToListBehavior',
  'marionette',
  'template!runTabsTpl',
  'dateUtils',
  'imageUtils',
  'underscore'
], function(
  module,
  TabsBaseView,
  headerTpl,
  AutocompleteView,
  $,
  App,
  EnvironmentHandler,
  BackToListBehavior,
  Marionette,
  runTabsTpl,
  dateFormatter,
  imageUtils,
  _
) {
  'use strict';

  var HeaderView = Marionette.LayoutView.extend({
    template: headerTpl
  });

  var RunTabsLayoutView = Marionette.LayoutView.extend({

    template: runTabsTpl,
    tagName: 'div',

    ui: {
        logo: '.js-logo-holder',
        tabs: '.js-tab-layout-tab',
        tabsRegion: '.js-tabs-region',
        header: '.js-tab-layout-header'
    },

    events: {
        'click .js-tab-layout-tab': '_navigate'
    },

    regions: {
        tabs: '.js-tabs-region',
        header: '.js-header-region'
    },

    behaviors: function() {
      this.backToListUrl = 'runs';
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: this.backToListUrl
        }
      };
    },

    onShowTabsBase: function() {
      this.getRegion('header').show(new HeaderView({
        model: this.model
      }));
    },

    onNavigate: function(tabName) {
      var currentRoute = App.getCurrentRoute(),
        routeSplitted = currentRoute.split('/'), newRoute = [];

      newRoute.push(routeSplitted[0]);
      newRoute.push(routeSplitted[1]);
      newRoute.push(tabName);
      App.navigate(newRoute.join('/'), {trigger: true});
    },

      serializeData: function() {
          var templateData = this.model.toJSON();
          templateData.tabs = this.options.tabs;
          templateData.formatedDate = dateFormatter.toDateTimeFormatString(templateData.creatDate);
          templateData.analyser = '';
          if (templateData.refMbAModel) {
              templateData.analyser = templateData.refMbAModel.code;
          }
          return templateData;
      },

      onShow: function() {
          var logo;
          if(!this.model.getImageUid) {
              throw 'Add method getImageUid to your model. It\'s required for displaying on header.';
          }
          logo = imageUtils.getLogo(this.model.getImageUid(), this.model.get('code'));
          if(logo) {
              this.ui.logo.css('background-image', 'url(' + logo + ')');
          } else {
              this.ui.logo.addClass('default-upload-image');
          }
          this._setHeight();
          $(window).on('resize', _.bind(this._setHeight, this));
          this.triggerMethod('show:tabs:base');
      },

      onShowActive: function(tabName) {
          this.ui.tabs.each(function(index, tab) {
              var $tab = $(tab);
              if($tab.hasClass('js-' + tabName)) {
                  $tab.addClass('active');
              } else {
                  $tab.removeClass('active');
              }
          });
      },

      onDestroy: function() {
          $(window).off('resize');
      },

      _navigate: function(e) {
          var $target = $(e.currentTarget);
          if($target.hasClass('active')) {
              return;
          }
          this.triggerMethod('navigate', $target.data('tab-name'));
      },


      _setHeight: _.throttle(function() {
          var windowHeight = $(window).height(),
              headerHeight = $('header').height() || 75,
              detailsHeaderHeight = this.ui.header.outerHeight(),
              height = windowHeight - headerHeight - detailsHeaderHeight - 40;

          this.ui.tabsRegion.height(height);
      }, 500)
  });

  module.exports = RunTabsLayoutView;
});
