define( 'entities/caccounts/userpids',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var UserPid = Backbone.Model.extend({
    defaults: {
      email: null,
      firstName: null,
      lastName: null,
      logonId: null,
      nickName: '',
      secId: null,
      superUser: null,
      type: ''
    },

    idAttribute: 'secId'
  });

  /**
   * Collection
   */
  var UserPids = Backbone.Collection.extend({
    model: UserPid
  });

  /**
   * REST API
   */
  var API = {
    getAdminsUrl: function() {
      return Settings.url(
        'compuzz',
        'v2/userpid'
      );
    },

    getRowCountUrl: function() {
      return 'v2/userpid/rowCount';
    },

    getModel: function() {
      return new UserPid();
    },

    getAdmins: function(params) {
      var userPids =  new UserPids(),
        defer = $.Deferred();

      userPids.url = Settings.url(
        'compuzz',
        'v2/userpid/',
        params
      );

      userPids.fetch().done(function(resp) {
        defer.resolve(resp);
      });

      return defer.promise();
    },

    getItem: function(secId) {
      var model =  new UserPid(),
        defer = $.Deferred();

      model.url = Settings.url(
        'compuzz',
        'v2/userpid/' + secId
      );

      model.fetch().done(function(resp) {
        defer.resolve(resp);
      });

      return defer.promise();
    },

    postData: function(data) {
      var defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: Settings.url( 'compuzz', 'v2/userpid'),
        data: data,
        processData: false,
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },

    deleteItem: function(secId) {
      var defer = $.Deferred(),
        url = Settings.url(
          'compuzz',
          'v2/userpid/' + secId
        );

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('caccount-admins:get-url', function(id) {
    return API.getAdminsUrl(id);
  });

  App.reqres.setHandler('caccount-admins:row-count-url', function(id) {
    return API.getRowCountUrl(id);
  });

  App.reqres.setHandler('caccount-admins:get-model', function() {
    return API.getModel();
  });

  App.reqres.setHandler('caccount-admins:get-admins', function(params) {
    return API.getAdmins(params);
  });

  App.reqres.setHandler('caccount-admins:post-item', function(data) {
    return API.postData(data);
  });

  App.reqres.setHandler('caccount-admins:get-item', function(secId) {
    return API.getItem(secId);
  });

  App.reqres.setHandler('caccount-admins:delete-item', function(secId) {
    return API.deleteItem(secId);
  });
});
