/**
 * Created by BFR on 18/04/2019.
 */
define('sampleIdsQueryController',[
  'module',
  'logger',
  'underscore',
  'app',
  'sampleIdsQueryView',
  'entities/runs'
], function(
  module,
  Logger,
  _,
  App,
  View
) {
  'use strict';

  var SampleIdsQcController = {
    showSampleIds: function() {
        this.view = new View({
        });
        App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    }
  };

  module.exports = SampleIdsQcController;
});
