
define('template!wellTooltipTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="" style="width:490px">\n    <div class="row">\n        <div class="col-xs-2">\n            ' +
((__t = ( pos )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-2">\n            ';
 if (error) { ;
__p += '\n                <div class="mdi-alert-error well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: red;"></div>\n            ';
 } else if (warning) { ;
__p += '\n                <div class="mdi-alert-warning well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: orange;"></div>\n            ';
 } else if (ok) { ;
__p += '\n                <div class="mdi-action-done well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: green;"></div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="col-xs-3" style="white-space: nowrap; overflow: hidden;">\n            ' +
((__t = ( smpid )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-2">\n            ';
 if (expSt) { ;
__p += '\n                <div class="mdi-action-exit-to-app"></div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="col-xs-3">\n            ' +
((__t = ( refKitL != null ? refKitL.lotNb : '' )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    <hr class="well-separator m-t-0"/>\n    ';
 _.each(results, function(result) { ;
__p += '\n    <div class="row m-b-0">\n        <label class="col-xs-3">' +
((__t = ( result.target )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-1">\n            ' +
((__t = ( result.iconHtml )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1">\n            <label class="row">' +
((__t = ( result.resCode )) == null ? '' : __t) +
'</label>\n        </div>\n        <label class="col-xs-2">' +
((__t = ( result.resFormated )) == null ? '' : __t) +
'</label>\n        ';
 if (!result.resCodeErr && result.resCommentErrAuto) { ;
__p += '\n            <label class="col-xs-5 small-regular-white-compuzz">(' +
((__t = ( result.resCommentErrAuto )) == null ? '' : __t) +
')</label>\n        ';
 } else { ;
__p += '\n            <label class="col-xs-5">' +
((__t = ( result.comment )) == null ? '' : __t) +
'</label>\n        ';
 } ;
__p += '\n    </div>\n    ';
 }); ;
__p += '\n</div>';

}
return __p
};});

