define('entities/kitp',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var Kitp = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/assays/' + 'undefined' + '/kitps/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      algoresCh1: '',
      channelNb: 1,
      paramCh1: '',
      algoresACurve: '',
      paramACurve: '',
      algoresMCurve: '',
      paramMCurve: '',
      ic: false
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (assaySecId) {
      return Settings.url('compuzz', 'v2/assays/' + assaySecId + '/kitps');
    },

    save: function() {
      this.url = this.postUrl(this.get('refAssay').secId);
      return Backbone.Model.prototype.save.call(this);
    }

  });

  var KitpCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/assays/');
    },
    model: Kitp
  });

  var API = {

    getKitpCollectionUrl: function(assaySecId) {
      new KitpCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/assays/' + assaySecId + '/kitps', params);
    },

    getAllKitp: function(assaySecId, params) {
      var url = Settings.url('compuzz', 'v2/assays' + assaySecId + '/kitps', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getKitpCountUrl: function(assaySecId) {
      var params = '';
      return 'v2/assays/'+assaySecId+'/kitps/rowCount' + params;
    },

    deleteKitp: function(assaySecId, secId){
      var url = Settings.url('compuzz', 'v2/assays/'+assaySecId+'/kitps/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('kitp:get-collection-url', function(assaySecId) {
      return API.getKitpCollectionUrl(assaySecId);
  });

  App.reqres.setHandler('kitp:get-collection-to-page', function(assaySecId, params) {
      return API.getAllKitp(assaySecId, params);
  });

  App.reqres.setHandler('kitp:get-count-url', function(assaySecId) {
    return API.getKitpCountUrl(assaySecId);
  });

  App.reqres.setHandler('kitp:model', function(itemJSON) {
    return new Kitp(itemJSON);
  });

  App.reqres.setHandler('kitp:delete', function(assaySecId, secId) {
    return API.deleteKitp(assaySecId, secId);
  });
});
