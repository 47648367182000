define('wellView',[
    'module',
    'marionette',
    'template!wellTpl',
    'wellResultIconView',
    'underscore'
], function(
    module,
    Marionette,
    wellTpl,
    WellResultIconView,
    _
) {
    'use strict';

    var WellView = Marionette.LayoutView.extend({
        template: wellTpl,

        ui: {
            elementRight: '.elementRight',
            selector: '.item-selector-circle',
            selectableItem: '.wellSelectableItem'
        },

        regions: {
            iconResult1: '.icon-result1',
            iconResult2: '.icon-result2',
            iconResult3: '.icon-result3',
            iconResult4: '.icon-result4',
            iconResult5: '.icon-result5',
            iconResult6: '.icon-result6'
        },

        events: {
            'click': 'onItemClick',
            'click @ui.selector': 'onSelectClick',
            'click .btn-assay': 'onAssayClick',
            'click .smp-id-link': 'onSampleIdClick'
        },

        onAssayClick: function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (this.model.refAssay) {
                this.trigger('well:filteredDetailAssay', this.model.refAssay.secId);
            }
        },

        onSampleIdClick: function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (!this.model.smptype || this.model.smptype === 'U') {
                this.trigger('well:showSampleIds', this.model.smpid, this.model);
            } else {
                this.trigger('well:showSampleIdsQc', this.model.smpid, this.model);
            }
        },

        onSelectClick: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var $template = this.ui.selectableItem,
                selected = $template.hasClass('selected') ? true : false;

            if (selected) {
                $template.removeClass('selected');
            } else {
                $template.addClass('selected');
            }

            this.trigger('well:selectedWell', this.model.pos, !selected);
        },

        onItemClick:function() {
            this.trigger('well:filteredDetailItem', this.model.pos);
        },

        onShow: function() {
            if (this.displayResult) {
                this.iconResult1View = new WellResultIconView({model: this.model, modelRun: this.options.modelRun, channel: 1});
                this.getRegion('iconResult1').show(this.iconResult1View);
                this.iconResult2View = new WellResultIconView({model: this.model, modelRun: this.options.modelRun, channel: 2});
                this.getRegion('iconResult2').show(this.iconResult2View);
                this.iconResult3View = new WellResultIconView({model: this.model, modelRun: this.options.modelRun, channel: 3});
                this.getRegion('iconResult3').show(this.iconResult3View);
                this.iconResult4View = new WellResultIconView({model: this.model, modelRun: this.options.modelRun, channel: 4});
                this.getRegion('iconResult4').show(this.iconResult4View);
                this.iconResult5View = new WellResultIconView({model: this.model, modelRun: this.options.modelRun, channel: 5});
                this.getRegion('iconResult5').show(this.iconResult5View);
                this.iconResult6View = new WellResultIconView({model: this.model, modelRun: this.options.modelRun, channel: 6});
                this.getRegion('iconResult6').show(this.iconResult6View);
            }
        },

        serializeData: function() {
            var templateData = {
            well: _.clone(this.model)
          };
          if (templateData.well.smpid) {
            templateData.well.smpid = this.options.modelRun.getFormatedSmpId(this.model);
          }
          templateData.displayResult = '';
          if (this.model.smpid && this.model.refAssay) {
              templateData.displayResult = true;
          }

          templateData.firstOfAssay = this.options.firstOfAssay;

          templateData.error = false;
          templateData.warning = false;
          templateData.fixedError = false;
          templateData.fixedWarning = false;
          templateData.ok = false;

          switch(templateData.well.errorInd) {
            case 'E': templateData.error = true; break;
            case 'W': templateData.warning = true; break;
          }

          var colorWarnErr = ['#000000', '#e54095', '#000000', '#e54095'];
          if (this.options.modelRun.get('settings') && this.options.modelRun.get('settings').COLOR_WELL_ST) {
            colorWarnErr = this.options.modelRun.get('settings').COLOR_WELL_ST.split(';');
          }
          templateData.colorError = colorWarnErr[0];
          templateData.colorWarning = colorWarnErr[1];
          templateData.colorFixedError = colorWarnErr[2];
          templateData.colorFixedWarning = colorWarnErr[3];

          if (!templateData.warning && !templateData.error && (this.model.res1CodeErr === 'W' ||
            this.model.res2CodeErr === 'W' || this.model.res3CodeErr === 'W' ||
            this.model.res4CodeErr === 'W' || this.model.res5CodeErr === 'W' || this.model.res6CodeErr === 'W')) {
            templateData.warning = true;
          }

          if (this.model.res1CodeErr !== 'W' &&
              this.model.res2CodeErr !== 'W' && this.model.res3CodeErr !== 'W' &&
              this.model.res4CodeErr !== 'W' && this.model.res5CodeErr !== 'W' && this.model.res6CodeErr !== 'W' &&
              ((this.model.res1CommentErrAuto && this.model.res1CommentErrAuto.indexOf('W') === 0) ||
                  (this.model.res2CommentErrAuto && this.model.res2CommentErrAuto.indexOf('W') === 0) ||
                  (this.model.res3CommentErrAuto && this.model.res3CommentErrAuto.indexOf('W') === 0) ||
                  (this.model.res4CommentErrAuto && this.model.res4CommentErrAuto.indexOf('W') === 0) ||
                  (this.model.res5CommentErrAuto && this.model.res5CommentErrAuto.indexOf('W') === 0) ||
                  (this.model.res6CommentErrAuto && this.model.res6CommentErrAuto.indexOf('W') === 0))) {
              templateData.fixedWarning = true;
          }

          if (!templateData.error && (this.model.res1CodeErr === 'E' ||
            this.model.res2CodeErr === 'E' || this.model.res3CodeErr === 'E' ||
            this.model.res4CodeErr === 'E' || this.model.res5CodeErr === 'E' || this.model.res6CodeErr === 'E')) {
            templateData.warning = false;
            templateData.error = true;
          }

            if (this.model.res1CodeErr !== 'E' &&
                this.model.res2CodeErr !== 'E' && this.model.res3CodeErr !== 'E' &&
                this.model.res4CodeErr !== 'E' && this.model.res5CodeErr !== 'E' && this.model.res6CodeErr !== 'E' &&
                ((this.model.res1CommentErrAuto && this.model.res1CommentErrAuto.indexOf('E') === 0) ||
                    (this.model.res2CommentErrAuto && this.model.res2CommentErrAuto.indexOf('E') === 0) ||
                    (this.model.res3CommentErrAuto && this.model.res3CommentErrAuto.indexOf('E') === 0) ||
                    (this.model.res4CommentErrAuto && this.model.res4CommentErrAuto.indexOf('E') === 0) ||
                    (this.model.res5CommentErrAuto && this.model.res5CommentErrAuto.indexOf('E') === 0) ||
                    (this.model.res6CommentErrAuto && this.model.res6CommentErrAuto.indexOf('E') === 0))) {
                templateData.fixedError = true;
            }

          if (!templateData.error && !templateData.warning) {
            templateData.ok = true;
          }

          if (templateData.well.valWst <= 2) {
              templateData.colorVal = '#0000FF';
          } else if (templateData.well.valWst === 4) {
              templateData.colorVal = '#00FF00';
          } else if (templateData.well.valWst === 3) {
              templateData.colorVal = '#FF0000';
          }

          templateData.colorCtrl = null;

          templateData.colorAssay = null;

          var colorsAssay = ['#FF0000', '#00FF00','#fff302','#ff0021','#0012ff'];

          if (this.options.modelRun.get('settings') && this.options.modelRun.get('settings').COLORS_ASSAY) {
            colorsAssay = this.options.modelRun.get('settings').COLORS_ASSAY.split(';');
          }

          if (templateData.displayResult) {
              var i = (this.options.assayIndex % (colorsAssay.length - 3));
              templateData.colorAssay = colorsAssay[i];
          }

          switch (templateData.well.smptype) {
              case 'NC': templateData.colorCtrl = colorsAssay[colorsAssay.length - 3]; break;
              case 'PC': templateData.colorCtrl = colorsAssay[colorsAssay.length - 2]; break;
              case 'C': templateData.colorCtrl = colorsAssay[colorsAssay.length - 1]; break;
          }

          this.displayResult = templateData.displayResult;

          templateData.runStatus = this.options.modelRun.get('status');

          return templateData;
        }
    });

    module.exports = WellView;
});
