/**
 * Created by bfr on 30/01/2017.
 */
define('runsMainRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'contentShowController',
    'itemPagesNameMixin',
    'emptyView'
], function(
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    ContentShowController,
    ItemPagesNameMixin,
    EmptyView
) {
    'use strict';

    var RunsRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'runs': 'showView',
            'runs/empty': 'showEmpty',
            'runs/new': 'showViewNewRun',
            'runs/:runSecId': 'showRunTableTab',
            'runs/:runSecId/detail': 'showRunDetailTab',
            'runs/:runSecId/table': 'showRunTableTab',
            'runs/:runSecId/details': 'showRunDetailsTab',
            'runs/:runSecId/results': 'showRunResultsTab',
            'runs/:runSecId/details/filters/:filters': 'showRunDetailsTabFilters',
            'runs/:runSecId/details/row/:row': 'showRunDetailsTabRow',
            'runs/:runSecId/details/column/:column': 'showRunDetailsTabColumn',
            'runs/:runSecId/details/row/:row/column/:column': 'showRunDetailsTab',
            'runs/:runSecId/details/row/:row/column/:column/filters/:filters': 'showRunDetailsRowColTabFilters',
            'runs/:runSecId/details/select/:values': 'showRunDetailsSelectTab',
            'runs/:runSecId/assays-targets': 'showRunAssaysTargetsTab',
            'runs/:runSecId/assays-targets-2': 'showRunAssaysTargets2Tab'
        }
    });

    var API = {

        showEmpty: function() {

        },

        showViewNewRun: function() {
            this.showViewRun(true);
        },

        showViewRun: function(newItem) {
            require([
                'runsShowController'
            ], function(ShowController) {
                ContentShowController.showContent().done(function() {
                    App.startSubModule('runsMain');
                    ShowController.showRuns();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);

                    if (newItem) {
                        ShowController.showNewRun();
                    } else if (ShowController.createEditView) {
                        ShowController.createEditView.hide(true);
                    }
                });
            });
        },

        showView: function() {
            this.showViewRun(false);
        },

        showRunDetailTab: function(runSecId) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTabsLayoutController',
                    'runDetailTabController'
                ], function(RunTabsLayoutController, RunDetailTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    region.show(new EmptyView());
                    RunTabsLayoutController.showTabsLayout(region, 'detail', runSecId).done(function(region, runModel) {
                        RunDetailTabController.showDetailTab(region, runModel);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    });
                });
            });
        },

        showRunAssaysTargetsTab: function(runSecId) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTabsLayoutController',
                    'runAssaysTargetsTabController'
                ], function(RunTabsLayoutController, RunAssaysTargetsTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    region.show(new EmptyView());
                    RunTabsLayoutController.showTabsLayout(region, 'assays-targets', runSecId).done(function(region, runModel) {
                        RunAssaysTargetsTabController.showAssaysTargetsTab(region, runModel);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    });
                });
            });
        },

        showRunAssaysTargets2Tab: function(runSecId) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTabsLayoutController',
                    'runAssaysTargets2TabController'
                ], function(RunTabsLayoutController, RunAssaysTargets2TabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    region.show(new EmptyView());
                    RunTabsLayoutController.showTabsLayout(region, 'assays-targets-2', runSecId).done(function(region, runModel) {
                        RunAssaysTargets2TabController.showAssaysTargetsTab(region, runModel);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    });
                });
            });
        },

        showRunTableTab: function(runSecId) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTabsLayoutController',
                    'runTableTabController'
                ], function(RunTabsLayoutController, RunTableTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    region.show(new EmptyView());
                    RunTabsLayoutController.showTabsLayout(region, 'table', runSecId).done(function(region, runModel) {
                        RunTableTabController.showTableTab(region, runModel);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    });
                });
            });
        },

        showRunDetailsTab: function(runSecId, row, column) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTabsLayoutController',
                    'runDetailsTabController'
                ], function(RunTabsLayoutController, RunDetailsTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    RunTabsLayoutController.showTabsLayout(region, 'details', runSecId).done(function(region, runModel) {
                        RunDetailsTabController.showDetailsTab(region, runModel, row, column);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    });
                });
            });
        },

        showRunDetailsSelectTab: function(runSecId, values) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTabsLayoutController',
                    'runDetailsTabController'
                ], function(RunTabsLayoutController, RunDetailsTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    RunTabsLayoutController.showTabsLayout(region, 'details', runSecId).done(function(region, runModel) {
                        RunDetailsTabController.showDetailsTab(region, runModel, null, null, null, values);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    });
                });
            });
        },

        showRunResultsTab: function(runSecId) {
          ContentShowController.showContent().done(function() {
            require([
              'runTabsLayoutController',
              'runResultsTabController'
            ], function(RunTabsLayoutController, RunResultsTabController) {
              var region = App.regions.getRegion('main').currentView.getRegion('content');
              RunTabsLayoutController.showTabsLayout(region, 'results', runSecId).done(function(region, runModel) {
                RunResultsTabController.onShowWellTable(region, runModel, null, null);
                App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
              });
            });
          });
        },

        showRunDetailsRowColTabFilters: function(runSecId, row, column, filters) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTabsLayoutController',
                    'runDetailsTabController'
                ], function(RunTabsLayoutController, RunDetailsTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    RunTabsLayoutController.showTabsLayout(region, 'details', runSecId).done(function(region, runModel) {
                        var filtersJson = filters?JSON.parse(filters):null;
                        RunDetailsTabController.showDetailsTab(region, runModel, row, column, filtersJson);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    });
                });
            });
        },

        showRunDetailsTabFilters: function(runSecId, filters) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTabsLayoutController',
                    'runDetailsTabController'
                ], function(RunTabsLayoutController, RunDetailsTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    RunTabsLayoutController.showTabsLayout(region, 'details', runSecId).done(function(region, runModel) {
                        var filtersJson = filters?JSON.parse(filters):null;
                        RunDetailsTabController.showDetailsTab(region, runModel, null, null, filtersJson);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNS);
                    });
                });
            });
        },

        showRunDetailsTabRow: function(runSecId, row) {
            this.showRunDetailsTab(runSecId, row);
        },

        showRunDetailsTabColumn: function(runSecId, column) {
            this.showRunDetailsTab(runSecId, null, column);
        }
    };

    App.on('runs:show', function() {
        App.navigate('runs');
        API.showView();
    });

    App.on('before:start', function() {
        new RunsRouter({
            controller: API
        });
    });

    module.exports = RunsRouter;
});
